import React from 'react';
import _ from 'lodash';
import ax from 'global/axios';
import Breadcrumbs from 'global/components/Breadcrumbs';
import Package from 'reporting/Package';

const sortByName = (xs) => _.sortBy(xs, (x) => x.name);

class Packages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      packages: [],
      loading: false,
      newPackage: null,
    };
  }

  componentDidMount() {
    this.load();
    this.pollId = setInterval(this.load, 30000);
  }

  componentWillUnmount() {
    clearInterval(this.pollId);
  }

  load = () => {
    this.setState({loading: true});
    ax({
      url: '/reporting/packages',
      method: 'get'
    })
      .then( (res) => {
        this.setState({packages: sortByName(res.data)});
      }, ax.handleError)
      .then( () => this.setState({loading: false}));
  };

  handleNewPackageClick = () => {
    this.setState({newPackage: {
      id: null,
      name: '',
      sections: []
    }});
  };

  handleNewPackageCancel = () => {
    this.setState({newPackage: null});
  };

  handleNewPackageCreated = ({pkg}) => {
    let packages = Array.from(this.state.packages);
    packages.push(pkg);
    this.setState({
      newPackage: null,
      packages: sortByName(packages)
    });
  };

  handleChange = (index) => ({pkg}) => {
    let packages = Array.from(this.state.packages);
    packages.splice(index, 1, pkg);
    this.setState({packages: sortByName(packages)});
  };

  handleDestroy = (index) => () => {
    let packages = Array.from(this.state.packages);
    packages.splice(index, 1);
    this.setState({packages});
  };

  render() {
    const {loading, packages, newPackage} = this.state;

    const panelHeadingProps = {
      className: 'panel-heading clearfix',
      style: {
        lineHeight: '2.5em'
      }
    };

    return (
      <div className="panel">
        <Breadcrumbs>
          <li><a href='/reporting/profile_report'>Report</a></li>
          <li>Reporting Packages</li>
        </Breadcrumbs>

        <div {...panelHeadingProps}>
          <span className="panel-title">
            Reporting Packages
          </span>
          <div className="pull-right">
            <button className="btn btn-primary" disabled={loading || newPackage} onClick={this.handleNewPackageClick}>
              New Package
            </button>
          </div>
        </div>
        { !loading && !newPackage && _.isEmpty(packages) &&
          <div className="panel-body">
            No Reporting Packages have been created
          </div>
        }

        <div className='list-group'>
          { newPackage &&
            <Package
              key='newPackage'
              pkg={newPackage}
              onChange={this.handleNewPackageCreated}
              onDestroy={this.handleNewPackageCancel}
            />
          }
          { packages.map( (pkg, index) => (
            <Package
              key={pkg.id}
              pkg={pkg}
              onChange={this.handleChange(index)}
              onDestroy={this.handleDestroy(index)}
            />
          ) ) }
        </div>

        { loading && _.isEmpty(packages) &&
          <div className="panel-body">
            <i className='fa fa-spinner fa-pulse'></i> Loading...
          </div>
        }
      </div>
    );
  }
}

export default Packages;
