import React from 'react';
import Select from 'react-select';
import ax from 'global/axios';
import _ from 'lodash';

import TimePeriodRangePicker from 'reporting/section/TimePeriodRangePicker';
import MultiSelectWithLoader from 'reporting/section/MultiSelectWithLoader';
import FormGroup from 'reporting/section/FormGroup';
import riskCategoryDefinitionsLoader from 'reporting/section/riskCategoryDefinitionsLoader';
import riskTagOptionsLoader from 'reporting/section/riskTagOptionsLoader';
import QuestionInfoTooltip from 'global/components/QuestionInfoTooltip';

const detailLevelOptions = [
  {
    value: 'composite_only',
    label: `${window.riskEntitySettings.composite_risk_label} Only`
  }, {
    value: 'category_only',
    label: 'Category Only'
  }, {
    value: 'all_levels',
    label: `${window.riskEntitySettings.composite_risk_label}, Category, Attribute, Indicator, and Self Assessment`
  }, {
    value: 'category_attribute_indicator',
    label: 'Category, Attribute, Indicator, and Self Assessment'
  }, {
    value: 'attribute_and_indicator',
    label: 'Attribute, Indicator, and Self Assessment'
  }
];

const appetiteOptions = [
  { value: 'elevated_or_exceeds', label: 'Elevated or Exceeds' },
  { value: 'elevated', label: 'Elevated' },
  { value: 'exceeds', label: 'Exceeds' }
];

const peerAverageOptions = [
  { value: 'exceeding_peer_average', label: 'Exceeds' },
  { value: 'lte_peer_average', label: 'Does not exceed' }
];

const policyLimitOptions = [
  { value: 'exceeding_policy_limit', label: 'Exceeds' },
  { value: 'lte_policy_limit', label: 'Does not exceed' }
];

const customMeasurementPoint1Options = [
  { value: 'exceeding_custom_1', label: 'Exceeds' },
  { value: 'lte_custom_1', label: 'Does not exceed' }
];

const customMeasurementPoint2Options = [
  { value: 'exceeding_custom_2', label: 'Exceeds' },
  { value: 'lte_custom_2', label: 'Does not exceed' }
];

const directionOfRiskOptions = [
  { value: 'increased', label: 'Increased >5%' },
  { value: 'any_increase', label: 'Any Increase' },
  { value: 'stable', label: 'Stable (-5% to 5%)' },
  { value: 'decreased', label: 'Decreased (<-5%)' },
  { value: 'any_decrease', label: 'Any Decrease' },
];

const attributeOptionsLoader = _.memoize( () => {
  return ax({url: '/reporting/packages/risk_attribute_risk_entity_definitions'})
    .then( (res) => res.data );
});

const attributeOptionsFilter = (risk_category_definition_ids) => ({data}) => {
  const {risk_category_definition_id} = data;
  return _.isEmpty(risk_category_definition_ids) ||
    _.includes(risk_category_definition_ids, risk_category_definition_id);
};

const indicatorOptionsLoader = _.memoize( () => {
  return ax({url: '/reporting/packages/risk_attribute_indicator_risk_entity_definitions'})
    .then( (res) => res.data );
});

const indicatorOptionsFilter = (risk_category_definition_ids, risk_attribute_risk_entity_definition_ids) => ({data}) => {
  const {
    risk_category_definition_id,
    risk_attribute_risk_entity_definition_id
  } = data;

  return (
    _.isEmpty(risk_category_definition_ids) ||
    _.includes(risk_category_definition_ids, risk_category_definition_id)
  ) && (
    _.isEmpty(risk_attribute_risk_entity_definition_ids) ||
    _.includes(risk_attribute_risk_entity_definition_ids, risk_attribute_risk_entity_definition_id)
  );
};

const ratingOptionsLoader = _.memoize( () => {
  return ax({url: '/reporting/packages/ratings'})
    .then( (res) => res.data );
});

class ProfileTrendReportAdvanced extends React.Component {
  static title = 'Profile Trend Report Section Configuration';

  handleSelectChange = (key) => (option) => {
    this.props.onChange({[key]: _.get(option, 'value', null)});
  };

  render() {
    return (
      <div>
        <FormGroup label='Category'>
          <MultiSelectWithLoader
            name='risk_category_definition_ids'
            value={this.props.risk_category_definition_ids}
            loader={riskCategoryDefinitionsLoader}
            onChange={this.props.onChange}
          />
        </FormGroup>

        <div className='form-group'>
          <div>
            <label>
              Level of Detail
              <QuestionInfoTooltip
                content={`${window.riskEntitySettings.composite_risk_label} Only: Show Time Period ${window.riskEntitySettings.plural_composite_risk_rating_label}. Category Only: Show Category level ratings. ${window.riskEntitySettings.composite_risk_label}, Category, Attribute, Indicator, and Self Assessment: Shows Time Period ${window.riskEntitySettings.plural_composite_risk_rating_label_pluralized}, Category level ratings, Attribute level ratings, KRI level ratings and Self Assessment Consideration level ratings. Category, Attribute, Indicator, and Self Assessment: Shows Category Level ratings, Attribute level ratings, KRI level ratings and Self Assessment Consideration level ratings. Attribute, Indicator, and Self Assessment: Shows only Attribute level ratings, KRI Level ratings and Self Assessment Consideration level ratings.`}
                html={false}
                placement='bottom'
                trigger='hover'
              />
            </label>
          </div>
          <div>
            <SimpleSelect
              name='detail_level'
              value={this.props.detail_level}
              options={detailLevelOptions}
              onChange={this.props.onChange}
            />
          </div>
        </div>

        <FormGroup label='Attribute'>
          <MultiSelectWithLoader
            key={this.props.risk_category_definition_ids}
            name='risk_attribute_risk_entity_definition_ids'
            value={this.props.risk_attribute_risk_entity_definition_ids}
            loader={attributeOptionsLoader}
            filterOption={attributeOptionsFilter(this.props.risk_category_definition_ids)}
            onChange={this.props.onChange}
          />
        </FormGroup>

        <FormGroup label='Indicator'>
          <MultiSelectWithLoader
            key={this.props.risk_category_definition_ids + this.props.risk_attribute_risk_entity_definition_ids}
            name='risk_attribute_indicator_risk_entity_definition_ids'
            value={this.props.risk_attribute_indicator_risk_entity_definition_ids}
            loader={indicatorOptionsLoader}
            filterOption={indicatorOptionsFilter(this.props.risk_category_definition_ids, this.props.risk_attribute_risk_entity_definition_ids)}
            onChange={this.props.onChange}
          />
        </FormGroup>

        <FormGroup label='Time Period Range'>
          <TimePeriodRangePicker
            start_time_period={this.props.start_time_period}
            end_time_period={this.props.end_time_period}
            onChange={this.props.onChange}
            showQuarters
            showMonths={window.riskEntitySettings.monthly_kri}
          />
        </FormGroup>

        <FormGroup label={window.riskEntitySettings.generic_rating_label}>
          <MultiSelectWithLoader
            name='ratings'
            value={this.props.ratings}
            loader={() => ratingOptionsLoader().then( (data) => data.ratings )}
            onChange={this.props.onChange}
          />
        </FormGroup>

        <FormGroup label='Inherent Risk Domain Rating'>
          <MultiSelectWithLoader
            name='inherent_cybersecurity_ratings'
            value={this.props.inherent_cybersecurity_ratings}
            loader={() => ratingOptionsLoader().then( (data) => data.inherent_cybersecurity_ratings )}
            onChange={this.props.onChange}
          />
        </FormGroup>

        <FormGroup label='Quality of Risk Management Domain Rating'>
          <MultiSelectWithLoader
            name='quality_cybersecurity_ratings'
            value={this.props.quality_cybersecurity_ratings}
            loader={() => ratingOptionsLoader().then( (data) => data.quality_cybersecurity_ratings )}
            onChange={this.props.onChange}
          />
        </FormGroup>

        <FormGroup label={window.riskEntitySettings.risk_appetite_label}>
          <SimpleSelect
            name='appetite'
            value={this.props.appetite}
            options={appetiteOptions}
            onChange={this.props.onChange}
          />
        </FormGroup>

        <FormGroup label={window.riskEntitySettings.peer_average_label}>
          <SimpleSelect
            name='peer_average'
            value={this.props.peer_average}
            options={peerAverageOptions}
            onChange={this.props.onChange}
          />
        </FormGroup>

        <FormGroup label={window.riskEntitySettings.policy_limit_label}>
          <SimpleSelect
            name='policy_limit'
            value={this.props.policy_limit}
            options={policyLimitOptions}
            onChange={this.props.onChange}
          />
        </FormGroup>

        <FormGroup label={window.riskEntitySettings.custom_measurement_point_1_label}>
          <SimpleSelect
            name='custom_measurement_point_1'
            value={this.props.custom_measurement_point_1}
            options={customMeasurementPoint1Options}
            onChange={this.props.onChange}
          />
        </FormGroup>

        <FormGroup label={window.riskEntitySettings.custom_measurement_point_2_label}>
          <SimpleSelect
            name='custom_measurement_point_2'
            value={this.props.custom_measurement_point_2}
            options={customMeasurementPoint2Options}
            onChange={this.props.onChange}
          />
        </FormGroup>

        <FormGroup label='Direction of Risk'>
          <SimpleSelect
            name='direction_of_risk'
            value={this.props.direction_of_risk}
            options={directionOfRiskOptions}
            onChange={this.props.onChange}
          />
        </FormGroup>

        <FormGroup label='Tags'>
          <MultiSelectWithLoader
            name='risk_tags'
            value={this.props.risk_tags}
            loader={riskTagOptionsLoader}
            onChange={this.props.onChange}
          />
        </FormGroup>
      </div>
    );
  }
}

function SimpleSelect(props) {
  let value = _.find(props.options, _.matchesProperty('value', props.value));
  // Setting value to undefined doesn't clear the Select component
  // appears to be a bug in react-select
  // setting value to null works as expected
  if (value === undefined) {
    value = null;
  }
  const onChange = (option) => props.onChange({
    [props.name]: _.get(option, 'value', null)
  });

  return (
    <Select
      isClearable={true}
      isSearchable={false}
      placeholder=''
      options={props.options}
      value={value}
      onChange={onChange}
    />
  );
}

export default ProfileTrendReportAdvanced;
