import _ from 'lodash';
import React from 'react';
import Select from 'react-select';
import AsyncPaginate from 'react-select-async-paginate';
import Button from 'global/components/Button';
import MonthlySwitch from 'benchmarks/components/MonthlySwitch';
import QuarterlySwitch from 'benchmarks/components/QuarterlySwitch';
import QuestionInfoTooltip from 'global/components/QuestionInfoTooltip';
import LimitTypes from 'settings/components/LimitTypes';
import {RaidParams} from 'administration/components/RaidParams';
import CeilingForm from 'benchmarks/components/CeilingForm';
import FloorForm from 'benchmarks/components/FloorForm';
import SelectDisplay from 'benchmarks/components/SelectDisplay';
import AbsoluteValueForm from 'benchmarks/components/AbsoluteValueForm';
import BidirectionalForm from 'benchmarks/components/BidirectionalForm';
import ValidationMessage from 'benchmarks/components/ValidationMessage';
import {ifSuccessful} from 'global/promise';
import ax from 'global/axios';

class RiskAttributeIndicatorNewForm extends React.Component {

  constructor(props) {
    super(props);

    const element = document.querySelector('#react-root');

    this.state = {
      monthlyFeatureFlag: true,
      submitting: false,
      previousUrl: element.dataset.previousUrl,
      dataInputsUrl: element.dataset.dataInputsUrl,
      validationUrl: element.dataset.validationUrl,
      submissionUrl: element.dataset.submissionUrl,
      attributeName: element.dataset.attributeName,
      attributeId: element.dataset.attributeId,
      enabledQuarterly: true,
      enabledMonthly: false,
      quarterlyBenchmarks: null,
      monthlyBenchmarks: null,
      limitType: { value: 'ceiling', label: 'Ceilng' },
      validation: {
        name: '',
        dataInput: '',
        monthlyBenchmarks: '',
        quarterlyBenchmarks: ''
      },
    };

    this.handleDataInputChange = this.handleDataInputChange.bind(this);
    this.loadDataInputs = this.loadDataInputs.bind(this);
  }

  async loadDataInputs(search, loadedOptions, { page }) {
    const response = await ax({
      url: this.state.dataInputsUrl,
      params: {
        q: search,
        page: page
      },
      method: 'get',
    }).then((res) => {
      return res.data;
    }).catch(error => {
      ax.handleError(error);
    });

    return {
      options: response.results,
      hasMore: response.has_more,
      additional: {
        page: page + 1,
      },
    };
  }

  handleDataInputChange(event) {
    const label = event === null ? '' : event.label;
    const value = event === null ? null : event.value;

    const valueFormat = event === null ? null : event.valueFormat;
    const selectOptions = event === null ? null : event.selectOptions;

    this.resetBenchmarks(valueFormat);

    this.setState({
      selectedDataInput: {
        label: label,
        value: value
      },
      dataInputName: label,
      dataInputSelectOptions: selectOptions,
    });
  }

  handleLimitTypeChange = (limitType) => {
    this.setState({limitType});
  };

  handleQuarterlyBenchmarksChange = (benchmarks) => {
    this.setState({quarterlyBenchmarks: benchmarks});
  };

  handleMonthlyBenchmarksChange = (benchmarks) => {
    this.setState({monthlyBenchmarks: benchmarks});
  };

  resetBenchmarks = (dataInputValueFormat) => {
    const enabledMonthly = this.state.enabledMonthly;

    var limitType = LimitTypes[0];
    var quarterlyBenchmarks = null;
    var monthlyBenchmarks = null;

    if (dataInputValueFormat === 5) {
      limitType = {
        value: 'select',
        label: 'Select'
      };
      quarterlyBenchmarks = [1.0, 2.0, 3.0, 4.0, 5.0, 6.0, null];
      if (enabledMonthly) {
        monthlyBenchmarks = [1.0, 2.0, 3.0, 4.0, 5.0, 6.0, null];
      } else {
        monthlyBenchmarks = null;
      }
    }

    this.setState({
      limitType,
      quarterlyBenchmarks,
      monthlyBenchmarks
    });
  };

  cancelButtonVisible() {
    return !this.state.submitting;
  }

  handleChange = (event) => {
    this.setState({[event.target.name]: event.target.value});
  };

  validate = () => {
    return ax({
      url: this.state.validationUrl,
      method: 'post',
      data: RaidParams(this.state)
    }).then((res) => {
      const {messages} = res.data.errors;
      this.setState({
        validation: {
          name: _.get(messages, 'name[0]', ''),
          dataInput: _.get(messages, 'data_input_definition[0]', ''),
          monthlyBenchmarks: _.get(messages, 'monthly_benchmarks[0]', ''),
          quarterlyBenchmarks: _.get(messages, 'quarterly_benchmarks[0]', '')
        }
      });
      return _.isEmpty(messages);
    });
  };

  submit = () => {
    this.setState({submitting: true});
    return ax({
      url: this.state.submissionUrl,
      method: 'post',
      data: RaidParams(this.state)
    });
  };

  redirect = () => {
    window.location.href = this.state.previousUrl;
  };

  handleFormSubmit = () => {
    return this.validate()
      .then(ifSuccessful(this.submit))
      .then(ifSuccessful(this.redirect))
      .catch(ax.handleError);
  };

  handleCancelClick = (e) => {
    e.preventDefault();
    window.location.href = this.state.previousUrl;
  };

  render() {
    const {
      monthlyFeatureFlag,
      name,
      description,
      definition,
      limitType,
      selectedDataInput,
      enabledMonthly,
      enabledQuarterly,
      monthlyBenchmarks,
      quarterlyBenchmarks,
      submitting,
      validation,
      dataInputSelectOptions
    } = this.state;

    return (
      <div>
        <form className='simple_form'>
          <div className="col-md-12">
            <div className="panel">
              <div className="panel-heading">
                <span className="panel-title">Create Indicator</span>
              </div>
              <div className="panel-body">
                <div className={validation.name ? 'form-group has-error' : 'form-group'}>
                  <label className='control-label string required'>
                    <abbr title='required'>*</abbr>
                      Name
                  </label>
                  <input
                    className={validation.name ? 'error form-control required' : 'form-control required'}
                    type="text"
                    name='name'
                    value={name || ''}
                    onChange={this.handleChange}
                    autoFocus={true}
                  />
                  {validation.name &&
                    <span className='help-block error-block'>{validation.name}</span>
                  }
                </div>
                <div className='form-group'>
                  <label className='control-label'>Description</label>
                  <input className='form-control' type="text" name='description' value={description || ''} onChange={this.handleChange} />
                </div>
                <div className='form-group'>
                  <label className='control-label'>Definition</label>
                  <input className='form-control' type="text" name='definition' value={definition || ''} onChange={this.handleChange} />
                </div>
                <div className='form-group'>
                  <label className='control-label'>
                    {window.riskEntitySettings.inherent_risk_attribute_label}
                    <QuestionInfoTooltip
                      content="The risk attribute is determined from the previous selection made at the list of available risk attributes."
                      html={false}
                      placement='bottom'
                      trigger='hover'
                    />
                  </label>
                  <input className='form-control' type="text" name='rared' value={this.state.attributeName} disabled />
                </div>
                <div className={validation.dataInput ? 'has-error form-group select' : 'form-group select'}>
                  <label className="select control-label">
                    <abbr title='required'>*</abbr>
                    Data Input
                  </label>
                  <AsyncPaginate
                    debounceTimeout={300}
                    id={'direds'}
                    className='dataInput'
                    classNamePrefix='dataInput'
                    value={selectedDataInput}
                    onChange={this.handleDataInputChange}
                    loadOptions={this.loadDataInputs}
                    additional={{page: 1}}
                    isClearable={true}
                    styles={{ menuList: (provided, _state) => ({ ...provided, maxHeight: 200 }) }}
                  />
                  {validation.dataInput &&
                    <span className='help-block error-block'>{validation.dataInput}</span>}
                </div>
                { limitType.value != 'select' &&
                  <div className='select'>
                    <label className='control-label'>
                      Benchmark Type
                    </label>
                    <QuestionInfoTooltip
                      content="<div class='text-left'><strong>Ceiling:</strong> Risk increases as value increases.<br/><br/><strong>Floor:</strong> Risk decreases as value increases.<br/><br/><strong>Absolute Value:</strong> Same as ceiling but using positive indicator value.<br/><br/><strong>Bidirectional:</strong> Risk is lowest at the midpoint and increases outward in both directions.</div>"
                      html={true}
                      placement='bottom'
                      trigger='hover'
                    />
                    <Select
                      id='limitTypes'
                      className='limitType'
                      classNamePrefix='limitType'
                      value={limitType}
                      onChange={this.handleLimitTypeChange}
                      options={LimitTypes}
                      isClearable={false}
                      styles={{ menuList: (provided, _state) => ({ ...provided, maxHeight: 200 }) }}
                    />
                    <br/>
                  </div>
                }
                <br/>
                <div id='quarterly-benchmarks'>
                  { monthlyFeatureFlag &&
                    <QuarterlySwitch value={enabledQuarterly} onChange={this.handleChange} />
                  }
                  { enabledQuarterly &&
                    <>
                      <ValidationMessage>{validation.quarterlyBenchmarks}</ValidationMessage>
                      {limitType.value == 'select' &&
                        <>
                          <div className="alert-message info-alert-message">
                            <p>The list of selectable options for this indicator can be configured via the data input after this page has been submitted.</p>
                          </div>
                          <SelectDisplay selectOptions={dataInputSelectOptions} />
                        </>
                      }
                      {limitType.value == 'ceiling' &&
                        <CeilingForm benchmarks={quarterlyBenchmarks} onChange={this.handleQuarterlyBenchmarksChange} />
                      }
                      {limitType.value == 'floor' &&
                        <FloorForm benchmarks={quarterlyBenchmarks} onChange={this.handleQuarterlyBenchmarksChange} />
                      }
                      {limitType.value == 'absolute_value' &&
                        <AbsoluteValueForm benchmarks={quarterlyBenchmarks} onChange={this.handleQuarterlyBenchmarksChange} />
                      }
                      {limitType.value == 'bidirectional' &&
                        <BidirectionalForm benchmarks={quarterlyBenchmarks} onChange={this.handleQuarterlyBenchmarksChange} />
                      }
                    </>
                  }
                </div>
                <div id='monthly-benchmarks'>
                  { monthlyFeatureFlag &&
                    <MonthlySwitch value={enabledMonthly} onChange={this.handleChange} />
                  }
                  { enabledMonthly &&
                    <>
                      <ValidationMessage>{validation.monthlyBenchmarks}</ValidationMessage>
                      {limitType.value == 'select' &&
                        <>
                          <div className="alert-message info-alert-message">
                            <p>The list of selectable options for this indicator can be configured via the data input after this page has been submitted.</p>
                          </div>
                          <SelectDisplay selectOptions={dataInputSelectOptions} />
                        </>
                      }
                      {limitType.value == 'ceiling' &&
                        <CeilingForm benchmarks={monthlyBenchmarks} onChange={this.handleMonthlyBenchmarksChange} />
                      }
                      {limitType.value == 'floor' &&
                        <FloorForm benchmarks={monthlyBenchmarks} onChange={this.handleMonthlyBenchmarksChange} />
                      }
                      {limitType.value == 'absolute_value' &&
                        <AbsoluteValueForm benchmarks={monthlyBenchmarks} onChange={this.handleMonthlyBenchmarksChange} />
                      }
                      {limitType.value == 'bidirectional' &&
                        <BidirectionalForm benchmarks={monthlyBenchmarks} onChange={this.handleMonthlyBenchmarksChange} />
                      }
                    </>
                  }
                </div>
                <Button
                  onClick={this.handleFormSubmit}
                  isDisabled={submitting}
                  isAsync={true}
                  label={'Create Indicator'}
                />
                {this.cancelButtonVisible() &&
                  <a href={this.state.previousUrl} className='btn btn-link cancel-link' onClick={this.handleCancelClick}>Cancel</a>}
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default RiskAttributeIndicatorNewForm;
