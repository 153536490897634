import _ from 'lodash';
import React from 'react';
import Routes from 'global/routes';
import Modal from 'react-bootstrap/Modal';
import RairedStepOneForm from './RairedStepOneForm';
import ChooseDiredMode from './ChooseDiredMode';
import SelectExistingDataInputRiskEntityDefinition from './SelectExistingDataInputRiskEntityDefinition';
import CreateDataInputRiskEntityDefinitionForm from './CreateDataInputRiskEntityDefinitionForm';
import CreateRiskObjectKriForm from './CreateRiskObjectKriForm';
import RairedStepTwoForm from './RairedStepTwoForm';
import RairedConfirmation from './RairedConfirmation';
import {RairedParams} from 'settings/components/RairedParams';
import styles from '../../styles/RairedModal';
import {ifSuccessful} from 'global/promise';
import ax from 'global/axios';

class RiskAttributeIndicatorSetupContainer extends React.Component {

  constructor(props) {
    super(props);

    const element = document.querySelector('#react-root');

    this.state = {
      modalOpen: false,
      modalIn: false, // Workaround to fix animation transition
      submitting: false,
      currentStep: 0,
      timePeriodParam: element.dataset.timePeriodParam,
      riskCategoryParam: element.dataset.riskCategoryParam,
      riskAttributeParam: element.dataset.riskAttributeParam,
      raredID: element.dataset.raredId,
      canEditObject: element.dataset.editableObject == 'true',
      monthlyFeatureFlag: window.riskEntitySettings.monthly_kri,
      diredMode: null,
      selectedDataInput: null,
      dataInputSection: null,
      dataInputDefinition: '',
      dataInputValueFormat: null,
      dataInputDecimalPlaces: '',
      dataInputSelectOptions: null,
      limitType: null,
      enabledMonthly: false,
      enabledQuarterly: true,
      quarterlyBenchmarks: null,
      monthlyBenchmarks: null,
      indicatorType: '',
      areaOfRisk: null,
      riskObject: null,
      ratingSelect: null,
      riskEntity: null,
      validation: {
        monthlyBenchmarks: '',
        quarterlyBenchmarks: '',
        formattedMonthlyBenchmarks: [],
        formattedQuarterlyBenchmarks: [],
        typeable: '',
        ratingSelect: ''
      }
    };
  }

  isSelectType = () => _.get(this.state, 'dataInputValueFormat.value') == 5;

  modalTitle = () => {
    let rairedName = this.state.rairedName;
    return rairedName ? `New ${window.riskEntitySettings.indicator_label}: ${rairedName}` : `New ${window.riskEntitySettings.indicator_label}`;
  };

  next = () => {
    const { monthlyFeatureFlag } = this.state;
    let currentStep = this.state.currentStep;
    if (currentStep == 1 && !monthlyFeatureFlag && this.isSelectType()) {
      this.setState({currentStep: 3}); // Skip benchmarks for select format unless months enabled
    } else if (currentStep == 1 && this.state.indicatorType == 'risk_object_rated') {
      this.validateRiskObject()
        .then(ifSuccessful(() => this.setState({currentStep: 3})))
        .catch(this.handleError);
    } else if (currentStep == 2) {
      this.validate()
        .then(ifSuccessful(() => this.setState({currentStep: 3})))
        .catch(this.handleError);
    } else {
      currentStep = currentStep + 1;
      this.setState({
        currentStep: currentStep
      });
    }
  };

  prev = () => {
    const { monthlyFeatureFlag } = this.state;
    let currentStep = this.state.currentStep;

    if (currentStep == 3 && !monthlyFeatureFlag && this.isSelectType()) {
      this.setState({currentStep: 1}); // Skip benchmarks for select format unless months enabled
    } else if (currentStep == 3 && this.state.indicatorType == 'risk_object_rated') {
      this.setState({currentStep: 1});
    } else {
      currentStep = currentStep - 1;
      this.setState({
        currentStep: currentStep
      });
    }
  };

  validate = () => {
    let data = RairedParams(this.state);
    data.value_format = this.state.dataInputValueFormat.value;
    data.precision = this.state.dataInputDecimalPlaces;

    return ax({
      url: '/settings/overrides/indicator_overrides/validate_and_format',
      method: 'post',
      data: data
    }).then((res) => {
      const {errors, formatted_monthly_benchmarks, formatted_quarterly_benchmarks} = res.data;
      const monthlyBenchmarksMessage = _.get(errors, 'messages.monthly_benchmarks[0]', '');
      const quarterlyBenchmarksMessage = _.get(errors, 'messages.quarterly_benchmarks[0]', '');
      this.setState({
        validation: {
          monthlyBenchmarks: monthlyBenchmarksMessage,
          quarterlyBenchmarks: quarterlyBenchmarksMessage,
          formattedMonthlyBenchmarks: formatted_monthly_benchmarks,
          formattedQuarterlyBenchmarks: formatted_quarterly_benchmarks
        }
      });
      return !monthlyBenchmarksMessage && !quarterlyBenchmarksMessage;
    });
  };

  validateRiskObject = () => {
    return ax({
      url: '/settings/overrides/indicator_overrides/validate_and_format',
      method: 'post',
      data: RairedParams(this.state)
    }).then((res) => {
      const {messages} = res.data.errors;
      this.setState({
        validation: {
          typeable: _.get(messages, 'typeable_id[0]', ''),
          ratingSelect: _.get(messages, 'rating_type[0]', '')
        }
      });
      return _.isEmpty(messages);
    });
  };

  handleStepTwo = (stateChanges) => {
    this.setState(stateChanges);
  };

  handleRairedStepOneFormDataChange = (formData) => {
    const prevName = this.state.rairedName;
    const prevDescription = this.state.description;
    const prevDefinition = this.state.definition;

    if (this.state.modalOpen == false) {
      return; // Clear the form if modal is closed on the first step
    }

    if (prevName != formData['name']) {
      this.setState({ rairedName: formData['name'] });
    }
    if (prevDescription != formData['description']) {
      this.setState({ description: formData['description'] });
    }
    if (prevDefinition != formData['definition']) {
      this.setState({ definition: formData['definition'] });
    }
  };

  handleCreateDiredForm = (stateChanges) => {
    if (stateChanges.dataInputValueFormat) {
      const valueFormat = stateChanges.dataInputValueFormat.value;
      stateChanges.limitType = valueFormat === 5 ? { value: 'select', label: 'Select' } : null;
    }
    stateChanges.indicatorType = 'data_input_rated';
    this.setState(stateChanges);
  };

  handleCreateRiskObjectForm = (stateChanges) => {
    stateChanges.indicatorType = 'risk_object_rated';
    stateChanges.limitType = { value: 'ceiling', label: 'Ceiling' };
    this.setState(stateChanges);
  };

  createDataInputRiskEntityDefinition = (diredProperties) => {
    return ax({
      url: Routes.settings_overrides_data_input_section_data_input_risk_entity_definitions_path(diredProperties.sectionId),
      method: 'post',
      data: {
        data_input_risk_entity_definition: {
          data_input_type: 1,
          data_input_level: 2,
          name: diredProperties.name,
          definition: diredProperties.definition,
          value_format: diredProperties.valueFormat,
          decimal_places: diredProperties.decimalPlaces,
          select_options: diredProperties.selectOptions,
          visible: true
        }
      }
    }).then((res) => {
      return res.data;
    }).catch(this.handleError);
  };

  handleFormSubmit = async () => {
    this.setState({submitting: true});

    if (this.state.diredMode === 'create-new') {
      const createdDired = await this.createDataInputRiskEntityDefinition({
        sectionId: this.state.dataInputSection.value,
        name: this.state.rairedName.trim(),
        definition: this.state.dataInputDefinition,
        valueFormat: this.state.dataInputValueFormat.value,
        decimalPlaces: this.state.dataInputDecimalPlaces,
        selectOptions: this.state.dataInputSelectOptions
      });
      this.setState({createdDired: {value: createdDired['id'], label: this.state.rairedName}});
    }

    if (this.state.diredMode === 'create-from-object') {
      this.setState({
        riskObject: {value: this.state.riskObject.value, label: this.state.riskObject.label},
        areaOfRisk: {value: this.state.areaOfRisk.value, label: this.state.areaOfRisk.label}
      });
    }

    const rairedParams = RairedParams(this.state);

    return ax({
      url: Routes.settings_overrides_indicator_overrides_risk_attribute_indicators_path(),
      method: 'post',
      data: rairedParams
    }).then((_res) => {
      const url = `/${this.state.timePeriodParam}/risk_categories/${this.state.riskCategoryParam}/attributes/${this.state.riskAttributeParam}/edit_risk_attribute_indicators`;
      window.location.href = url;
    }).catch(this.handleError);
  };

  handleError = (error) => {
    this.setState({
      currentStep: 0,
      modalOpen: false,
      modalIn: false
    });
    ax.handleError(error);
  };

  handleDiredModeChange = (mode) => {
    this.setState({
      diredMode: mode,
      selectedDataInput: null,
      dataInputSection: null,
      dataInputValueFormat: null,
      dataInputDecimalPlaces: '',
      dataInputSelectOptions: null,
      // Variables for RiskObjectRated. Setting to blank string instead of null because RairedParams.js errors out finding the value if it is an object type param
      indicatorType: '',
      areaOfRisk: '',
      riskObject: '',
      ratingSelect: '',
      riskEntity: ''
    });
  };

  handleSelectedDataInputChange = (event) => {
    const label = event === null ? '' : event.label;
    const value = event === null ? null : event.value;

    const definition = event === null ? null : event.definition;
    const valueFormat = event === null ? null : event.valueFormat;
    const valueFormatLabel = event === null ? null : event.valueFormatName;
    const decimalPlaces = event === null ? null : event.decimalPlaces;
    const selectOptions = event === null ? null : event.selectOptions;

    this.setState({
      limitType: valueFormat === 5 ? { value: 'select', label: 'Select' } : null,
      selectedDataInput: { value: value, label: label },
      dataInputDefinition: definition,
      dataInputValueFormat: { value: valueFormat, label: valueFormatLabel },
      dataInputDecimalPlaces: decimalPlaces,
      dataInputSelectOptions: selectOptions,
      indicatorType: 'data_input_rated'
    });
  };

  loadDataInputRiskEntityDefinitions = async (search, loadedOptions, { page }) => {
    const response = await ax({
      url: '/settings/overrides/indicator_overrides/data_input_risk_entity_definitions',
      params: {
        q: search,
        page: page
      },
      method: 'get',
    }).then((res) => {
      return res.data;
    }).catch(this.handleError);

    return {
      options: response.results,
      hasMore: response.has_more,
      additional: {
        page: page + 1,
      },
    };
  };

  render() {
    const { modalOpen, canEditObject } = this.state;
    const animationClassName = this.state.modalIn ? 'in ' : '';
    const makeAnimationWork = {
      onEntering: this.handleModalEntering,
      onExit: this.handleModalExit,
      backdropClassName: animationClassName,
      className: animationClassName + styles.modal
    };

    return (
      <div>
        <button className='btn btn-primary' onClick={this.handleShowModal}>
          New {window.riskEntitySettings.indicator_label}
        </button>
        <Modal centered animation
          style={{zIndex:1040}}
          dialogClassName={styles.kriWizardModal}
          show={modalOpen}
          onHide={this.handleModalHide}
          {...makeAnimationWork}
        >
          <Modal.Header>
            <button className='display-md-inline close' onClick={this.handleModalExit}>&times;</button>
            <h4 className='modal-title overflow-ellipsis'>{this.modalTitle()}</h4>
          </Modal.Header>
          { this.state.currentStep == 0 &&
            <RairedStepOneForm
              name={this.state.rairedName}
              description={this.state.description}
              definition={this.state.definition}
              onFormDataChange={this.handleRairedStepOneFormDataChange}
              onNext={this.next}
            />
          }
          { this.state.currentStep == 1 && this.state.diredMode == null &&
            <ChooseDiredMode
              handleModeChange={this.handleDiredModeChange}
              onBack={this.prev}
              canEditObject={canEditObject}
            />
          }
          {this.state.currentStep == 1 && this.state.diredMode == 'select-existing' &&
            <SelectExistingDataInputRiskEntityDefinition
              loadOptions={this.loadDataInputRiskEntityDefinitions}
              onChange={this.handleSelectedDataInputChange}
              onModeChange={this.handleDiredModeChange}
              selectedDataInput={this.state.selectedDataInput}
              canEditObject={canEditObject}
              onNext={this.next}
              onBack={this.prev}
            />
          }
          {this.state.currentStep == 1 && this.state.diredMode == 'create-new' &&
            <CreateDataInputRiskEntityDefinitionForm
              dataInputSection={this.state.dataInputSection}
              dataInputDefinition={this.state.dataInputDefinition}
              dataInputValueFormat={this.state.dataInputValueFormat}
              dataInputDecimalPlaces={this.state.dataInputDecimalPlaces}
              dataInputSelectOptions={this.state.dataInputSelectOptions}
              onChange={this.handleCreateDiredForm}
              onError={this.handleError}
              onModeChange={this.handleDiredModeChange}
              canEditObject={canEditObject}
              onNext={this.next}
              onBack={this.prev}
            />
          }
          {this.state.currentStep == 1 && this.state.diredMode == 'create-from-object' &&
            <CreateRiskObjectKriForm
              areaOfRisk={this.state.areaOfRisk}
              riskObject={this.state.riskObject}
              ratingSelect={this.state.ratingSelect}
              riskEntity={this.state.riskEntity}
              handleRairedStepOneFormDataChange={this.handleRairedStepOneFormDataChange}
              validation={this.state.validation}
              onChange={this.handleCreateRiskObjectForm}
              onError={this.handleError}
              onModeChange={this.handleDiredModeChange}
              onNext={this.next}
              onBack={this.prev}
            />
          }
          { this.state.currentStep == 2 &&
            <RairedStepTwoForm
              limitType={this.state.limitType}
              valueFormatLabel={this.state.dataInputValueFormat.label}
              decimalPlaces={this.state.dataInputDecimalPlaces}
              dataInputSelectOptions={this.state.dataInputSelectOptions}
              enabledMonthly={this.state.enabledMonthly}
              enabledQuarterly={this.state.enabledQuarterly}
              monthlyBenchmarks={this.state.monthlyBenchmarks}
              quarterlyBenchmarks={this.state.quarterlyBenchmarks}
              validation={this.state.validation}
              onChange={this.handleStepTwo}
              onNext={this.next}
              onBack={this.prev}
            />
          }
          { this.state.currentStep == 3 &&
            <RairedConfirmation
              rairedName={this.state.rairedName}
              rairedDescription={this.state.description}
              rairedDefinition={this.state.definition}
              selectedDired={this.state.selectedDataInput ? true : false}
              diredName={this.state.selectedDataInput ? this.state.selectedDataInput.label : this.state.rairedName}
              diredDefinition={this.state.dataInputDefinition}
              diredValueFormatLabel={this.state.dataInputValueFormat ? this.state.dataInputValueFormat.label : ''}
              diredDecimalPlaces={this.state.dataInputDecimalPlaces}
              dataInputSelectOptions={this.state.dataInputSelectOptions}
              limitType={this.state.limitType}
              indicatorType={this.state.indicatorType}
              riskObject={this.state.riskObject}
              ratingSelect={this.state.ratingSelect}
              riskEntity={this.state.riskEntity}
              areaOfRisk={this.state.areaOfRisk}
              enabledMonthly={this.state.enabledMonthly}
              enabledQuarterly={this.state.enabledQuarterly}
              validation={this.state.validation}
              submitting={this.state.submitting}
              onBack={this.prev}
              onCancel={this.handleCancelClick}
              onSubmit={this.handleFormSubmit}
            />
          }
        </Modal>
      </div>
    );
  }

  handleShowModal = (e) => {
    e.preventDefault();
    this.setState({modalOpen: true});
  };

  handleCancelClick = (e) => {
    e.preventDefault();
    this.setState({modalIn: false});
    const url = `/${this.state.timePeriodParam}/risk_categories/${this.state.riskCategoryParam}/attributes/${this.state.riskAttributeParam}/edit_risk_attribute_indicators`;
    window.location.href = url;
  };

  handleModalEntering = () => {
    this.setState({modalIn: true});
  };

  handleModalExit = () => {
    this.setState({
      modalOpen: false,
      modalIn: false, // Workaround to fix animation transition
      submitting: false,
      currentStep: 0,
      diredMode: null,
      selectedDataInput: null,
      dataInputSection: null,
      dataInputDefinition: '',
      dataInputValueFormat: null,
      dataInputDecimalPlaces: '',
      dataInputSelectOptions: null,
      limitType: null,
      enabledMonthly: false,
      enabledQuarterly: true,
      quarterlyBenchmarks: null,
      monthlyBenchmarks: null,
      indicatorType: '',
      areaOfRisk: null,
      riskObject: null,
      ratingSelect: null,
      riskEntity: null,
      validation: {
        monthlyBenchmarks: '',
        quarterlyBenchmarks: '',
        formattedMonthlyBenchmarks: [],
        formattedQuarterlyBenchmarks: [],
        typeable: '',
        ratingSelect: ''
      },
      rairedName: '',
      definition: '',
      description: ''
    });
  };

  handleModalHide = () => {
    this.setState({modalOpen: false});
  };
}

export default RiskAttributeIndicatorSetupContainer;
