import React from 'react';
import PropTypes from 'prop-types';
import styles from 'risk_management/styles/Confirmation';

class DisplayRow extends React.Component {
  render() {
    const record = this.props.record;
    return (
      <>
        <li className={styles.confirmationItem}>
          {record.name}
        </li>
      </>
    );
  }
}

DisplayRow.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    name: PropTypes.string
  }),
  headerName: PropTypes.string
};

export default DisplayRow;
