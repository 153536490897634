import React from 'react';
import * as powerbi from 'powerbi-client';
import ax from 'global/axios';

class PowerBIEmbed extends React.Component {
  constructor(props) {
    super(props);

    const element = document.querySelector('#react-root');
    const embedContainer = document.getElementById('powerbi-report-container');

    this.state = {
      visId: element.dataset.visualizationId,
      embedContainer: embedContainer,
    };
  }

  fetchVisualization = () => {
    const { visId, embedContainer } = this.state;

    ax.get(`/data_visualizations/${visId}/load_embed_config`)
      .then((response) => {
        const embedUrl = response.data.report_details.embed_url;
        const accessToken = response.data.token;

        const embedConfig = {
          type: 'report',
          embedUrl: embedUrl,
          accessToken: accessToken,
          tokenType: powerbi.models.TokenType.Embed,
          settings: {
            // Enable this setting to remove gray shoulders from embedded report
            // background: models.BackgroundType.Transparent,
            //filterPaneEnabled: false,
            //navContentPaneEnabled: false,
            // DisplayOption: 2,
            layoutType: powerbi.models.LayoutType.FitToWidth,
            panes: {
              filters: {
                visible: false
              },
              // pageNavigation: {
              //   visible: true//,
              //   position: models.PageNavigationPosition.Left
              // }
            }
          }
        };

        const powerbiService = new powerbi.service.Service(
          powerbi.factories.hpmFactory,
          powerbi.factories.wpmpFactory,
          powerbi.factories.routerFactory,
        );

        powerbiService.embed(embedContainer, embedConfig);
      })
      .catch((error) =>
        console.error('Error fetching PowerBI embed URL', error),
      );
  };

  render() {
    const container = this.fetchVisualization();

    return <div>{container}</div>;
  }
}

export default PowerBIEmbed;
