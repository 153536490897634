import React from 'react';

class RiskEntityMenuItem extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {id, name, url, primary} = this.props.riskEntity;
    const method = this.props.method === null ? 'get' : this.props.method;

    return (
      <li key={id} className="risk-entity-menu-item">
        <a href={url} data-method={method}>
          {name}
          {
            primary && (
              <span className="primary-label">(Primary)</span>
            )
          }
        </a>
      </li>
    );
  }
}

export default RiskEntityMenuItem;
