import React from 'react';
import Select from 'react-select';
import _ from 'lodash';

class TagFilterModePicker extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      options: [ {value: 'all', label: 'All of'}, {value: 'any', label: 'Any of'}]
    };
  }

  selectedOption() {
    const {value} = this.props;
    const {options} = this.state;
    const found = _.find(options, _.matchesProperty('value', value));
    if (found) {
      return found;
    } else {
      // Returning undefined directly from _.find doesn't clear visible selection reliably
      return null;
    }
  }

  handleChange = (option) => {
    const value = _.get(option, 'value', null);
    this.setState({value});
    this.props.onChange({tag_filter_mode: value});
  };

  render() {
    const {options} = this.state;

    const selectProps = {
      options: options,
      value: this.selectedOption(),
      isDisabled: false,
      onChange: this.handleChange,
      styles: this.props.styles
    };

    return (
      <Select {...selectProps} />
    );
  }
}

TagFilterModePicker.defaultProps = {
  value: 'any'
};

export default TagFilterModePicker;