
import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import _ from 'lodash';

const attributeTypeOptions = [
  { value: 0, label: 'Manual' },
  { value: 1, label: 'Indicator Calculated' },
  { value: 2, label: 'Consideration Calculated' },
];

class AttributeForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      touchedName: false
    };
  }

  componentDidMount() {
    if (this.props.componentType === 0) {
      this.setState({
        attributeType: 1,
        attributeTypeMessage: ''
      });
    } else {
      this.setState({
        attributeType: 0,
        attributeTypeMessage: ''
      });
    }
  }

  componentDidUpdate() {
    this.props.onFormDataChange({
      name: this.state.name,
      touchedName: this.state.touchedName,
      attributeType: this.state.attributeType,
      attributeTypeMessage: this.state.attributeTypeMessage,
      ratingDescriptionOne: this.state.ratingDescriptionOne,
      ratingDescriptionTwo: this.state.ratingDescriptionTwo,
      ratingDescriptionThree: this.state.ratingDescriptionThree,
      ratingDescriptionFour: this.state.ratingDescriptionFour,
      ratingDescriptionFive: this.state.ratingDescriptionFive
    });
  }

  handleNameChange = (event) => {
    this.setState({name: event.target.value});
  };

  handleNameBlur = () => {
    this.setState({touchedName: true});
  };

  handleAttributeTypeBlur = () => {
    const {attributeType} = this.state;
    if (attributeType === null) {
      this.setState({attributeTypeMessage: 'Must choose an Attribute Type.'});
    }
  };

  handleAttributeTypeChange = (attributeType) => {
    this.setState({
      attributeType: attributeType.attribute_type,
      attributeTypeMessage: ''
    });
  };

  handleRatingDescriptionChange = (e) => {
    const {name, value} = e.currentTarget;
    this.setState({[name]: value});
  };

  render() {
    const {
      name,
      attributeTypeMessage,
      attributeType,
    } = this.state;

    const nameInvalid = this.state.touchedName && (name === undefined || name.trim().length === 0);

    const textAreaStyles = {
      display: 'block',
      width: '100%',
      height: '8em',
      resize: 'none'
    };

    return (
      <>
        <form className='simple_form'>
          <div className={nameInvalid ? 'form-group has-error' : 'form-group'}>
            <label className='control-label string required'>
              <abbr title='required'>*</abbr>
                Name
            </label>
            <input
              className={nameInvalid ? 'error form-control required' : 'form-control required'}
              onBlur={this.handleNameBlur}
              id='name'
              type="text"
              name='name'
              value={name || ''}
              onChange={this.handleNameChange}
              autoFocus={true}
            />
            {nameInvalid &&
              <span className='help-block error-block'>Can&apos;t be blank</span>
            }
          </div>
          <div className={attributeTypeMessage ? 'has-error form-group select' : 'form-group select'}>
            <label className='control-label string required'>
              <abbr title='required'>*</abbr>
                Attribute Type
            </label>
            <SimpleSelect
              name='attribute_type'
              className='attributeType'
              classNamePrefix='attributeType'
              value={attributeType}
              options={attributeTypeOptions}
              onBlur={this.handleAttributeTypeBlur}
              onChange={this.handleAttributeTypeChange}
            />
            {attributeTypeMessage &&
              <span className='help-block error-block'>{attributeTypeMessage}</span>}
          </div>
          <br/>
          <label className='control-label string required'>
            {window.riskEntitySettings.rating_very_low_label} Rating Description
          </label>
          <textarea name="ratingDescriptionOne" onChange={this.handleRatingDescriptionChange} style={textAreaStyles}></textarea>
          <br/>
          <label className='control-label string required'>
            {window.riskEntitySettings.rating_low_label} Rating Description
          </label>
          <textarea name="ratingDescriptionTwo" onChange={this.handleRatingDescriptionChange} style={textAreaStyles}></textarea>
          <br/>
          <label className='control-label string required'>
            {window.riskEntitySettings.rating_moderate_label} Rating Description
          </label>
          <textarea name="ratingDescriptionThree" onChange={this.handleRatingDescriptionChange} style={textAreaStyles}></textarea>
          <br/>
          <label className='control-label string required'>
            {window.riskEntitySettings.rating_high_label} Rating Description
          </label>
          <textarea name="ratingDescriptionFour" onChange={this.handleRatingDescriptionChange} style={textAreaStyles}></textarea>
          <br/>
          <label className='control-label string required'>
            {window.riskEntitySettings.rating_very_high_label} Rating Description
          </label>
          <textarea name="ratingDescriptionFive" onChange={this.handleRatingDescriptionChange} style={textAreaStyles}></textarea>
        </form>
      </>
    );
  }
}

function SimpleSelect(props) {
  const value = _.find(props.options, _.matchesProperty('value', props.value));
  const onChange = (option) => props.onChange({
    [props.name]: _.get(option, 'value', null)
  });

  return (
    <Select
      isClearable={true}
      isSearchable={false}
      placeholder=''
      className={props.className}
      classNamePrefix={props.classNamePrefix}
      options={props.options}
      value={value}
      onBlur={props.onBlur}
      onChange={onChange}
    />
  );
}

AttributeForm.propTypes = {
  onFormDataChange: PropTypes.func,
  componentType: PropTypes.number
};

export default AttributeForm;
