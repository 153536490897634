import React from 'react';
import PropTypes from 'prop-types';
import ax from 'global/axios';
import _ from 'lodash';
import Modal from 'react-bootstrap/Modal';

class RairedStepOneForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      touchedName: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.debouncedSearch = _.debounce(this.search, 250);
  }

  search = (term) => {
    ax({
      url: '/settings/overrides/indicator_overrides/search',
      params: {q: term},
      method: 'get',
    }).then((res) => {
      this.setState({raireds: res.data});
    }).catch(error => {
      ax.handleError(error);
      this.setState({error: true});
    });
  };

  handleSearchChange = (event) => {
    const search = event.target.value;
    this.setState({search});
    this.debouncedSearch(search);
  };

  componentDidMount() {
    this.setState({
      name: this.props.name,
      description: this.props.description,
      definition: this.props.definition,
    });
  }

  componentDidUpdate() {
    this.props.onFormDataChange({
      name: this.state.name,
      description: this.state.description,
      definition: this.state.definition
    });
  }

  handleNameBlur = () => {
    this.setState({touchedName: true});
  };

  handleChange(event) {
    this.setState({[event.target.name]: event.target.value});
  }

  toggleSearch = () => {
    if (this.state.showSearch === true) {
      this.setState({showSearch: false});
    } else {
      this.setState({showSearch: true});
    }
  };

  render() {
    const {onNext} = this.props;
    const {
      showSearch,
      search,
      raireds,
      name,
      description,
      definition
    } = this.state;

    const nameInvalid = this.state.touchedName && (name === undefined || name.trim().length === 0);

    return (
      <>
        <Modal.Body>
          <div>
            {showSearch &&
            <>
              <div>
                <a href='#' onClick={this.toggleSearch} data-turbolinks="false">
                  Return to {window.riskEntitySettings.indicator_label} Form
                </a>
                <br/>
                <br/>
              </div>
              <div className="form-group">
                <label className='control-label string'>
                  Search {window.riskEntitySettings.indicator_label} Name
                </label>
                <input
                  className="form-control"
                  type="text"
                  name='search'
                  value={search || ''}
                  onChange={this.handleSearchChange}
                  autoFocus={true}
                />
              </div>
              <h4>Results</h4>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Attribute</th>
                    <th>{window.riskEntitySettings.indicator_label}</th>
                  </tr>
                </thead>
                <tbody>
                  {raireds && raireds.length != 0 ?
                    raireds.map(raired => (
                      <tr key={raired.rairedID}>
                        <td className="col-md-6">{raired.raredName}</td>
                        <td className="col-md-6"><a href={`/settings/overrides/indicator_overrides/risk_attribute_indicator_risk_entity_definitions/${raired.rairedID}/edit`}>{raired.rairedName}</a></td>
                      </tr>
                    )) :
                    <tr>
                      <td colSpan="2">
                        <p>No Results</p>
                        <a href='#' onClick={this.toggleSearch} data-turbolinks="false">
                          Return to {window.riskEntitySettings.indicator_label} Form
                        </a>
                      </td>
                    </tr>}
                </tbody>
              </table>
            </>
            }
            {!showSearch &&
            <>
              <div>
                <a href='#' onClick={this.toggleSearch} data-turbolinks="false">
                  Search for Existing {window.riskEntitySettings.indicator_label}
                </a>
                <br/>
                <br/>
              </div>
              <form className='simple_form'>
                <div className={nameInvalid ? 'form-group has-error' : 'form-group'}>
                  <label className='control-label string required'>
                    <abbr title='required'>*</abbr>
                      Name
                  </label>
                  <input
                    className={nameInvalid ? 'error form-control required' : 'form-control required'}
                    onBlur={this.handleNameBlur}
                    type="text"
                    name='name'
                    value={name || ''}
                    onChange={this.handleChange}
                    autoFocus={true}
                  />
                  {nameInvalid &&
                    <span className='help-block error-block'>Can&apos;t be blank</span>
                  }
                </div>
                <div className='form-group'>
                  <label className='control-label'>Description</label>
                  <input className='form-control' type="text" name='description' value={description || ''} onChange={this.handleChange} />
                </div>
                <div className='form-group'>
                  <label className='control-label'>Definition</label>
                  <input className='form-control' type="text" name='definition' value={definition || ''} onChange={this.handleChange} />
                </div>
              </form>
            </>
            }
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary pull-right" type="button" disabled={!_.trim(name)} onClick={onNext}>
          Next
          </button>
        </Modal.Footer>
      </>
    );
  }
}

RairedStepOneForm.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  definition: PropTypes.string,
  onFormDataChange: PropTypes.func,
  onNext: PropTypes.func
};

export default RairedStepOneForm;
