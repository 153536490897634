import React from 'react';

class SelectOptionsFieldset extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {
        veryLow: false,
        low: false,
        moderate: false,
        high: false,
        veryHigh: false
      }
    };
  }

  validateSelectOption = (key, event) => {
    const target = event.target;
    var errors = {...this.state.errors};
    if (target.value == '--') {
      errors[key] = true;
      this.setState({
        errors
      });
    } else {
      errors[key] = false;
      this.setState({
        errors
      });
    }
  };

  handleChange = (key, event) => {
    const target = event.target;
    this.props.onChange({ [key]: target.value });
  };

  render() {
    const { veryLow, low, moderate, high, veryHigh } = this.props;
    const { errors } = this.state;
    return (
      <fieldset>
        <legend>Selectable Options</legend>
        <SelectOptionField
          name={'veryLow'}
          label={window.riskEntitySettings.rating_very_low_label}
          value={veryLow}
          onChange={(e) => this.handleChange('very_low', e)}
          onBlur={(e) => this.validateSelectOption('veryLow', e)}
          errors={errors.veryLow}
        />
        <SelectOptionField
          name={'low'}
          label={window.riskEntitySettings.rating_low_label}
          value={low}
          onChange={(e) => this.handleChange('low', e)}
          onBlur={(e) => this.validateSelectOption('low', e)}
          errors={errors.low}
        />
        <SelectOptionField
          name={'moderate'}
          label={window.riskEntitySettings.rating_moderate_label}
          value={moderate}
          onChange={(e) => this.handleChange('moderate', e)}
          onBlur={(e) => this.validateSelectOption('moderate', e)}
          errors={errors.moderate}
        />
        <SelectOptionField
          name={'high'}
          label={window.riskEntitySettings.rating_high_label}
          value={high}
          onChange={(e) => this.handleChange('high', e)}
          onBlur={(e) => this.validateSelectOption('high', e)}
          errors={errors.high}
        />
        <SelectOptionField
          name={'veryHigh'}
          label={window.riskEntitySettings.rating_very_high_label}
          value={veryHigh}
          onChange={(e) => this.handleChange('very_high', e)}
          onBlur={(e) => this.validateSelectOption('veryHigh', e)}
          errors={errors.veryHigh}
        />
      </fieldset>
    );
  }
}

function SelectOptionField(props) {
  const { name, label, value, onChange, onBlur, errors } = props;
  return (
    <div className={errors ? 'form-group has-error' : 'form-group'}>
      <label className='control-label'>
        <abbr title='required'>*</abbr>
        {label}
      </label>
      <input
        name={name}
        className='form-control'
        type='text'
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
      {errors && <span className="help-block">{'"--"'} is not a valid option.</span>}
    </div>
  );
}

export default SelectOptionsFieldset;
