import React from 'react';
import Routes from 'global/routes';
import DataTable from 'global/components/DataTable';
import Toolbar from 'settings/components/Toolbar';
import DataInputSectionRow from 'settings/components/DataInputSectionRow';
import SearchButton from 'settings/components/SearchButton';
import ax from 'global/axios';
import _ from 'lodash';

class DataInputSectionsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedKeys: new Set(),
      sections: [],
      columnHeaders: [],
      loading: true,
      reordering: false,
      toolbarActions: [
        {
          onClick: this.handleNewSectionClick,
          iconClass: 'fa-plus',
          tooltip: 'Add New Section',
          visibility: {
            minSelections: 0
          }
        },
        {
          onClick: this.handleEditSectionClick,
          iconClass: 'fa-pencil',
          tooltip: 'Edit Selected Section',
          visibility: {
            minSelections: 1,
            maxSelections: 1
          }
        },
        {
          onClick: this.handleRemoveSectionClick,
          iconClass: 'fa-trash',
          tooltip: 'Remove Selected Section',
          visibility: {
            minSelections: 1,
            maxSelections: 1
          }
        },
        {
          onClick: this.handleShowSectionClick,
          iconClass: 'fa-eye',
          tooltip: 'Un-hide Selected Section(s)',
          visibility: {
            minSelections: 1
          }
        },
        {
          onClick: this.handleHideSectionClick,
          iconClass: 'fa-eye-slash',
          tooltip: 'Hide Selected Section(s)',
          visibility: {
            minSelections: 1
          }
        },
        {
          onClick: this.handleSaveReorderClick,
          iconClass: 'fa fa-save',
          text: 'Save Arrangement',
          visibility: {
            whenReordering: true
          }
        },
        {
          onClick: this.handleCancelReorderClick,
          iconClass: 'fa fa-ban',
          text: 'Cancel',
          visibility: {
            whenReordering: true
          }
        }
      ]
    };
  }

  componentDidMount() {
    this.getDataInputSections();
  }

  render() {
    const sections = this.state.sections;
    const loading = this.state.loading;
    const columnHeaders = this.state.columnHeaders;

    return (
      <div className='panel'>
        <div className='panel-heading'>
          <span className='panel-title'>Data Input Section Configuration</span>
          <div className='panel-heading-controls'>
            <SearchButton text={'Search Data Inputs'} searchPath={Routes.search_settings_overrides_data_input_sections_path()} />
          </div>
        </div>
        <Toolbar actions={this.state.toolbarActions} isActionVisible={this.toolbarActionVisible} loading={loading} />
        <DataTable
          records={sections}
          columnHeaders={columnHeaders}
          isLoading={loading}
          isCheckable={true}
          isDraggable={true}
          droppableId={'sections'}
          rowComponent={DataInputSectionRow}
          onSelectedRowsChange={this.handleSelectedRowsChange}
          onDragEnd={this.handleDragEnd}
        />
      </div>
    );
  }

  toolbarActionVisible = (key) => {
    const {
      selectedKeys,
      toolbarActions,
      reordering
    } = this.state;
    const visibilityParams = toolbarActions[key].visibility;

    if (!_.has(visibilityParams, 'whenReordering') && reordering) {
      // Short circuit for reordering is occurring.
      return false;
    }

    if (_.has(visibilityParams, 'minSelections') && _.has(visibilityParams, 'maxSelections')) {
      return (selectedKeys.size >= visibilityParams.minSelections) && (selectedKeys.size <= visibilityParams.maxSelections);
    } else if (_.has(visibilityParams, 'minSelections')) {
      return (selectedKeys.size >= visibilityParams.minSelections);
    } else if (_.has(visibilityParams, 'whenReordering')) {
      return reordering;
    }
  };

  handleSelectedRowsChange = (selectedKeys) => {
    const prevKeys = this.state.selectedKeys;
    if (!_.isEqual(prevKeys, selectedKeys)) {
      this.setState({ selectedKeys: new Set(selectedKeys) });
    }
  };

  handleNewSectionClick = async () => {
    window.location = Routes.new_settings_overrides_data_input_section_path();
  };

  handleEditSectionClick = async () => {
    const selectedKey = firstSetValue(this.state.selectedKeys);
    window.location = Routes.edit_settings_overrides_data_input_section_path(selectedKey);
  };

  handleRemoveSectionClick = async () => {
    return window.dialogs.confirm({
      title: 'Are you sure?',
      confirm: 'Are you sure you want to delete this section?',
      primaryButtonText: 'Delete Section',
      primaryButtonClass: 'btn-danger',
      hardEscape: true,
      onPrimaryClick: () => {
        this.deleteDataInputSection();
      },
      closeOnPrimary: true
    });
  };

  handleShowSectionClick = async () => {
    return await this.updateVisible(true);
  };

  handleHideSectionClick = async () => {
    return await this.updateVisible(false);
  };

  handleSaveReorderClick =  async () => {
    return await this.updateOrder();
  };

  handleCancelReorderClick = async () => {
    return await this.getDataInputSections();
  };

  handleDragEnd = (result) => {
    // Dropped outside the table
    if (!result.destination) {
      return;
    }

    const sections = reorder(
      this.state.sections,
      result.source.index,
      result.destination.index
    );

    this.setState({ sections: sections, reordering: true });
  };

  getDataInputSections = async () => {
    return ax({
      url: Routes.settings_overrides_data_input_sections_path(),
      method: 'get',
      headers: {
      }
    }).then((res) => {
      this.setState({ sections: res.data.dataInputSections, columnHeaders: res.data.columnHeaders, loading: false, reordering: false });
    }).catch(ax.handleError);
  };

  updateVisible = async (value) => {
    const keys = this.state.selectedKeys;
    return ax({
      url: Routes.update_visible_settings_overrides_data_input_sections_path(),
      method: 'patch',
      data: {
        data_input_section_ids: keys,
        visible: value
      }
    }).then((res) => {
      var sections = [...this.state.sections];
      res.data.dataInputSections.map(r => sections.splice(_.findIndex(sections, { id: r.id }), 1, r));

      this.setState({ sections: sections, reordering: false });
    }).catch(ax.handleError);
  };

  updateOrder = async () => {
    const orderedKeys = this.state.sections.map(s => s.id);
    return ax({
      url: Routes.update_order_settings_overrides_data_input_sections_path(),
      method: 'patch',
      data: {
        data_input_section_ids: orderedKeys
      }
    }).then(() => {
      return this.getDataInputSections();
    }).catch(ax.handleError);
  };

  deleteDataInputSection = () => {
    const selectedKey = firstSetValue(this.state.selectedKeys);
    return ax({
      url: Routes.settings_overrides_data_input_section_path(selectedKey),
      method: 'delete'
    }).then(() => {
      this.getDataInputSections();
    }).catch(ax.handleError);
  };
}

const firstSetValue = (set) => {
  const firstIter = set.values().next();
  return firstIter.value;
};

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export default DataInputSectionsContainer;
