import React from 'react';

function Input(props) {
  const { symbol, name, value, onChange, disabled } = props;
  return (
    <td>
      <div className='input-group'>
        <span className='input-group-addon limit-sign'>{symbol}</span>
        <input className='form-control' style={{zIndex: 0}} type="text" disabled={disabled ? 'disabled' : ''} name={name} value={value} onChange={onChange} aria-label='input' />
      </div>
    </td>
  );
}

export default Input;
