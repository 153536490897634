export const SacredParams = (props) => {
  let name = '';
  if (props.name) {
    name = props.name.trim();
  } else {
    name = null;
  }

  return {
    self_assessment_consideration_risk_entity_definition: {
      risk_attribute_risk_entity_definition_id: props.raredID,
      name: name,
    }
  };
};
