import React from 'react';
import PropTypes from 'prop-types';

class SearchButton extends React.Component {
  render() {
    return (
      <div>
        <button className='btn-sm btn-primary' onClick={this.handleSearchClick}>
          <i className='fa fa-search'></i> {this.props.text}
        </button>
        &nbsp;{this.renderClearSearch()}
      </div>
    );
  }

  renderClearSearch = () => {
    if (this.props.clearSearchPath) {
      return(
        <button className='btn-sm' onClick={this.handleClearClick}>
          <i className='fa fa-ban'></i> Clear Search
        </button>
      );
    }
  };

  handleSearchClick = (e) => {
    e.preventDefault();
    const initialSearchTerm = this.props.searchTerm || '';
    let url = this.props.searchPath;
    window.dialogs.prompt({
      title: 'Search Data Input Name',
      value: initialSearchTerm,
      primaryButtonText: 'Search',
      onPrimaryClick: function(e, value, _modal){
        window.location = url + '?q=' + encodeURIComponent(value);
      }
    });
  };

  handleClearClick = (e) => {
    e.preventDefault();
    window.location = this.props.clearSearchPath;
  };
}

SearchButton.propTypes = {
  text: PropTypes.string.isRequired,
  searchPath: PropTypes.string.isRequired,
  clearSearchPath: PropTypes.string,
  searchTerm: PropTypes.string
};

export default SearchButton;
