import React from 'react';
import PropTypes from 'prop-types';
import styles from 'global/styles/DataTable';
import CheckedDisplay from 'settings/components/CheckedDisplay';

class DataInputSectionRow extends React.Component {
  render() {
    const record = this.props.record;
    return (
      <tr
        ref={this.props.provided.innerRef}
        className={styles.draggable}
        {...this.props.provided.draggableProps}
      >
        <td
          className={styles.controlColumn}
          {...this.props.provided.dragHandleProps}
        >
          <span className='grip-handle'></span>
        </td>
        <td className={styles.controlColumn}><input id={`dataInputSection-${record.id}`} className='boolean' aria-label='checkbox' type='checkbox' checked={this.props.selected} onChange={this.handleCheckedChange} /></td>
        <td><a href={record.href}>{record.name}</a></td>
        <td className={[styles.column15].join(' ')}><CheckedDisplay checked={record.visible} /></td>
        <td className={[styles.column15].join(' ')}><CheckedDisplay checked={record.system_default} /></td>
        <td className={[styles.column15].join(' ')}><strong>{record.dataInputsCount}</strong> ({record.dataInputsVisibleCount} visible)</td>
      </tr>
    );
  }

  handleCheckedChange = (e) => {
    this.props.onSelectedChange(this.props.record.id, e.target.checked);
  };
}

DataInputSectionRow.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    href: PropTypes.string,
    visible: PropTypes.bool,
    dataInputsCount: PropTypes.number,
    dataInputsVisibleCount: PropTypes.number
  }),
  selected: PropTypes.bool.isRequired,
  onSelectedChange: PropTypes.func.isRequired
};

export default DataInputSectionRow;
