import React from 'react';
import PropTypes from 'prop-types';
import styles from 'global/styles/DataTable';

class CategoryRiskRow extends React.Component {
  render() {
    const record = this.props.record;
    return (
      <tr
        ref={this.props.provided.innerRef}
        className={styles.draggable}
        {...this.props.provided.draggableProps}
      >
        <td
          className={styles.controlColumn}
          {...this.props.provided.dragHandleProps}
        >
          <span className='grip-handle'></span>
        </td>
        <td><a href={record.href}>{record.name}</a></td>
        <td className={styles.leftBorder}>
          {record.weight}
        </td>
      </tr>
    );
  }
}

CategoryRiskRow.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    weight: PropTypes.string,
  })
};

export default CategoryRiskRow;
