import _ from 'lodash';
import React from 'react';
import Button from 'global/components/Button';
import QuestionInfoTooltip from 'global/components/QuestionInfoTooltip';
import MonthlySwitch from 'benchmarks/components/MonthlySwitch';
import QuarterlySwitch from 'benchmarks/components/QuarterlySwitch';
import {RaidParams} from 'administration/components/RaidParams';
import CeilingForm from 'benchmarks/components/CeilingForm';
import FloorForm from 'benchmarks/components/FloorForm';
import SelectDisplay from 'benchmarks/components/SelectDisplay';
import AbsoluteValueForm from 'benchmarks/components/AbsoluteValueForm';
import BidirectionalForm from 'benchmarks/components/BidirectionalForm';
import ValidationMessage from 'benchmarks/components/ValidationMessage';
import {ifSuccessful} from 'global/promise';
import ax from 'global/axios';

class RiskAttributeIndicatorDefinitionEditForm extends React.Component {

  constructor(props) {
    super(props);

    const element = document.querySelector('#react-root');

    this.state = {
      submitting: false,
      raidID: element.dataset.raidId,
      previousUrl: element.dataset.previousUrl,
      validation: {
        name: '',
        monthlyBenchmarks: '',
        quarterlyBenchmarks: ''
      },
      enabledMonthly: false,
      enabledQuarterly: false,
      canPopulateRiskEntities: false
    };
  }

  async componentDidMount() {
    const raid = await this.getRaid();
    this.setState({
      cad: raid.risk_category_definition,
      rad: raid.risk_attribute_definition,
      did: raid.data_input_definition,
      name: raid.name,
      definition: raid.definition,
      description: raid.description,
      calcType: raid.calcType,
      limitType: raid.limitType,
      monthlyBenchmarks: raid.monthly_benchmarks,
      quarterlyBenchmarks: raid.quarterly_benchmarks,
      enabledMonthly: raid.enabledMonthly,
      enabledQuarterly: raid.enabledQuarterly,
      canPopulateRiskEntities: raid.canPopulateRiskEntities
    });

    document.addEventListener('keydown', this.onEnterPress);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onEnterPress);
  }

  getRaid = () => {
    return ax({
      url: `/administration/risk_attribute_indicator_definitions/${this.state.raidID}/edit?previous_url=${window.location.href}`,
      method: 'get'
    }).then((res) => {
      return res.data;
    }).catch(error => {
      ax.handleError(error);
    });
  };

  validate = () => {
    let data = RaidParams(this.state);
    data.id = this.state.raidID;
    data.enabled_quarterly = true; // Ensure quarterly benchmarks are validated
    return ax({
      url: `/administration/risk_attribute_indicator_definitions/${this.state.raidID}/validate_and_format`,
      method: 'post',
      data: data
    }).then((res) => {
      const {messages} = res.data.errors;
      this.setState({
        validation: {
          name: _.get(messages, 'name[0]', ''),
          quarterlyBenchmarks: _.get(messages, 'quarterly_benchmarks[0]', ''),
          monthlyBenchmarks: _.get(messages, 'monthly_benchmarks[0]', '')
        }
      });
      return _.isEmpty(messages);
    });
  };

  submit = () => {
    this.setState({submitting: true});
    return ax({
      url: `/administration/risk_attribute_indicator_definitions/${this.state.raidID}`,
      method: 'patch',
      data: RaidParams(this.state)
    });
  };

  populateRiskEntities = () => {
    this.setState({submitting: true});
    return ax({
      url: `/administration/risk_attribute_indicator_definitions/${this.state.raidID}/populate_risk_entities`,
      method: 'post'
    })
      .then(ifSuccessful(this.redirect))
      .catch(ax.handleError);
  };

  redirect = () => {
    window.location.href = this.indicatorsUrl();
  };

  handleFormSubmit = () => {
    return this.validate()
      .then(ifSuccessful(this.submit))
      .then(ifSuccessful(this.redirect))
      .catch(ax.handleError);
  };

  handleMonthlyBenchmarksChange = (benchmarks) => {
    this.setState({monthlyBenchmarks: benchmarks});
  };

  handleQuarterlyBenchmarksChange = (benchmarks) => {
    this.setState({quarterlyBenchmarks: benchmarks});
  };

  indicatorsUrl() {
    if (this.state.previousUrl) {
      return this.state.previousUrl;
    } else {
      return `/administration/risk_attribute_indicator_definitions?risk_category_definition_id=${this.state.cad.id}&risk_attribute_definition_id=${this.state.rad.id}`;
    }
  }

  cancelButtonVisible() {
    return !this.state.submitting;
  }

  handleChange = (event) => {
    const target = event.target;
    this.setState({[target.name]: target.value});
  };

  handleCancelClick = (e) => {
    e.preventDefault();
    window.location.href = this.indicatorsUrl();
  };

  onEnterPress = (e) => {
    if (e.keyCode == 13 && e.shiftKey == false) {
      e.preventDefault();
      this.handleFormSubmit();
    }
  };

  render() {
    const {
      rad,
      did,
      name,
      definition,
      description,
      calcType,
      limitType,
      monthlyBenchmarks,
      quarterlyBenchmarks,
      enabledMonthly,
      enabledQuarterly,
      canPopulateRiskEntities,
      submitting,
      validation
    } = this.state;

    if (rad) {
      return (
        <div className='row'>
          <form className='simple_form'>
            <div className="col-md-12">
              <div className="panel">
                <div className="panel-heading">
                  <span className="panel-title">Edit Global Indicator</span>
                  {canPopulateRiskEntities &&
                    <span className="pull-right">
                      <Button
                        onClick={this.populateRiskEntities}
                        isDisabled={submitting}
                        isAsync={true}
                        label={'Populate Risk Entities'}
                      />
                    </span>}
                </div>
                <div className="panel-body">
                  <div className='row'>
                    <div className={'col-md-12'}>
                      <div className={validation.name ? 'form-group has-error' : 'form-group'}>
                        <abbr title='required'>*</abbr>
                        <label className='control-label string'>Name</label>
                        <input
                          className={validation.name ? 'error form-control required' : 'form-control required'}
                          type="text"
                          name='name'
                          value={name}
                          onChange={this.handleChange}
                          autoFocus={true}
                        />
                        {validation.name &&
                          <span className='help-block error-block'>{validation.name}</span>}
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className={'col-md-12'}>
                      <div className='form-group'>
                        <label className='control-label'>Description</label>
                        <input className='form-control' type="text" name='description' value={description} onChange={this.handleChange} />
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className={'col-md-12'}>
                      <div className='form-group'>
                        <label className='control-label'>Definition</label>
                        <input className='form-control' type="text" name='definition' value={definition} onChange={this.handleChange} />
                      </div>
                    </div>
                  </div>
                  <div className='form-group'>
                    <label className='control-label'>
                      Risk Attribute Definition
                      <QuestionInfoTooltip
                        content="The risk attribute definition can not be changed for an existing indicator definition."
                        html={false}
                        placement='bottom'
                        trigger='hover'
                      />
                    </label>
                    <input className='form-control' type="text" name='rared' value={rad.keyName} disabled />
                  </div>
                  <div className='form-group'>
                    <label className="select control-label">
                      Data Input Definition
                      <QuestionInfoTooltip
                        content="The data input definition can not be changed for an existing indicator definition."
                        html={false}
                        placement='bottom'
                        trigger='hover'
                      />
                    </label>
                    <input className='form-control' type="text" name='dired' value={did.keyName} disabled />
                  </div>
                  <div className='select'>
                    <label className='control-label'>
                      Calculation Type
                      <QuestionInfoTooltip
                        content="Calculation Type changes how indicator values are converted into a rating."
                        html={false}
                        placement='bottom'
                        trigger='hover'
                      />
                    </label>
                    <input className='form-control' type="text" name='calcType' value={calcType} disabled />
                    <br/><br/>
                  </div>
                  <div id='quarterly-benchmarks'>
                    <QuarterlySwitch value={enabledQuarterly} onChange={this.handleChange} />
                    { (enabledQuarterly) &&
                      <>
                        <ValidationMessage>{validation.quarterlyBenchmarks}</ValidationMessage>
                        {limitType == 'select' &&
                          <>
                            <div className="alert-message info-alert-message">
                              <p>The list of selectable options for this indicator definition: <a href={did.href}>{did.keyName}</a>.</p>
                            </div>
                            <SelectDisplay selectOptions={did.selectOptions} />
                          </>
                        }
                        {limitType == 'ceiling' &&
                          <CeilingForm benchmarks={quarterlyBenchmarks} onChange={this.handleQuarterlyBenchmarksChange} />
                        }
                        {limitType == 'floor' &&
                          <FloorForm benchmarks={quarterlyBenchmarks} onChange={this.handleQuarterlyBenchmarksChange} />
                        }
                        {limitType == 'absolute_value' &&
                          <AbsoluteValueForm benchmarks={quarterlyBenchmarks} onChange={this.handleQuarterlyBenchmarksChange} />
                        }
                        {limitType == 'bidirectional' &&
                          <BidirectionalForm benchmarks={quarterlyBenchmarks} onChange={this.handleQuarterlyBenchmarksChange} />
                        }
                      </>
                    }
                  </div>
                  <div id='monthly-benchmarks'>
                    <MonthlySwitch value={enabledMonthly} onChange={this.handleChange} />
                    { enabledMonthly &&
                      <>
                        <ValidationMessage>{validation.monthlyBenchmarks}</ValidationMessage>
                        {limitType == 'select' &&
                          <>
                            <div className="alert-message info-alert-message">
                              <p>The list of selectable options for this indicator definition: <a href={did.href}>{did.keyName}</a>.</p>
                            </div>
                            <SelectDisplay selectOptions={did.selectOptions} />
                          </>
                        }
                        {limitType == 'ceiling' &&
                          <CeilingForm benchmarks={monthlyBenchmarks} onChange={this.handleMonthlyBenchmarksChange} />
                        }
                        {limitType == 'floor' &&
                          <FloorForm benchmarks={monthlyBenchmarks} onChange={this.handleMonthlyBenchmarksChange} />
                        }
                        {limitType == 'absolute_value' &&
                          <AbsoluteValueForm benchmarks={monthlyBenchmarks} onChange={this.handleMonthlyBenchmarksChange} />
                        }
                        {limitType == 'bidirectional' &&
                          <BidirectionalForm benchmarks={monthlyBenchmarks} onChange={this.handleMonthlyBenchmarksChange} />
                        }
                      </>
                    }
                  </div>
                  <Button
                    onClick={this.handleFormSubmit}
                    isDisabled={submitting}
                    isAsync={true}
                    label={'Update Indicator Definition'}
                  />
                  {this.cancelButtonVisible() &&
                    <a href={this.indicatorsUrl()} className='btn btn-link cancel-link' onClick={this.handleCancelClick}>Cancel</a>}
                </div>
              </div>
            </div>
          </form>
        </div>
      );
    } else {
      return (<div><p>Loading...</p></div>);
    }
  }
}

export default RiskAttributeIndicatorDefinitionEditForm;
