import React from 'react';
import styles from 'global/styles/TimePeriodPicker';
import monthNames from 'reporting/section/monthNames';
import ax from 'global/axios';
import _ from 'lodash';

function InputGroup(props) {
  const {children, error, loading, onClick} = props;
  const iconClass = error ? 'fa fa-calendar-times-o' : (loading ? 'fa fa-spinner fa-pulse fa-fw' : 'fa fa-calendar');
  const inputGroupClasses = ['input-group', styles.inputGroup].join(' ');

  return (
    <div className='datepicker'>
      <div className={inputGroupClasses}>
        <div className='form-control' onClick={onClick}>
          {children}
        </div>
        <div className='input-group-btn'>
          <button type='button' className='btn btn-default' onClick={onClick}>
            <span className={iconClass}></span>
            <span className="sr-only">Toggle Calendar</span>
          </button>
        </div>
      </div>
    </div>
  );
}

function Chevron(props) {
  const classes = `fa fa-chevron-${props.direction} ${styles.chevron}`;
  return <i className={classes} aria-hidden="true"></i>;
}

function YearPicker(props) {
  const {min, max, value, onChange} = props;
  const leftEnabled = value > min;
  const leftLinkProps = {
    className: leftEnabled ? null : styles.disabled,
    onClick: leftEnabled ? _.partial(onChange, value - 1) : null
  };
  const rightEnabled = value < max;
  const rightLinkProps = {
    className: rightEnabled ? null : styles.disabled,
    onClick: rightEnabled ? _.partial(onChange, value + 1) : null
  };

  return (
    <header>
      <a {...leftLinkProps}>
        <Chevron direction="left" />
      </a>
      {value}
      <a {...rightLinkProps}>
        <Chevron direction="right" />
      </a>
    </header>
  );
}

function Month(props) {
  const {name, number, disabled, selected, onClick} = props;
  const liProps = {
    className: disabled ? styles.disabled : (selected ? styles.selected : null),
    onClick: !(disabled || selected) ? _.partial(onClick, number) : null
  };

  return (
    <li {...liProps}>
      <label>
        {name}
      </label>
    </li>
  );
}

class MonthPicker extends React.Component {
  _isMounted = false;

  constructor(props) {
    const date = new Date();
    super(props);
    this.state = {
      open: false,
      selectedMonth: undefined,
      selectedYear: undefined,
      visibleYear: date.getFullYear(),
      months: [],
      loading: true,
      error: false
    };
    this.ref = React.createRef();
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadMonths();
    document.addEventListener('mousedown', this.handleDocumentMouseDown, false);
  }

  componentWillUnmount() {
    this._isMounted = false;
    document.removeEventListener('mousedown', this.handleDocumentMouseDown, false);
  }

  handleDocumentMouseDown = (e) => {
    const node = this.ref.current;
    const inNode = node && node.contains(e.target);
    if (!inNode) {
      this.close();
    }
  };

  inputGroupText = () => {
    const {open, selectedYear, selectedMonth} = this.state;
    if (!open && selectedYear && selectedMonth) {
      const monthName = monthNames[selectedMonth - 1];
      return `${monthName} ${selectedYear}`;
    }
    return 'Select Month & Year';
  };

  render() {
    const years = this.state.months.map( x => x.year );

    return (
      <span ref={this.ref}>
        <InputGroup error={this.state.error} loading={this.state.loading} onClick={this.toggleOpen}>
          {this.inputGroupText()}
        </InputGroup>
        { this.state.open &&
          <nav className={styles.nav}>
            <YearPicker
              value={this.state.visibleYear}
              min={_.min(years)}
              max={_.max(years)}
              onChange={this.changeVisibleYear}
            />
            <ol className={styles.months}>
              { _.map(monthNames, ((monthName, index) => {
                const monthNumber = index + 1;
                return (
                  <Month
                    key={monthName}
                    name={monthName}
                    number={monthNumber}
                    disabled={!this.isEnabled(monthNumber)}
                    selected={this.isSelected(monthNumber)}
                    onClick={this.selectMonth}
                  />
                );
              })) }
            </ol>
            <footer>
            </footer>
          </nav>
        }
      </span>
    );
  }

  toggleOpen = () => {
    const {open} = this.state;
    this.setState({open: !open});
  };

  close = () => {
    const {selectedYear} = this.state;
    this.setState({
      visibleYear: selectedYear,
      open: false
    });
  };

  changeVisibleYear = (year) => {
    this.setState({visibleYear: year});
  };

  selectMonth = (monthNumber) => {
    const {visibleYear} = this.state;
    this.setState({
      error: false,
      loading: true,
      selectedYear: visibleYear,
      selectedMonth: monthNumber
    });

    this.props.onChange(visibleYear, monthNumber);
  };

  loadMonths() {
    this.setState({
      loading: true,
      error: false
    });

    ax({
      url: '/time_periods/months.json',
      method: 'get'
    }).then((res) => {
      const {months, selected_data_input_month} = res.data;

      if (this._isMounted) {
        this.setState({
          error: false,
          loading: false,
          months: months.filter(this.props.filter)
        });
      }

      if (selected_data_input_month && this._isMounted) {
        this.setState({
          visibleYear: selected_data_input_month.year,
          selectedYear: selected_data_input_month.year,
          selectedMonth: selected_data_input_month.month
        });
      }
    }).catch(() => {
      if (this._isMounted) {
        this.setState({
          error: true,
          loading: false,
          months: []
        });
      }
    });
  }

  isEnabled(monthNumber) {
    return _.some(this.state.months, (time_period) => {
      return time_period.year == this.state.visibleYear && time_period.month == monthNumber;
    });
  }

  isSelected(monthNumber) {
    return this.state.visibleYear == this.state.selectedYear && this.state.selectedMonth == monthNumber;
  }
}

export default MonthPicker;
