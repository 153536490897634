/*eslint no-unused-vars: ["error", { "varsIgnorePattern": "React" }]*/
import React from 'react';
import TimePeriodRangePicker from 'reporting/section/TimePeriodRangePicker';

class KRITrendReport extends React.Component {
  render() {
    const {
      start_time_period,
      end_time_period,
      onChange
    } = this.props;

    return (
      <div className='row'>
        <div className='col-md-4'>
          <h4>KRI Trend Report</h4>
        </div>
        <div className='col-lg-4 col-md-5 hidden-sm hidden-xs'>
          <TimePeriodRangePicker
            start_time_period={start_time_period}
            end_time_period={end_time_period}
            onChange={onChange}
            showQuarters
            showMonths={window.riskEntitySettings.monthly_kri}
          />
        </div>
      </div>
    );
  }
}

export default KRITrendReport;
