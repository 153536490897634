import _ from 'lodash';
import React from 'react';
import ax from 'global/axios';
import Routes from 'global/routes';
import Select from 'react-select';
import SelectOptionsFieldset from './SelectOptionsFieldset';
import Modal from 'react-bootstrap/Modal';

const ValueFormats = [
  {value: 0, label: 'Percent'},
  {value: 1, label: 'Whole Number'},
  {value: 2, label: 'Currency'},
  {value: 3, label: 'Decimal'},
  {value: 4, label: 'Year'},
  {value: 5, label: 'Select'},
  {value: 6, label: 'Currency w/ Decimal'}
];

class CreateDataInputRiskEntityDefinitionForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataInputSection: props.dataInputSection,
      dataInputDefinition: props.dataInputDefinition,
      dataInputSections: [],
      dataInputSectionMessage: '',
      dataInputValueFormat: props.dataInputValueFormat,
      dataInputValueFormatMessage: '',
      dataInputDecimalPlaces: props.dataInputDecimalPlaces,
      dataInputDecimalPlacesTouched: false,
      modalBodyClass: this.getModalBodyClass(props.dataInputValueFormat)
    };
  }

  componentDidMount() {
    return ax({
      url: Routes.settings_overrides_data_input_sections_path(),
      method: 'get',
    }).then((res) => {
      const dataInputSections = res.data.dataInputSections.map((section) => ({ value: section.id, label: section.name }));
      this.setState({dataInputSections});
    }, this.props.onError);
  }

  notify = () => {
    const {onChange} = this.props;
    const filteredState = _.pick(this.state, ['dataInputDefinition', 'dataInputSection', 'dataInputValueFormat', 'dataInputDecimalPlaces']);
    onChange(filteredState);
  };

  handleDefinitionChange = (event) => {
    this.setState({dataInputDefinition: event.target.value});
  };

  handleDataInputSectionChange = (dataInputSection) => {
    this.setState({
      dataInputSection: dataInputSection,
      dataInputSectionMessage: dataInputSection ? '' : 'Must be assigned to a Data Input Section.'
    }, this.notify);
  };

  handleValueFormatChange = (dataInputValueFormat) => {
    var selectOptions = null;
    if (_.get(dataInputValueFormat, 'label') === 'Select') {
      selectOptions = {
        very_low: '',
        low: '',
        moderate: '',
        high: '',
        very_high: ''
      };
    }

    this.setState({
      dataInputValueFormat: dataInputValueFormat,
      dataInputValueFormatMessage: dataInputValueFormat ? '' : 'Must select a Value Format.',
      modalBodyClass: this.getModalBodyClass(dataInputValueFormat)
    }, this.notify);
    this.props.onChange({ dataInputSelectOptions: selectOptions });
  };

  getModalBodyClass = (valueFormat) => {
    if (_.get(valueFormat, 'label') === 'Select') {
      return '';
    } else {
      return 'overflow-visible';
    }
  };

  handleDecimalPlacesChange = (e) => {
    this.setState({ dataInputDecimalPlaces: e.target.value }, this.notify);
  };

  handleDecimalPlacesBlur = () => {
    this.setState({ dataInputDecimalPlacesTouched: true });
  };

  decimalPlacesValid = (dataInputDecimalPlaces) => {
    if (dataInputDecimalPlaces === '' || !Number.isInteger(parseInt(dataInputDecimalPlaces)) || dataInputDecimalPlaces < 0) {
      return false;
    } else {
      return true;
    }
  };

  handleSelectOptionsChange = (selectOption) => {
    var { dataInputSelectOptions } = this.props;
    this.props.onChange({
      dataInputSelectOptions: _.assign(dataInputSelectOptions, selectOption)
    });
  };

  isDecimalPlacesFieldVisible = () => {
    const { dataInputValueFormat } = this.props;
    const decimalFormats = ['Percent', 'Decimal', 'Currency w/ Decimal'];
    const valueFormatLabel = _.get(dataInputValueFormat, 'label');

    return decimalFormats.includes(valueFormatLabel);
  };

  isSelectOptionsFieldsetVisible = () => {
    const { dataInputValueFormat } = this.props;
    const valueFormatLabel = _.get(dataInputValueFormat, 'label');

    return valueFormatLabel === 'Select';
  };

  nextButtonDisabled() {
    if (_.isUndefined(_.get(this.state, 'dataInputSection.value'))) {
      return true;
    }
    if (_.isUndefined(_.get(this.state, 'dataInputValueFormat.value'))) {
      return true;
    }

    if (this.isSelectOptionsFieldsetVisible()) {
      const {dataInputSelectOptions} = this.props;
      const isBlank = (x) => !_.trim(x);
      const isDashes = (x) => x == '--';
      const requiredFields = _.pick(dataInputSelectOptions, ['very_low', 'low', 'moderate', 'high', 'very_high']);
      if (_.some(requiredFields, isBlank)) {
        return true;
      }
      if (_.some(requiredFields, isDashes)) {
        return true;
      }
    }

    return false;
  }

  render() {
    const {
      onModeChange,
      dataInputSelectOptions,
      canEditObject,
      onBack,
      onNext
    } = this.props;
    const {
      dataInputSection,
      dataInputSections,
      dataInputSectionMessage,
      dataInputDefinition,
      dataInputValueFormat,
      dataInputValueFormatMessage,
      dataInputDecimalPlaces,
      dataInputDecimalPlacesTouched,
      modalBodyClass
    } = this.state;

    const decimalPlacesInvalid = dataInputDecimalPlacesTouched && !this.decimalPlacesValid(dataInputDecimalPlaces) && this.isDecimalPlacesFieldVisible();

    return (
      <>
        <Modal.Body className={modalBodyClass}>
          <div>
            <a href='#' onClick={() => onModeChange('select-existing')}>Select an existing Data Input</a>
            <br/>
            {canEditObject &&
              <div>
                <a href='#' onClick={() => onModeChange('create-from-object')}>Create from Risk Object</a>
                <br/>
              </div>
            }
            <br/>
          </div>
          <div>
            <fieldset>
              <legend>Create New Data Input</legend>
              <div className={dataInputSectionMessage ? 'has-error form-group select' : 'form-group select'}>
                <label className="select control-label">
                  <abbr title='required'>*</abbr>
                  Data Input Section
                </label>
                <Select
                  id='sections'
                  classNamePrefix='section'
                  type='select'
                  value={dataInputSection}
                  onChange={this.handleDataInputSectionChange}
                  options={dataInputSections}
                  isClearable={false}
                  styles={{ menuList: (provided, _state) => ({ ...provided, maxHeight: 200 }) }}
                />
                {dataInputSectionMessage &&
                  <span className='help-block error-block'>{dataInputSectionMessage}</span>}
              </div>
              <div className='form-group'>
                <label className='control-label'>Definition</label>
                <input className='form-control' type="text" id='dataInputDefinition' name='dataInputDefinition' value={dataInputDefinition} onChange={this.handleDefinitionChange} />
              </div>
              <div className={dataInputValueFormatMessage ? 'has-error form-group select' : 'form-group select'}>
                <label className='control-label'>
                  <abbr title='required'>*</abbr>
                  Value Format
                </label>
                <Select
                  id={'valueFormats'}
                  classNamePrefix='valueFormat'
                  value={dataInputValueFormat}
                  onChange={this.handleValueFormatChange}
                  options={ValueFormats}
                  isClearable={false}
                  styles={{ menuList: (provided, _state) => ({ ...provided, maxHeight: 200 }) }}
                />
                {dataInputValueFormatMessage &&
                  <span className='help-block error-block'>{dataInputValueFormatMessage}</span>}
              </div>
              {this.isDecimalPlacesFieldVisible() &&
                <div className={decimalPlacesInvalid ? 'has-error form-group' : 'form-group'}>
                  <label className='control-label'>Decimal Places</label>
                  <input
                    className='form-control'
                    onBlur={this.handleDecimalPlacesBlur}
                    type='text'
                    value={dataInputDecimalPlaces}
                    onChange={this.handleDecimalPlacesChange}
                  />
                  {decimalPlacesInvalid &&
                    <span className='help-block error-block'>Must be greater than or equal to 0.</span>}
                </div>}
              {this.isSelectOptionsFieldsetVisible() &&
                <SelectOptionsFieldset
                  veryLow={dataInputSelectOptions && dataInputSelectOptions['very_low'] || ''}
                  low={dataInputSelectOptions && dataInputSelectOptions['low'] || ''}
                  moderate={dataInputSelectOptions && dataInputSelectOptions['moderate'] || ''}
                  high={dataInputSelectOptions && dataInputSelectOptions['high'] || ''}
                  veryHigh={dataInputSelectOptions && dataInputSelectOptions['very_high'] || ''}
                  onChange={this.handleSelectOptionsChange}
                />}
            </fieldset>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary pull-left" type="button" onClick={onBack}>
            Back
          </button>
          <button
            className="btn btn-primary pull-right"
            type="button" disabled={decimalPlacesInvalid || this.nextButtonDisabled()} onClick={onNext}>
          Next
          </button>
        </Modal.Footer>
      </>
    );
  }
}

export default CreateDataInputRiskEntityDefinitionForm;
