import React from 'react';
import _ from 'lodash';

function GlyphiconButton(props) {
  const {icon, text, alt, tooltip, disabled, onClick, onMouseDown} = props;
  const tooltipProps = {
    'className': 'btn-group',
    'data-toggle': 'tooltip',
    'data-container': 'body',
    'title': tooltip
  };
  let buttonStyle = _.get(props, 'style', {});
  if (disabled) {
    buttonStyle.pointerEvents ='none';
  }
  const buttonProps = {
    className: 'btn btn-outline',
    onClick: onClick,
    onMouseDown: onMouseDown,
    disabled: disabled,
    style: buttonStyle
  };

  const iconProps = {
    className: ['glyphicon', icon].join(' '),
    style: {
      pointerEvents: 'none'
    }
  };

  return (
    <div {...tooltipProps}>
      <button {...buttonProps}>
        <span {...iconProps}></span>
        { text &&
          <>&nbsp;{text}</>
        }
        { alt &&
          <span className='sr-only'>{alt}</span>
        }
      </button>
    </div>
  );
}

export default GlyphiconButton;
