import React from 'react';
import ReactDOM from 'react-dom';

class Breadcrumbs extends React.Component {
  constructor(props) {
    super(props);
    this.el = document.getElementsByClassName('breadcrumb')[0];
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.el);
  }
}

export default Breadcrumbs;
