/* global riskAttributesContainerParams */
import React from 'react';
import RiskAttributesFormContainer from 'settings/components/RiskAttributesFormContainer';

class RiskAttributesContainer extends React.Component {
  render() {
    const {
      attributeOverrideId,
      riskAttributeDomainDefinitionId,
      riskAttributeDomainInherentComponentType,
      forecastingPresent,
      inherentLabel,
      qualityLabel,
      forecastingLabel
    } = riskAttributesContainerParams;

    if (riskAttributeDomainDefinitionId) {
      if (riskAttributeDomainInherentComponentType){
        return([
          <RiskAttributesFormContainer
            key='inherent'
            title={inherentLabel}
            componentType={0}
            forecasting={false}
            cybersecurity={true}
            attributeOverrideId={attributeOverrideId}
            riskAttributeDomainDefinitionId={riskAttributeDomainDefinitionId}
          />
        ]);
      } else {
        return([
          <RiskAttributesFormContainer
            key='quality'
            title={qualityLabel}
            componentType={1}
            forecasting={false}
            cybersecurity={true}
            attributeOverrideId={attributeOverrideId}
            riskAttributeDomainDefinitionId={riskAttributeDomainDefinitionId}
          />,
        ]);
      }
    }

    let containers = [
      <RiskAttributesFormContainer
        key='inherent'
        title={inherentLabel}
        componentType={0}
        forecasting={false}
        cybersecurity={false}
        attributeOverrideId={attributeOverrideId}
        riskAttributeDomainDefinitionId={riskAttributeDomainDefinitionId}
      />,
      <RiskAttributesFormContainer
        key='quality'
        title={qualityLabel}
        componentType={1}
        forecasting={false}
        cybersecurity={false}
        attributeOverrideId={attributeOverrideId}
        riskAttributeDomainDefinitionId={riskAttributeDomainDefinitionId}
      />,
    ];

    if (forecastingPresent) {
      containers.push(
        <RiskAttributesFormContainer
          key='forecasting'
          title={forecastingLabel}
          componentType={2}
          forecasting={true}
          cybersecurity={false}
          attributeOverrideId={attributeOverrideId}
          riskAttributeDomainDefinitionId={riskAttributeDomainDefinitionId}
        />
      );
    }

    return(containers);
  }
}

export default RiskAttributesContainer;
