import React from 'react';
import Select from 'react-select';
import _ from 'lodash';

class MultiSelectWithLoader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
      value: [],
      isLoading: false
    };
  }

  componentDidMount() {
    this.setState({isLoading: true});
    this.props.loader()
      .then( (options) => {
        this.setState({options: options, isLoading: false});
      }, () => this.setState({isLoading: false}));
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.value != this.props.value || prevState.options != this.state.options) {
      const value = _.filter(this.state.options, (option) => _.includes(this.props.value, option.value));
      this.setState({value});
    }
  }

  handleChange = (options) => {
    const value = _.map(options, _.property('value'));
    this.props.onChange({[this.props.name]: value});
  };

  render() {
    return (
      <Select
        isClearable={true}
        isSearchable={true}
        isMulti={true}
        placeholder=''
        options={this.state.options}
        value={this.state.value}
        isLoading={this.state.isLoading}
        filterOption={this.props.filterOption}
        onChange={this.handleChange}
        styles={this.props.styles}
      />
    );
  }
}
export default MultiSelectWithLoader;
