import React from 'react';
import PropTypes from 'prop-types';

class Button extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = { loading: false };
  }

  render() {
    const { loading } = this.state;
    const buttonClasses = `btn ${this.props.buttonClass || 'btn-primary'}`;
    const label = this.props.label;
    const isDisabled = this.props.isDisabled || loading;
    const type = this.props.type || 'button';
    return (
      <button type={type} className={buttonClasses} onClick={this.handleClick} disabled={isDisabled} data-turbolinks='false'>
        {this.renderIcon(loading)}{label}
      </button>
    );
  }

  renderIcon = (loading) => {
    const iconClass = this.props.iconClass;

    if (loading) {
      return (
        <span><i className='fa fa-spinner fa-pulse'></i>&nbsp;</span>
      );
    } else if (iconClass) {
      return (
        <span><i className={`fa ${iconClass}`}></i>&nbsp;</span>
      );
    }
  };

  handleClick = (e) => {
    e.preventDefault();
    const { loading } = this.state;
    const isAsync = this.props.isAsync;

    if (!loading) {
      this.setState({ loading: true });

      this.props.onClick().then(() => {
        if (this._isMounted && isAsync) {
          this.setState({ loading: false });
        }
      });
    }
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
}

Button.propTypes = {
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string,
  buttonClass: PropTypes.string,
  iconClass: PropTypes.string,
  isDisabled: PropTypes.bool,
  isAsync: PropTypes.bool,
  type: PropTypes.string
};

export default Button;
