import React from 'react';
import Button from 'global/components/Button';
import Modal from 'react-bootstrap/Modal';
import Portal from 'global/components/Portal';
import {RaredParams} from 'settings/components/RaredParams';
import AttributeForm from 'settings/components/attribute_wizard/AttributeForm';
import QuestionInfoTooltip from 'global/components/QuestionInfoTooltip';
import styles from '../../styles/RairedModal';
import ax from 'global/axios';
import _ from 'lodash';

const $ = window.$;

class RiskAttributeSetupContainer extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    const element = document.querySelector('#react-root');

    this.state = {
      modalOpen: false,
      modalIn: false,
      submitting: false,
      validForm: false,
      name: null,
      touchedName: false,
      validWeight: true,
      timePeriodParam: element.dataset.timePeriodParam,
      componentType: null,
      categoryId: null,
      categoryDefinitionId: null,
      componentDefinitionId: null,
      attributeType: null,
      ratingDescriptionOne: null,
      ratingDescriptionTwo: null,
      ratingDescriptionThree: null,
      ratingDescriptionFour: null,
      ratingDescriptionFive: null
    };
  }

  componentDidMount() {
    this._isMounted = true;
    document.addEventListener('attributeWizard:setVars', this.handleSetVars);
    document.addEventListener('weight:validation', this.handleWeightValidation);
  }

  componentWillUnmount() {
    this._isMounted = false;
    document.removeEventListener('attributeWizard:setVars');
  }

  handleAttributeFormDataChange = (formData) => {
    const prevName = this.state.name;
    const prevTouchedName = this.state.touchedName;
    const prevAttributeType = this.state.attributeType;
    const prevAttributeTypeMessage = this.state.attributeTypeMessage;
    const prevRatingDescriptionOne = this.state.ratingDescriptionOne;
    const prevRatingDescriptionTwo = this.state.ratingDescriptionTwo;
    const prevRatingDescriptionThree = this.state.ratingDescriptionThree;
    const prevRatingDescriptionFour = this.state.ratingDescriptionFour;
    const prevRatingDescriptionFive = this.state.ratingDescriptionFive;

    if (prevName != formData['name']) {
      this.setState({ name: formData['name'] });
    }
    if (prevTouchedName != formData['touchedName']) {
      this.setState({ touchedName: formData['touchedName'] });
    }
    if (prevAttributeType != formData['attributeType']) {
      this.setState({ attributeType: formData['attributeType'] });
    }
    if (prevAttributeTypeMessage != formData['attributeTypeMessage']) {
      this.setState({ attributeTypeMessage: formData['attributeTypeMessage'] });
    }
    if (prevRatingDescriptionOne != formData['ratingDescriptionOne']) {
      this.setState({ ratingDescriptionOne: formData['ratingDescriptionOne'] });
    }
    if (prevRatingDescriptionTwo != formData['ratingDescriptionTwo']) {
      this.setState({ ratingDescriptionTwo: formData['ratingDescriptionTwo'] });
    }
    if (prevRatingDescriptionThree != formData['ratingDescriptionThree']) {
      this.setState({ ratingDescriptionThree: formData['ratingDescriptionThree'] });
    }
    if (prevRatingDescriptionFour != formData['ratingDescriptionFour']) {
      this.setState({ ratingDescriptionFour: formData['ratingDescriptionFour'] });
    }
    if (prevRatingDescriptionFive != formData['ratingDescriptionFive']) {
      this.setState({ ratingDescriptionFive: formData['ratingDescriptionFive'] });
    }
  };

  handleSetVars = () => {
    if (this._isMounted) {
      this.setState({
        step: window.raredSettings.step,
        componentType: window.raredSettings.componentType,
        categoryId: window.raredSettings.categoryId,
        categoryDefinitionId: window.raredSettings.categoryDefinitionId,
        componentDefinitionId: window.raredSettings.componentDefinitionId
      });
    }
  };

  handleWeightValidation = (event) => {
    if (this._isMounted) {
      this.setState({validWeight: event.detail.valid});
    }
  };

  buildAndFireStepEvent = () => {
    const detail = {
      step: this.state.step,
      direction: 'direct',
      categoryId: this.state.categoryId
    };
    const jumpStep = new CustomEvent('attributeWizard:jumpStep', {'detail': detail});
    document.dispatchEvent(jumpStep);
  };

  getFormParams = () => {
    const getParams = new CustomEvent('attributeWizard:getFormParams');
    document.dispatchEvent(getParams);
    return window.attributeForm;
  };

  saveComponentChanges = async () => {
    const data = this.getFormParams();
    if (data.attributesPresent) {
      const errorMessage = 'An error has occurred, we apologize for the inconvenience. Please refresh the page and try again.';
      var componentUpdateRoute = '';
      if (this.state.componentType == 0) {
        componentUpdateRoute = 'update_inherent_risk_component';
      } else {
        componentUpdateRoute = 'update_quality_risk_component';
      }
      const url = `/${this.state.timePeriodParam}/risk_categories/${this.state.categoryId}/${componentUpdateRoute}`;
      return $.ajax({
        type: 'PATCH',
        url: url,
        data: data.formParams,
        success: function(data) {
          if (data.result == 'success') {
            this.setState({validForm: true});
          } else {
            this.setState({validForm: false});
          }
        }.bind(this),
        error: function() {
          window.dialogs.error({message: errorMessage});
        }.bind(this)
      });
    } else {
      this.setState({validForm: true});
    }
  };

  modalTitle = () => {
    let name = this.state.name;
    return name ? `New Attribute: ${name}` : 'New Attribute';
  };

  handleFormSubmit = async () => {
    const errorMessage = await this.saveComponentChanges();
    this.setState({submitting: true});

    if (this.state.validForm) {
      const raredParams = RaredParams(this.state);

      return ax({
        url: `/settings/overrides/attribute_overrides/${this.state.categoryDefinitionId}/risk_attributes`,
        method: 'post',
        data: raredParams
      }).then((_res) => {
        this.setState({modalIn: false, modalOpen: false, submitting: false});
        this.buildAndFireStepEvent();
      }).catch(this.handleError);
    } else {
      this.setState({modalIn: false, modalOpen: false, submitting: false});
      window.dialogs.message({
        title: 'Attribute Not Created',
        message: errorMessage.errors.base[0]
      });
    }
  };

  render() {
    const { modalOpen, name, componentType, validWeight, attributeTypeMessage, submitting } = this.state;
    const animationClassName = this.state.modalIn ? 'in ' : '';
    const makeAnimationWork = {
      onEntering: this.handleModalEntering,
      onExit: this.handleModalExit,
      backdropClassName: animationClassName,
      className: animationClassName + styles.modal
    };

    const target = `attribute-wizard-${componentType}`;

    return (
      <Portal target_id={target}>
        <div>
          {!validWeight &&
            <QuestionInfoTooltip
              content={'Total weight on this page must equal 100 before a new attribute can be added'}
              html={false}
              placement='bottom'
              trigger='hover'
            />}
          <button className='btn btn-primary' disabled={!validWeight} onClick={this.handleShowModal}>
            New Attribute
          </button>
          <Modal centered animation
            style={{zIndex:1040}}
            dialogClassName={styles.kriWizardModal}
            show={modalOpen}
            onHide={this.handleModalHide}
            {...makeAnimationWork}
          >
            <Modal.Header>
              <button className='display-md-inline close' onClick={this.handleCancelClick}>&times;</button>
              <h4 className='modal-title overflow-ellipsis'>{this.modalTitle()}</h4>
            </Modal.Header>
            <Modal.Body>
              <AttributeForm onFormDataChange={this.handleAttributeFormDataChange} componentType={componentType} />
            </Modal.Body>
            <Modal.Footer>
              {!submitting &&
                <a className='btn btn-link cancel-link' onClick={this.handleCancelClick}>Cancel</a>
              }
              <Button onClick={this.handleFormSubmit} isDisabled={!_.trim(name) || attributeTypeMessage != ''} label='Create Attribute' />
            </Modal.Footer>
          </Modal>
        </div>
      </Portal>
    );
  }

  handleShowModal = (e) => {
    e.preventDefault();
    this.setState({modalOpen: true});
  };

  handleCancelClick = (e) => {
    e.preventDefault();
    this.setState({modalIn: false, modalOpen: false, submitting: false});
  };

  handleModalEntering = () => {
    this.setState({modalIn: true});
  };

  handleModalExit = () => {
    this.setState({modalIn: false});
  };

  handleModalHide = () => {
    this.setState({modalOpen: false});
  };
}

export default RiskAttributeSetupContainer;
