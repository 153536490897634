/*eslint no-unused-vars: ["error", { "varsIgnorePattern": "React" }]*/
import React from 'react';
import TimePeriodPicker from 'reporting/section/TimePeriodPicker';

function DomainDetailReport(props) {
  const {timePeriod, onChange} = props;

  return (
    <div className='row'>
      <div className='col-md-4'>
        <h4>Domain Detail Report</h4>
      </div>
      <div className='col-lg-4 col-md-5 hidden-sm hidden-xs'>
        <TimePeriodPicker
          value={timePeriod}
          onChange={(value) => onChange({timePeriod: value})}
          showQuarters
        />
      </div>
      <div className='col-lg-3 hidden-md hidden-sm hidden-xs'></div>
    </div>
  );
}

export default DomainDetailReport;
