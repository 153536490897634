import React from 'react';
import PropTypes from 'prop-types';
import styles from 'global/styles/DataTable';
import CheckedDisplay from 'settings/components/CheckedDisplay';

class DiredSearchResultRow extends React.Component {
  render() {
    const record = this.props.record;
    return (
      <tr>
        <td>{record.key}</td>
        <td><a href={record.href}>{record.name}</a></td>
        <td className={styles.leftBorder}><a href={record.sectionHref}>{record.sectionName}</a></td>
        <td className={styles.leftBorder}><CheckedDisplay checked={record.visible} /></td>
        <td className={styles.leftBorder}>{record.valueFormat}</td>
      </tr>
    );
  }
}

DiredSearchResultRow.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    href: PropTypes.string,
    sectionName: PropTypes.string,
    sectionHref: PropTypes.string,
    visible: PropTypes.bool,
    valueFormat: PropTypes.string,
  }),
};

export default DiredSearchResultRow;
