import React from 'react';
import ax from 'global/axios';
import _ from 'lodash';

import TimePeriodRangePicker from 'reporting/section/TimePeriodRangePicker';
import TagFilterModePicker from 'reporting/section/TagFilterModePicker';
import MultiSelectWithLoader from 'reporting/section/MultiSelectWithLoader';
import FormGroup from 'reporting/section/FormGroup';
import Switch from 'global/components/Switch';
import riskCategoryDefinitionsLoader from 'reporting/section/riskCategoryDefinitionsLoader';
import riskTagOptionsLoader from 'reporting/section/riskTagOptionsLoader';
import QuestionInfoTooltip from 'global/components/QuestionInfoTooltip';

const attributeOptionsLoader = _.memoize( () => {
  return ax({url: '/reporting/packages/risk_attribute_risk_entity_definitions'})
    .then( (res) => res.data );
});

const attributeOptionsFilter = (risk_category_definition_ids) => ({data}) => {
  const {risk_category_definition_id} = data;
  return _.isEmpty(risk_category_definition_ids) ||
    _.includes(risk_category_definition_ids, risk_category_definition_id);
};

const indicatorOptionsLoader = _.memoize( () => {
  return ax({url: '/reporting/packages/risk_attribute_indicator_risk_entity_definitions'})
    .then( (res) => res.data );
});

const indicatorOptionsFilter = (risk_category_definition_ids, risk_attribute_risk_entity_definition_ids) => ({data}) => {
  const {
    risk_category_definition_id,
    risk_attribute_risk_entity_definition_id
  } = data;

  return (
    _.isEmpty(risk_category_definition_ids) ||
    _.includes(risk_category_definition_ids, risk_category_definition_id)
  ) && (
    _.isEmpty(risk_attribute_risk_entity_definition_ids) ||
    _.includes(risk_attribute_risk_entity_definition_ids, risk_attribute_risk_entity_definition_id)
  );
};

class KRITrendReportAdvanced extends React.Component {
  static title = 'KRI Trend Report Section Configuration';

  handleSelectChange = (key) => (option) => {
    this.props.onChange({[key]: _.get(option, 'value', null)});
  };

  render() {
    const records_per_page = _.get(this.props, 'records_per_page', 1);
    const { tag_filter_mode } = this.props;

    const tag_filter_mode_styles = {
      container: base => ({
        ...base,
        flexBasis: '7em'
      })
    };
    const risk_tags_styles = {
      container: base => ({
        ...base,
        flexGrow: 10
      })
    };

    return (
      <div>
        <FormGroup label='Category'>
          <MultiSelectWithLoader
            name='risk_category_definition_ids'
            value={this.props.risk_category_definition_ids}
            loader={riskCategoryDefinitionsLoader}
            onChange={this.props.onChange}
          />
        </FormGroup>

        <FormGroup label='Attribute'>
          <MultiSelectWithLoader
            key={this.props.risk_category_definition_ids}
            name='risk_attribute_risk_entity_definition_ids'
            value={this.props.risk_attribute_risk_entity_definition_ids}
            loader={attributeOptionsLoader}
            filterOption={attributeOptionsFilter(this.props.risk_category_definition_ids)}
            onChange={this.props.onChange}
          />
        </FormGroup>

        <FormGroup label='Indicator'>
          <MultiSelectWithLoader
            key={this.props.risk_category_definition_ids + this.props.risk_attribute_risk_entity_definition_ids}
            name='risk_attribute_indicator_risk_entity_definition_ids'
            value={this.props.risk_attribute_indicator_risk_entity_definition_ids}
            loader={indicatorOptionsLoader}
            filterOption={indicatorOptionsFilter(this.props.risk_category_definition_ids, this.props.risk_attribute_risk_entity_definition_ids)}
            onChange={this.props.onChange}
          />
        </FormGroup>

        <FormGroup label='Time Period Range'>
          <TimePeriodRangePicker
            start_time_period={this.props.start_time_period}
            end_time_period={this.props.end_time_period}
            onChange={this.props.onChange}
            showQuarters
            showMonths={window.riskEntitySettings.monthly_kri}
          />
        </FormGroup>

        <div className='form-group'>
          <div>
            <label>
              Tags
              <QuestionInfoTooltip
                content={'All of: Include results tagged with all of the selected tags. Any of: Include results tagged with any of the selected tags.'}
                html={false}
                placement='bottom'
                trigger='hover'
              />
            </label>
          </div>
          <div style={{display: 'flex'}}>
            <TagFilterModePicker
              value={tag_filter_mode}
              onChange={this.props.onChange}
              styles={tag_filter_mode_styles}
            />
            <MultiSelectWithLoader
              name='risk_tags'
              value={this.props.risk_tags}
              loader={riskTagOptionsLoader}
              onChange={this.props.onChange}
              styles={risk_tags_styles}
            />
          </div>
        </div>

        <FormGroup label='Compact'>
          <Switch
            name='records_per_page'
            value={records_per_page == 4}
            onChange={ (e) => this.props.onChange({records_per_page: e.target.value ? 4 : 1}) }
          />
        </FormGroup>
      </div>
    );
  }
}

export default KRITrendReportAdvanced;
