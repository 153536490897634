import _ from 'lodash';
import React from 'react';
import styles from '../styles/Table';

function BenchmarkTable({children}) {
  const includeBounds = _.compact(children).length == 7; // Compact to exclude unrendered children
  const colSpan = includeBounds ? 7 : 5;
  const classNames = 'table bordered border border-[#e4e4e4]' + styles.table;
  return (
    <div id='risk-attribute-indicator-overrides-new' className='table-light'>
      <table id='benchmarks-table' className={classNames} style={{borderLeft: '1px solid #dcdcdc'}}>
        <thead>
          <tr>
            <th colSpan={colSpan} className={styles.heading}>{window.riskEntitySettings.indicator_rating_ranges_label}</th>
          </tr>
          <tr className={styles.subheading}>
            {includeBounds && <th>Lower Bound</th>}
            <th>{window.riskEntitySettings.rating_very_low_label}</th>
            <th>{window.riskEntitySettings.rating_low_label}</th>
            <th>{window.riskEntitySettings.rating_moderate_label}</th>
            <th>{window.riskEntitySettings.rating_high_label}</th>
            <th>{window.riskEntitySettings.rating_very_high_label}</th>
            {includeBounds && <th>Upper Bound</th>}
          </tr>
        </thead>
        <tbody>
          <tr>{children}</tr>
        </tbody>
      </table>
    </div>
  );
}

export default BenchmarkTable;
