/*global $*/
import _ from 'lodash';
import React from 'react';
import ax from 'global/axios';
import Button from 'global/components/Button';
import CeilingForm from 'benchmarks/components/CeilingForm';
import FloorForm from 'benchmarks/components/FloorForm';
import AbsoluteValueForm from 'benchmarks/components/AbsoluteValueForm';
import BidirectionalForm from 'benchmarks/components/BidirectionalForm';
import ValidationMessage from 'benchmarks/components/ValidationMessage';

class RiskAttributeIndicatorBenchmarksForm extends React.Component {

  constructor(props) {
    super(props);

    const element = document.querySelector('#benchmarks-form');

    this.state = {
      url: element.dataset.url,
      limitType: '',
      benchmarks: [],
      submitting: false,
      validation: {
        benchmarks: ''
      },
      benchmarksRadio: 'future'
    };
  }

  componentDidMount() {
    this.load().then((indicator) => {
      this.setState(indicator);
    });

    document.addEventListener('keydown', this.onEnterPress);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onEnterPress);
  }

  load = () => {
    const { url } = this.state;
    return ax({
      url: url,
      method: 'get'
    }).then((res) => {
      this.setState({ title: res.data.editTitle });
      return res.data;
    }).catch(ax.handleError);
  };

  submit = () => {
    this.setState({submitting: true});
    const { url } = this.state;
    return ax({
      url: url + '/update_benchmarks',
      method: 'patch',
      data: {
        benchmarks: this.state.benchmarks,
        benchmarksRadio: this.state.benchmarksRadio
      }
    }).then((res) => {
      if (res.data.success) {
        let html = '<p class="alert">' + res.data.message + '</p>';
        $('#link_modal .modal-body').html(html);
        setTimeout(() => { $('#link_modal .close').click(); }, 1000);
        window.location.reload();
      } else {
        const { messages } = res.data.errors;
        this.setState({
          validation: {
            benchmarks: _.get(messages, 'benchmarks[0]', '')
          },
          submitting: false
        });
        return _.isEmpty(messages);
      }
    }).catch(ax.handleError);
  };

  handleChange = (benchmarks) => {
    this.setState({ benchmarks: benchmarks });
  };

  handleCheckedChange = (e) => {
    this.setState({ benchmarksRadio: e.target.value });
  };

  cancelButtonVisible() {
    return !this.state.submitting;
  }

  handleCancelClick = (e) => {
    e.preventDefault();
    return false;
    // window.location.reload();
  };

  onEnterPress = (e) => {
    if (e.keyCode == 13 && e.shiftKey == false) {
      e.preventDefault();
      this.submit();
    }
  };

  render() {
    const { limitType, benchmarks, submitting, validation } = this.state;

    return (
      <>
        <div className="modal-header">
          <button type="button" onClick={this.handleCancelClick} className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
          <h4 className="modal-title">{ this.state.title }</h4>
        </div>
        <div className="modal-body">
          <ValidationMessage>{validation.benchmarks}</ValidationMessage>
          {limitType == 'select' &&
            <h4>Benchmarks cannot be updated for this indicator.</h4>
          }
          {limitType == 'ceiling' &&
            <CeilingForm benchmarks={benchmarks} onChange={this.handleChange} />
          }
          {limitType == 'floor' &&
            <FloorForm benchmarks={benchmarks} onChange={this.handleChange} />
          }
          {limitType == 'absolute_value' &&
            <AbsoluteValueForm benchmarks={benchmarks} onChange={this.handleChange} />
          }
          {limitType == 'bidirectional' &&
            <BidirectionalForm benchmarks={benchmarks} onChange={this.handleChange} />
          }
          {limitType != 'select' &&
            <div onChange={this.handleCheckedChange} >
              <div>
                <label>
                  <input
                    type="radio"
                    name="radio-group"
                    value="future"
                    className="align-baseline mr-1"
                    data-turbolinks="false"
                    defaultChecked />
                  Apply changes to this and future Time Periods
                </label>
              </div>
              <div>
                <label>
                  <input
                    type="radio"
                    name="radio-group"
                    className="align-baseline mr-1"
                    data-turbolinks="false"
                    value="current" />
                  Apply changes only to this Time Period
                </label>
              </div>
              <div>
                <label>
                  <input
                    type="radio"
                    name="radio-group"
                    className="align-baseline mr-1"
                    data-turbolinks="false"
                    value="all" />
                  Apply changes to all open Time Periods
                </label>
              </div>
            </div>
          }
        </div>
        <div className="modal-footer">
          {this.cancelButtonVisible() &&
            <a href="#" className='btn btn-link cancel-link' data-dismiss="modal" onClick={this.handleCancelClick}>Cancel</a>}
          <Button
            onClick={this.submit}
            isDisabled={submitting}
            isAsync={true}
            label={'Update Benchmarks'}
          />
        </div>
      </>
    );
  }
}

export default RiskAttributeIndicatorBenchmarksForm;
