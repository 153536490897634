import React from 'react';
import CalendarInputGroup from 'reporting/section/CalendarInputGroup';
import YearPicker from 'reporting/section/YearPicker';
import QuarterPicker from 'reporting/section/QuarterPicker';
import MonthPicker from 'reporting/section/MonthPicker';
import styles from 'global/styles/TimePeriodPicker';
import timePeriodLoader from 'reporting/section/timePeriodLoader';
import {fromParam, toParam} from 'reporting/section/timePeriodParam';
import monthNames from 'reporting/section/monthNames';
import _ from 'lodash';

class TimePeriodPicker extends React.Component {
  constructor(props) {
    super(props);

    const date = new Date();
    let visibleYear = date.getFullYear();
    const selected = fromParam(props.value);
    if (selected) {
      visibleYear = selected.year;
    }

    this.state = {
      open: false,
      selected: selected,
      visibleYear: visibleYear,
      timePeriods: [],
      loading: true,
      error: false
    };
    this.ref = React.createRef();
  }

  componentDidMount() {
    if (!this.props.value) {
      this.props.onChange(window.riskEntitySettings.selected_time_period);
    }

    this.loadTimePeriods();
    document.addEventListener('mousedown', this.handleDocumentMouseDown, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleDocumentMouseDown, false);
  }

  componentDidUpdate(prevProps) {
    if (this.props.value != prevProps.value) {
      this.setState({selected: fromParam(this.props.value)});
    }
  }

  handleDocumentMouseDown = (e) => {
    const node = this.ref.current;
    const inNode = node && node.contains(e.target);
    if (!inNode) {
      this.close();
    }
  };

  inputGroupText = () => {
    const {open, selected} = this.state;
    if (!open && selected) {
      if (selected.unit == 'month') {
        const monthName = monthNames[selected.month - 1];
        return `${monthName} ${selected.year}`;
      } else {
        return `Q${selected.quarter}-${selected.year}`;
      }
    }
    return 'Select Time Period';
  };

  render() {
    const {showMonths, showQuarters} = this.props;
    const years = this.state.timePeriods.map( x => x.year );

    return (
      <span ref={this.ref}>
        <CalendarInputGroup error={this.state.error} loading={this.state.loading} onClick={this.toggleOpen}>
          {this.inputGroupText()}
        </CalendarInputGroup>
        { this.state.open &&
          <nav className={styles.nav}>
            <YearPicker
              value={this.state.visibleYear}
              min={_.min(years)}
              max={_.max(years)}
              onChange={this.changeVisibleYear}
            />
            { showQuarters &&
              <QuarterPicker
                isDisabled={this.isQuarterDisabled}
                isSelected={this.isQuarterSelected}
                onClick={this.selectQuarter}
              />
            }
            { showMonths &&
              <MonthPicker
                isDisabled={this.isMonthDisabled}
                isSelected={this.isMonthSelected}
                onClick={this.selectMonth}
              />
            }
            <footer>
            </footer>
          </nav>
        }
      </span>
    );
  }

  toggleOpen = () => {
    const {open} = this.state;
    this.setState({open: !open});
    if (this.state.error) {
      // Bust the cache and reload if the user attempts to open in an error state
      timePeriodLoader().catch( () => timePeriodLoader.cache.delete() );
      this.loadTimePeriods();
    }
  };

  close = () => {
    const {selected} = this.state;
    if (selected) {
      this.setState({visibleYear: selected.year});
    }
    this.setState({open: false});
  };

  changeVisibleYear = (year) => {
    this.setState({visibleYear: year});
  };

  selectMonth = (monthNumber) => {
    const {visibleYear} = this.state;
    const selected = {
      unit: 'month',
      year: visibleYear,
      month: monthNumber
    };
    this.setState({
      selected: selected,
      open: false
    });
    this.props.onChange(toParam(selected));
  };

  selectQuarter = (quarter) => {
    const {visibleYear} = this.state;
    const selected = {
      unit: 'quarter',
      year: visibleYear,
      quarter: quarter
    };
    this.setState({
      selected: selected,
      open: false
    });
    this.props.onChange(toParam(selected));
  };

  timePeriodPredicate = (timePeriod) => {
    const {showQuarters, showMonths} = this.props;
    if (!showQuarters && timePeriod.unit == 'quarter') {
      return false;
    }
    if (!showMonths && timePeriod.unit == 'month') {
      return false;
    }
    return timePeriod.status > 0;
  };

  loadTimePeriods() {
    this.setState({
      loading: true,
      error: false
    });

    timePeriodLoader()
      .then( (res) => {
        const {visibleYear} = this.state;
        const timePeriods = _.filter(res.data.time_periods, this.timePeriodPredicate);
        const years = timePeriods.map(x => x.year);
        const minYear = _.min(years);
        const maxYear = _.max(years);
        if (maxYear && !_.inRange(visibleYear, minYear, maxYear)) {
          this.setState({visibleYear: maxYear});
        }
        this.setState({
          error: false,
          loading: false,
          timePeriods: timePeriods
        });
      }, () => {
        this.setState({
          error: true,
          loading: false,
          timePeriods: []
        });
      });
  }

  isMonthDisabled = (monthNumber) => {
    const {visibleYear, timePeriods} = this.state;
    return !_.some(timePeriods, _.matches({unit: 'month', year: visibleYear, month: monthNumber}));
  };

  isMonthSelected = (monthNumber) => {
    const {selected, visibleYear} = this.state;
    return selected && selected.unit == 'month' && visibleYear == selected.year && selected.month == monthNumber;
  };

  isQuarterDisabled = (quarter) => {
    const {visibleYear, timePeriods} = this.state;
    return !_.some(timePeriods, _.matches({unit: 'quarter', year: visibleYear, quarter: quarter}));
  };

  isQuarterSelected = (quarter) => {
    const {selected, visibleYear} = this.state;
    return selected && selected.unit == 'quarter' && visibleYear == selected.year && selected.quarter == quarter;
  };
}

export default TimePeriodPicker;
