import React from 'react';
import Table from 'benchmarks/components/Table';
import Cell from 'benchmarks/components/Cell';

const isFlat = window.riskEntitySettings.indicator_flat_rating_calc;
const isIncremental = !isFlat;
const ratingSymbols = {veryLow: '>', low: '>', moderate: '>', high: '>', veryHigh: '≤'};

function FloorDisplay(props) {
  const { benchmarks } = props;

  return (
    <Table>
      {isIncremental &&
        <Cell symbol={ratingSymbols.softLow} value={benchmarks[1]} />}
      <Cell symbol={ratingSymbols.veryLow} value={benchmarks[2]} />
      <Cell symbol={ratingSymbols.low} value={benchmarks[3]} />
      <Cell symbol={ratingSymbols.moderate} value={benchmarks[4]} />
      <Cell symbol={ratingSymbols.high} value={benchmarks[5]} />
      <Cell symbol={ratingSymbols.veryHigh} value={benchmarks[5]} />
      {isIncremental &&
        <Cell symbol={ratingSymbols.softHigh} value={benchmarks[6]} />}
    </Table>
  );
}

export default FloorDisplay;
