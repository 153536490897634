import _ from 'lodash';
import React from 'react';

function ValidationMessage({children}) {
  if (_.isEmpty(children)) {
    return null;
  } else {
    return (
      <div className='alert-message error-alert-message'>
        <h4>Before Continuing:</h4>
        <ul>
          <li className='error-message'>{children}</li>
        </ul>
      </div>
    );
  }
}

export default ValidationMessage;
