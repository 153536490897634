import React from 'react';
import BidirectionalLayout from 'benchmarks/components/BidirectionalLayout';

function parse(string) {
  const x = parseFloat(string);
  return isNaN(x) ? null : x;
}

function Input(props) {
  const { symbol, value, onChange, disabled } = props;
  return (
    <div className="form-group">
      <div className='input-group'>
        <span className='input-group-addon limit-sign'>{symbol}</span>
        <input className='form-control' style={{zIndex: 0}} type="text" disabled={disabled ? 'disabled' : ''} value={value} onChange={onChange} />
      </div>
    </div>
  );
}

function MidpointInput({ value, onChange }) {
  return (
    <div className="form-group">
      <div>
        <input className='form-control' style={{zIndex: 0}} type="text" value={value} onChange={onChange} />
      </div>
    </div>
  );
}

class BidirectionalForm extends React.Component {
  constructor(props) {
    super(props);
    const initialEmptyBenchmarks = ['', '', '', '', '', '', '', '', '', '', ''];
    this.state = {benchmarks: props.benchmarks || initialEmptyBenchmarks};
  }

  handler(index, e) {
    const target = e.target;
    const value = target.value.trim();
    const benchmarks = this.state.benchmarks;
    benchmarks[index] = value;
    if (index == 1) {
      benchmarks[0] = value;
    } else if (index == 9) {
      benchmarks[10] = value;
    }
    this.setState({benchmarks}, this.notifier);
  }

  notifier = () => {
    const {onChange} = this.props;
    const {benchmarks} = this.state;
    if (onChange) {
      const parsed = benchmarks.map(parse);
      onChange(parsed);
    }
  };

  render() {
    const {benchmarks} = this.state;
    return (
      <BidirectionalLayout>
        <Input
          symbol='≤'
          value={benchmarks[0]}
          disabled={true}
        />
        <Input
          symbol='≥'
          value={benchmarks[10]}
          disabled={true}
        />
        <Input
          symbol='>'
          value={benchmarks[1]}
          onChange={this.handler.bind(this, 1)}
        />
        <Input
          symbol='<'
          value={benchmarks[9]}
          onChange={this.handler.bind(this, 9)}
        />
        <Input
          symbol='>'
          value={benchmarks[2]}
          onChange={this.handler.bind(this, 2)}
        />
        <Input
          symbol='<'
          value={benchmarks[8]}
          onChange={this.handler.bind(this, 8)}
        />
        <Input
          symbol='>'
          value={benchmarks[3]}
          onChange={this.handler.bind(this, 3)}
        />
        <Input
          symbol='<'
          value={benchmarks[7]}
          onChange={this.handler.bind(this, 7)}
        />
        <Input
          symbol='>'
          value={benchmarks[4]}
          onChange={this.handler.bind(this, 4)}
        />
        <Input
          symbol='<'
          value={benchmarks[6]}
          onChange={this.handler.bind(this, 6)}
        />
        <MidpointInput
          value={benchmarks[5]}
          onChange={this.handler.bind(this, 5)}
        />
      </BidirectionalLayout>
    );
  }
}

export default BidirectionalForm;
