import React from 'react';
import PropTypes from 'prop-types';
import ax from 'global/axios';
import styles from 'global/styles/DataTable';
import CheckedDisplay from 'settings/components/CheckedDisplay';

class DataInputRiskEntityDefinitionRow extends React.Component {
  render() {
    const record = this.props.record;
    return (
      <tr
        ref={this.props.provided.innerRef}
        className={styles.draggable}
        {...this.props.provided.draggableProps}
      >
        <td
          className={styles.controlColumn}
          {...this.props.provided.dragHandleProps}
        >
          <span className='grip-handle'></span>
        </td>
        <td className={styles.controlColumn}><input id={`dataInputRiskEntityDefinition-${record.id}`} aria-label='checkbox' className='boolean' type='checkbox' checked={this.props.selected} onChange={this.handleCheckedChange} /></td>
        <td>{record.key}</td>
        <td><a href={record.href}>{record.name}</a></td>
        <td className={[styles.leftBorder, styles.column15].join(' ')}>{record.inputType}</td>
        <td className={[styles.leftBorder, styles.column15].join(' ')}>{record.valueFormat}</td>
        <td className={[styles.leftBorder, styles.column5].join(' ')}>{record.decimalPlaces}</td>
        <td className={[styles.leftBorder, styles.column5, styles.alignCenter].join(' ')}><CheckedDisplay checked={record.visible} /></td>
        <td className={[styles.leftBorder, styles.column5, styles.alignCenter].join(' ')}><CheckedDisplay checked={record.usedInLastYear} /></td>
        { record.currentUserCanPromote &&
          <>
            <td className={[styles.leftBorder, styles.column5, styles.alignCenter].join(' ')}><CheckedDisplay checked={record.global} /></td>
            <td className={[styles.leftBorder, styles.column5, styles.alignCenter].join(' ')}>
              { !record.global && record.currentUserCanPromote &&
              <button
                onClick={() => {
                  const confirmBox = window.confirm(
                    'Do you really want to add to the Global Library?'
                  );
                  if (confirmBox === true) {
                    this.promoteToGlobalLibrary(record);
                  }
                }}>
                <i className='fa fa-upload'></i>
              </button> }
            </td></>}
      </tr>
    );
  }

  handleCheckedChange = (e) => {
    this.props.onSelectedChange(this.props.record.id, e.target.checked);
  };

  promoteToGlobalLibrary = (record) => {
    return ax({
      url: record.promotionHref,
      params: { },
      method: 'get',
      headers: { }
    }).then((_res) => {
      window.location.reload();
    }).catch(ax.handleError);
  };
}

DataInputRiskEntityDefinitionRow.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    href: PropTypes.string,
    promotionHref: PropTypes.string,
    visible: PropTypes.bool,
    global: PropTypes.bool,
    valueFormat: PropTypes.string,
    decimalPlaces: PropTypes.string,
    inputType: PropTypes.string,
    usedInLastYear: PropTypes.bool,
  }),
  selected: PropTypes.bool.isRequired,
  onSelectedChange: PropTypes.func.isRequired
};

export default DataInputRiskEntityDefinitionRow;
