import React from 'react';
import styles from '../styles/Table';

function Cell(props) {
  const { symbol, value } = props;
  return (
    <td className={styles['rating-range']}>
      <div className="text-center">{symbol} {value}</div>
    </td>
  );
}

export default Cell;
