import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import styles from 'settings/styles/Toolbar';

class ToolbarAction extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = { inProgress: false };
  }

  render() {
    const action = this.props.action;
    const buttonClassName = `${styles.actionButton} btn btn-outline`;
    return (
      <button type='button' className={buttonClassName} onClick={this.handleClick} title={action.tooltip}>
        {this.renderIcon(action)}{(_.has(action, 'text') && ` ${action.text}` )}
      </button>
    );
  }

  renderIcon = (action) => {
    const inProgress = this.state.inProgress;
    const iconClassName = `fa ${action.iconClass}`;

    if (inProgress) {
      return (
        <i className='fa fa-spinner fa-pulse'></i>
      );
    } else {
      return (
        <i className={iconClassName}></i>
      );
    }
  };

  handleClick = (e) => {
    e.preventDefault();
    const loading = this.props.loading;
    const inProgress = this.state.inProgress;

    if (!(loading || inProgress)) {
      this.setState({ inProgress: true });

      this.props.action.onClick().then(() => {
        // Do not set state on an unmounted component. ToolbarActions are
        // unmounted when hidden, which frequently happens as a result of
        // the above onClick handler, thus making resetting the state
        // unnecessary.
        if (this._isMounted) {
          this.setState({ inProgress: false });
        }
      });
    }
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  static shape() {
    return PropTypes.shape({
      onClick: PropTypes.func.isRequired,
      iconClass: PropTypes.string.isRequired,
      text: PropTypes.string,
      tooltip: PropTypes.string
    });
  }
}

ToolbarAction.propTypes = {
  action: ToolbarAction.shape().isRequired,
  loading: PropTypes.bool
};

export default ToolbarAction;
