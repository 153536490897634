import 'url-search-params-polyfill';
import React from 'react';
import Routes from 'global/routes';
import DataTable from 'global/components/DataTable';
import DiredSearchResultRow from 'settings/components/DiredSearchResultRow';
import SearchButton from 'settings/components/SearchButton';
import ax from 'global/axios';

class DiredSearchResultsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      definitions: [],
      columnHeaders: [],
      loading: true
    };
  }

  componentDidMount() {
    this.getDataInputRiskEntityDefinitions();
  }

  render() {
    const definitions = this.state.definitions;
    const loading = this.state.loading;
    const columnHeaders = this.state.columnHeaders;
    return (
      <div className='panel'>
        <div className='panel-heading'>
          <span className='panel-title'>Search Results for &quot;{this.searchTerm()}&quot;</span>
          <div className='panel-heading-controls'>
            <SearchButton
              text={'Search Data Inputs'}
              searchPath={Routes.search_settings_overrides_data_input_sections_path()}
              clearSearchPath={Routes.settings_overrides_data_input_sections_path()}
              searchTerm={this.searchTerm()} />
          </div>
        </div>
        <DataTable
          records={definitions}
          columnHeaders={columnHeaders}
          isLoading={loading}
          rowComponent={DiredSearchResultRow}
        />
      </div>
    );
  }

  getDataInputRiskEntityDefinitions = () => {
    this.setState({ loading: true });
    ax({
      url: window.location.href,
      method: 'get'
    }).then((res) => {
      this.setState({ definitions: res.data.direds, columnHeaders: res.data.columnHeaders, loading: false });
    }).catch(ax.handleError);
  };

  searchTerm = () => {
    return new URLSearchParams(window.location.search).get('q');
  };
}

export default DiredSearchResultsContainer;
