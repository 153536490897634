import React from 'react';
import QuestionInfoTooltip from 'global/components/QuestionInfoTooltip';
import Switch from 'global/components/Switch';

const tooltipProps = {
  content: 'Available for selection in Monthly time periods.',
  html: false,
  placement: 'bottom',
  trigger: 'hover'
};

function MonthlySwitch({value, onChange}) {
  return (
    <>
      <label className='control-label'>
        Monthly Rating Benchmarks
        <QuestionInfoTooltip {...tooltipProps} />
      </label>
      <Switch name="enabledMonthly" value={value} onChange={onChange} />
    </>
  );
}

export default MonthlySwitch;
