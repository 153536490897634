import React from 'react';
import TimePeriodPicker from 'reporting/section/TimePeriodPicker';
import RiskCategoryDefinitionPicker from 'reporting/section/RiskCategoryDefinitionPicker';
import LineOfBusinessPicker from 'reporting/section/LineOfBusinessPicker';
import DepartmentPicker from 'reporting/section/DepartmentPicker';
import FormGroup from 'reporting/section/FormGroup';
import Switch from 'global/components/Switch';
import _ from 'lodash';

function ProfileReportAdvanced(props) {
  const {
    timePeriod,
    risk_category_definition_id,
    line_of_business_risk_entity_definition_id,
    department_risk_entity_definition_id,
    onChange
  } = props;

  const compact = _.get(props, 'compact', true);

  return (
    <div>
      <FormGroup label='Time Period'>
        <TimePeriodPicker
          value={timePeriod}
          onChange={(value) => onChange({timePeriod: value})}
          showQuarters
          showMonths={window.riskEntitySettings.monthly_kri}
        />
      </FormGroup>

      <FormGroup label={window.riskEntitySettings.risk_category_label}>
        <RiskCategoryDefinitionPicker
          timePeriod={timePeriod}
          value={risk_category_definition_id}
          onChange={onChange}
        />
      </FormGroup>

      <FormGroup label='Line of Business'>
        <LineOfBusinessPicker
          risk_category_definition_id={risk_category_definition_id}
          value={line_of_business_risk_entity_definition_id}
          onChange={onChange}
        />
      </FormGroup>

      <FormGroup label={window.riskEntitySettings.department_label}>
        <DepartmentPicker
          risk_category_definition_id={risk_category_definition_id}
          line_of_business_risk_entity_definition_id={line_of_business_risk_entity_definition_id}
          value={department_risk_entity_definition_id}
          onChange={onChange}
        />
      </FormGroup>

      <FormGroup label='Compact'>
        <Switch
          name='compact'
          value={compact}
          onChange={ (e) => onChange({compact: e.target.value}) }
        />
      </FormGroup>
    </div>
  );
}

ProfileReportAdvanced.title = 'Profile Report Section Configuration';

export default ProfileReportAdvanced;
