/*eslint no-unused-vars: ["error", { "varsIgnorePattern": "React" }]*/
import React from 'react';
import TimePeriodPicker from 'reporting/section/TimePeriodPicker';
import RiskCategoryPicker from 'reporting/section/RiskCategoryPicker';

function HeatMapReport(props) {
  const {timePeriod, risk_category_id, onChange} = props;

  return (
    <div className='row'>
      <div className='col-md-4'>
        <h4>Heat Map Report</h4>
      </div>
      <div className='col-lg-4 col-md-5 hidden-sm hidden-xs'>
        <TimePeriodPicker
          value={timePeriod}
          onChange={(value) => onChange({timePeriod: value})}
          showQuarters
          showMonths={window.riskEntitySettings.monthly_kri}
        />
      </div>
      <div className='col-lg-3 hidden-md hidden-sm hidden-xs'>
        <RiskCategoryPicker
          timePeriod={timePeriod}
          value={risk_category_id}
          onChange={onChange}
        />
      </div>
    </div>
  );
}

export default HeatMapReport;
