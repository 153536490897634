import React from 'react';

function OrientationButtons(props) {
  const {value, onChange} = props;

  const buttonClassName = (name) => {
    if (name == value) {
      return 'btn btn-outline active';
    } else {
      return 'btn btn-outline';
    }
  };
  const handleClick = (name) => () => {
    if (name != value) {
      onChange(name);
    }
  };

  return (
    <div className='btn-group' data-toggle='tooltip' title='Orientation'>
      <button className={buttonClassName('portrait')} onClick={handleClick('portrait')}>
        <svg style={{width: '1em'}} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M5.3 2v20h13V5.6L14.8 2H5.3zm10-1.3H5a1 1 0 00-1 1v20.6c0 .5.4 1 1 1h13.6c.6 0 1-.5 1-1V5L15.2.8z" fill="black"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M14 .8h1.3V5h4.3v1.3H14V.7z" fill="black"/>
        </svg>
        <span className='sr-only'>
          Portrait
        </span>
      </button>
      <button className={buttonClassName('landscape')} onClick={handleClick('landscape')}>
        <svg style={{width: '1em'}} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M22 18.4H2V5.3h16.4L22 8.8v9.5zm1.3-10v10.2c0 .6-.5 1-1 1H1.6a1 1 0 01-1-1V5c0-.6.5-1 1-1H19l4.4 4.4z" fill="black"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M23.3 9.6V8.4h-4.4V4h-1.3v5.6h5.6z" fill="black"/>
        </svg>
        <span className='sr-only'>
          Landscape
        </span>
      </button>
    </div>
  );
}

export default OrientationButtons;
