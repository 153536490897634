import React from 'react';
import styles from 'global/styles/TimePeriodPicker';

function CalendarInputGroup(props) {
  const {children, error, loading, onClick} = props;
  const iconClass = error ? 'fa fa-calendar-times-o' : (loading ? 'fa fa-spinner fa-pulse fa-fw' : 'fa fa-calendar');
  const inputGroupClasses = ['input-group', styles.inputGroup].join(' ');

  return (
    <div className='datepicker'>
      <div className={inputGroupClasses}>
        <div className='form-control' onClick={onClick} style={{zIndex: 'auto'}}>
          {children}
        </div>
        <div className='input-group-btn'>
          <button type='button' className='btn btn-default' style={{zIndex: 'auto'}} onClick={onClick}>
            <span className={iconClass}></span>
            <span className="sr-only">Toggle Calendar</span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default CalendarInputGroup;
