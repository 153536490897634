import React from 'react';
import _ from 'lodash';
import monthNames from 'reporting/section/monthNames';
import styles from 'global/styles/TimePeriodPicker';

function Month(props) {
  const {name, number, disabled, selected, onClick} = props;
  const liProps = {
    className: disabled ? styles.disabled : (selected ? styles.selected : null),
    onClick: onClick && !(disabled || selected) ? _.partial(onClick, number) : null
  };

  return (
    <li {...liProps}>
      <label>
        {name}
      </label>
    </li>
  );
}
function MonthPicker(props) {
  const {isSelected, isDisabled, onClick} = props;
  return (
    <ol className={styles.months}>
      { _.range(1, 13).map( (n) => (
        <Month
          name={monthNames[n-1]}
          key={monthNames[n-1]}
          disabled={isDisabled(n)}
          selected={isSelected(n)}
          onClick={_.partial(onClick, n)}
        />
      )) }
    </ol>
  );
}

export default MonthPicker;
