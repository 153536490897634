import React from 'react';
import MonthPicker from 'risk_management/components/MonthPicker';
import ax from 'global/axios';

function onChange(year, month) {
  ax({
    url: '/time_periods/change_time_period',
    method: 'post',
    data: {
      time_period_select: window.reportMonthPickerParams,
      time_period_param: [year, month].join('-')
    }
  }).then((res) => {
    window.location = res.data.url;
  }).catch(ax.handleError);
}

function ReportMonthPicker() {
  return (
    <MonthPicker filter={(time_period) => time_period.status > 0} onChange={onChange} />
  );
}

export default ReportMonthPicker;
