import React from 'react';
import PropTypes from 'prop-types';
import Routes from 'global/routes';
import DataTable from 'global/components/DataTable';
import Toolbar from 'settings/components/Toolbar';
import RiskRow from 'settings/components/RiskRow';
import ax from 'global/axios';
import _ from 'lodash';

class RiskAttributesFormContainer extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      attributes: [],
      columnHeaders: [],
      loading: true,
      reordering: false,
      toolbarActions: [
        {
          onClick: this.handleNewAttributeClick,
          iconClass: 'fa-plus',
          tooltip: 'Add New Attribute',
          visibility: {
            forecasting: false,
            cybersecurity: false
          }
        },
        {
          onClick: this.handleSaveReorderClick,
          iconClass: 'fa fa-save',
          text: 'Save Arrangement',
          visibility: {
            whenReordering: true
          }
        },
        {
          onClick: this.handleCancelReorderClick,
          iconClass: 'fa fa-ban',
          text: 'Cancel',
          visibility: {
            whenReordering: true
          }
        }
      ]
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.getRiskAttributes();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const attributes = this.state.attributes;
    const loading = this.state.loading;
    const columnHeaders = this.state.columnHeaders;

    return (
      <div className='panel'>
        <div className='panel-heading'>
          <span className='panel-title'>{this.props.title}</span>
        </div>
        <Toolbar actions={this.state.toolbarActions} isActionVisible={this.toolbarActionVisible} loading={loading} />
        <DataTable
          records={attributes}
          columnHeaders={columnHeaders}
          isLoading={loading}
          isCheckable={false}
          isDraggable={true}
          rowComponent={RiskRow}
          onDragEnd={this.handleDragEnd}
        />
      </div>
    );
  }

  toolbarActionVisible = (key) => {
    const {
      toolbarActions,
      reordering
    } = this.state;
    const visibilityParams = toolbarActions[key].visibility;

    if (!_.has(visibilityParams, 'whenReordering') && reordering) {
      // Short circuit for reordering is occurring.
      return false;
    }

    if (_.has(visibilityParams, 'forecasting') && visibilityParams.forecasting === this.props.forecasting && _.has(visibilityParams, 'cybersecurity') && visibilityParams.cybersecurity === this.props.cybersecurity) {
      return toolbarActions;
    } else if (_.has(visibilityParams, 'whenReordering')) {
      return reordering;
    }
  };

  handleNewAttributeClick = async () => {
    window.location = Routes.new_settings_overrides_attribute_override_risk_attribute_path(this.props.attributeOverrideId, {
      risk_category_definition_id: this.props.attributeOverrideId,
      risk_attribute_domain_definition: this.props.riskAttributeDomainDefinitionId,
      component_type: this.props.componentType
    });
  };

  handleSaveReorderClick =  async () => {
    return await this.updateOrder();
  };

  handleCancelReorderClick = async () => {
    return await this.getRiskAttributes();
  };

  handleDragEnd = (result) => {
    // Dropped outside the table
    if (!result.destination) {
      return;
    }

    const attributes = reorder(
      this.state.attributes,
      result.source.index,
      result.destination.index
    );

    this.setState({ attributes: attributes, reordering: true });
  };

  getRiskAttributes = async () => {
    return ax({
      url: Routes.attributes_settings_overrides_attribute_override_risk_attributes_path(this.props.attributeOverrideId),
      params: {
        component_type: this.props.componentType,
        forecasting: this.props.forecasting,
        risk_category_definition_id: this.props.attributeOverrideId,
        risk_attribute_domain_definition: this.props.riskAttributeDomainDefinitionId
      },
      method: 'get'
    }).then((res) => {
      if (this._isMounted) {
        this.setState({ attributes: res.data.rareds, columnHeaders: res.data.columnHeaders, loading: false, reordering: false });
      }
    }).catch(ax.handleError);
  };

  updateOrder = async () => {
    const orderedKeys = this.state.attributes.map(i => i.id);
    return ax({
      url: Routes.update_order_settings_overrides_attribute_override_risk_attributes_path(this.props.attributeOverrideId),
      method: 'patch',
      data: {
        risk_attribute_risk_entity_definition_ids: orderedKeys
      }
    }).then(() => {
      return this.getRiskAttributes();
    }).catch(ax.handleError);
  };
}

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

RiskAttributesFormContainer.propTypes = {
  title: PropTypes.string,
  componentType: PropTypes.number,
  forecasting: PropTypes.bool,
  cybersecurity: PropTypes.bool,
  attributeOverrideId: PropTypes.number.isRequired,
  riskAttributeDomainDefinitionId: PropTypes.string
};

export default RiskAttributesFormContainer;
