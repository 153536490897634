import React from 'react';
import ax from 'global/axios';
import Select from 'react-select';

class SlidingBar extends React.Component {
  constructor(props) {
    super(props);

    const element = document.querySelector('#sliding-bar-container-' + this.props.id);

    this.state = {
      url: element.dataset.url,
      originalBadges: JSON.parse(element.dataset.gatheredBadges),
      gatheredBadges: JSON.parse(element.dataset.gatheredBadges),
      select_format: false,
      rating_range_detail: {},
      measurement_points: {},
      selectedMeasurementPoints: [
        { value: 'value', label: 'Value' },
        { value: 'target', label: window.riskEntitySettings.target_label },
        { value: 'peer', label: window.riskEntitySettings.peer_average_label },
        { value: 'policy', label: window.riskEntitySettings.policy_limit_label },
        { value: 'previous', label: window.riskEntitySettings.previous_value_label },
        { value: 'forecasted', label: window.riskEntitySettings.forecasted_value_label },
        { value: 'custom_one', label: window.riskEntitySettings.custom_measurement_point_1_label },
        { value: 'custom_two', label: window.riskEntitySettings.custom_measurement_point_2_label },
      ],
    };
  }

  componentDidMount() {
    this.load().then((indicator) => {
      this.setState({ 'indicator': indicator });
      this.setState(indicator);
      this.updateSelectedMeasurementPoints(indicator);
    });
  }

  load = () => {
    const { url } = this.state;
    return ax({
      url: url,
      method: 'get',
    }).then((res) => {
      return res.data;
    }).catch(ax.handleError);
  };

  updateSelectedMeasurementPoints = (indicator) => {
    const options = this.state.selectedMeasurementPoints.filter(({ value }) => {
      let measurementPoint = value;

      return indicator.measurement_points[measurementPoint] && indicator.measurement_points[measurementPoint].value;
    });

    this.setState({ selectedMeasurementPoints: options });
  };

  setSelectedMeasurementPoints = (options) => {
    this.setState({ selectedMeasurementPoints: options });
  };

  renderMeasurementPoint = (name, display) => {
    let style = display.style;
    return (
      <div className="lollypop" style={style} data-toggle="tooltip" data-placement="bottom" data-title={display.label}>
        <div className="new-badge">
          <span>{display.abbreviation}</span>
        </div>
        <div className="new-stick"></div>
      </div>
    );
  };

  doesntOverlap = (overlaps, name) => {
    return !overlaps.flat().includes(name);
  };

  renderOverlapPoints = (overlap, measurement_points) => {
    let point = measurement_points[overlap[0]];
    if (point == null) {
      return;
    }
    let style = point.display.style;

    let labels = '';
    let abbreviations = '';
    for (var i = 0; i < overlap.length; i++) {
      point = measurement_points[overlap[i]];
      labels = labels + point.display.label + ' ';
      abbreviations = abbreviations + point.display.abbreviation + ' ';
    }

    return (
      <div className="lollypop" style={style} data-toggle="tooltip" data-placement="bottom" data-title={labels}>
        <div className="wide-badge">
          <span>{abbreviations}</span>
        </div>
        <div className="new-stick"></div>
      </div>
    );
  };

  currentlyOverlaps = (item, currentBadges) => {
    let found = false;
    if (currentBadges.overlapped.length == 0) {
      return false;
    }
    for (var i = 0; i < currentBadges.overlapped.length; i++) {
      if (currentBadges.overlapped[i].includes(item)) {
        found = true;
      }
    }
    return found;
  };

  render() {
    const {
      select_format,
      rating_range_detail,
      measurement_points,
      selectedMeasurementPoints,
    } = this.state;

    const measurementPointOptions = [
      { value: 'value', label: 'Value' },
      { value: 'target', label: window.riskEntitySettings.target_label },
      { value: 'peer', label: window.riskEntitySettings.peer_average_label },
      { value: 'policy', label: window.riskEntitySettings.policy_limit_label },
      { value: 'previous', label: window.riskEntitySettings.previous_value_label },
      { value: 'forecasted', label: window.riskEntitySettings.forecasted_value_label },
      { value: 'custom_one', label: window.riskEntitySettings.custom_measurement_point_1_label },
      { value: 'custom_two', label: window.riskEntitySettings.custom_measurement_point_2_label },
    ];

    let valueSelected = selectedMeasurementPoints.find( ({ value }) => value === 'value' );
    let targetSelected = selectedMeasurementPoints.find( ({ value }) => value === 'target' );
    let peerSelected = selectedMeasurementPoints.find( ({ value }) => value === 'peer' );
    let policySelected = selectedMeasurementPoints.find( ({ value }) => value === 'policy' );
    let previousSelected = selectedMeasurementPoints.find( ({ value }) => value === 'previous' );
    let forecastedSelected = selectedMeasurementPoints.find( ({ value }) => value === 'forecasted' );
    let customOneSelected = selectedMeasurementPoints.find( ({ value }) => value === 'custom_one' );
    let customTwoSelected = selectedMeasurementPoints.find( ({ value }) => value === 'custom_two' );

    const rating_range = measurement_points.value && measurement_points.value.rating_range;
    const target_range = measurement_points.target && measurement_points.target.rating_range;

    const valuePresent = measurement_points.value && measurement_points.value.value;
    const targetPresent = measurement_points.target && measurement_points.target.value;
    const peerPresent = measurement_points.peer && measurement_points.peer.value;
    const policyPresent = measurement_points.policy && measurement_points.policy.value;
    const previousPresent = measurement_points.previous && measurement_points.previous.value;
    const forecastedPresent = measurement_points.forecasted && measurement_points.forecasted.value;
    const customOnePresent = measurement_points.custom_one && measurement_points.custom_one.value;
    const customTwoPresent = measurement_points.custom_two && measurement_points.custom_two.value;

    let overlappedBadges = this.state.originalBadges.overlapped;
    let currentOverlaps = [];
    for (var i = 0; i < overlappedBadges.length; i++) {
      let selectedOverlaps = [];
      for (var x = 0; x < overlappedBadges[i].length; x++) {
        let isSelected = selectedMeasurementPoints.find( ({ value }) => value === overlappedBadges[i][x] );
        if (isSelected != undefined) {
          selectedOverlaps.push(overlappedBadges[i][x]);
        }
      }
      if (selectedOverlaps.length > 1) {
        currentOverlaps.push(selectedOverlaps);
      }
    }

    return (
      <div className='measurement-point-detail-container'>
        <div className='measurement-points-picker'>
          <Select
            isClearable={true}
            isSearchable={true}
            isMulti={true}
            placeholder='Choose Measurement Points'
            options={measurementPointOptions}
            value={selectedMeasurementPoints}
            onChange={this.setSelectedMeasurementPoints}
          />
        </div>
        <div className='rating-scale-display'>
          <div className='rating-scale-section'>
            <div className='rating-scale-sub-section'>
              <div className='rating-swatch rating-swatch-very-low'></div>
              <div className='rating-scale-label'>{window.riskEntitySettings.rating_very_low_label}</div>
              <div className={`rating-scale-format ${select_format ? 'rating-scale-select-format' : '' }`}>
                <strong>&nbsp;{rating_range_detail.very_low_display}</strong>
              </div>
            </div>
          </div>
          <div className='rating-scale-section'>
            <div className='rating-scale-sub-section'>
              <div className='rating-swatch rating-swatch-low'></div>
              <div className='rating-scale-label'>{window.riskEntitySettings.rating_low_label}</div>
              <div className={`rating-scale-format ${select_format ? 'rating-scale-select-format' : '' }`}>
                <strong>&nbsp;{rating_range_detail.low_display}</strong>
              </div>
            </div>
          </div>
          <div className='rating-scale-section'>
            <div className='rating-scale-sub-section'>
              <div className='rating-swatch rating-swatch-moderate'></div>
              <div className='rating-scale-label'>{window.riskEntitySettings.rating_moderate_label}</div>
              <div className={`rating-scale-format ${select_format ? 'rating-scale-select-format' : '' }`}>
                <strong>&nbsp;{rating_range_detail.moderate_display}</strong>
              </div>
            </div>
          </div>
          <div className='rating-scale-section'>
            <div className='rating-scale-sub-section'>
              <div className='rating-swatch rating-swatch-high'></div>
              <div className='rating-scale-label'>{window.riskEntitySettings.rating_high_label}</div>
              <div className={`rating-scale-format ${select_format ? 'rating-scale-select-format' : '' }`}>
                <strong>&nbsp;{rating_range_detail.high_display}</strong>
              </div>
            </div>
          </div>
          <div className='rating-scale-section'>
            <div className='rating-scale-sub-section'>
              <div className='rating-swatch rating-swatch-very-high'></div>
              <div className='rating-scale-label'>{window.riskEntitySettings.rating_very_high_label}</div>
              <div className={`rating-scale-format ${select_format ? 'rating-scale-select-format' : '' }`}>
                <strong>&nbsp;{rating_range_detail.very_high_display}</strong>
              </div>
            </div>
          </div>
        </div>

        <div className="new-sliding-bar-container">
          <div className="new-measurement-point-overlay">
            { valuePresent && valueSelected && this.doesntOverlap(currentOverlaps, 'value') && this.renderMeasurementPoint('value', measurement_points.value.display)}
            { targetPresent && targetSelected && this.doesntOverlap(currentOverlaps, 'target') && this.renderMeasurementPoint('target', measurement_points.target.display)}
            { peerPresent && peerSelected && this.doesntOverlap(currentOverlaps, 'peer') && this.renderMeasurementPoint('peer', measurement_points.peer.display)}
            { policyPresent && policySelected && this.doesntOverlap(currentOverlaps, 'policy') && this.renderMeasurementPoint('policy', measurement_points.policy.display)}
            { previousPresent && previousSelected && this.doesntOverlap(currentOverlaps, 'previous') && this.renderMeasurementPoint('previous', measurement_points.previous.display)}
            { forecastedPresent && forecastedSelected && this.doesntOverlap(currentOverlaps, 'forecasted') && this.renderMeasurementPoint('forecasted', measurement_points.forecasted.display)}
            { customOnePresent && customOneSelected && this.doesntOverlap(currentOverlaps, 'custom_one') && this.renderMeasurementPoint('custom_one', measurement_points.custom_one.display)}
            { customTwoPresent && customTwoSelected && this.doesntOverlap(currentOverlaps, 'custom_two') && this.renderMeasurementPoint('custom_two', measurement_points.custom_two.display)}
            { currentOverlaps.map(x => this.renderOverlapPoints(x, measurement_points)) }
            <div className='new-sliding-bar'>
              <div className='new-sliding-bar-background new-sliding-bar-very-low'></div>
              <div className='new-sliding-bar-background new-sliding-bar-low'></div>
              <div className='new-sliding-bar-background new-sliding-bar-moderate'></div>
              <div className='new-sliding-bar-background new-sliding-bar-high'></div>
              <div className='new-sliding-bar-background new-sliding-bar-very-high'></div>
            </div>
          </div>
        </div>

        <div className='rating-range-container'>
          <div className={`rating-range-cell ${(rating_range == 'very_low' || target_range == 'very_low') ? 'border-highlight' : ''}`}></div>
          <div className={`rating-range-cell ${(rating_range == 'low' || target_range == 'low') ? 'border-highlight' : ''}`}></div>
          <div className={`rating-range-cell ${(rating_range == 'moderate' || target_range == 'moderate') ? 'border-highlight' : ''}`}></div>
          <div className={`rating-range-cell ${(rating_range == 'high' || target_range == 'high') ? 'border-highlight' : ''}`}></div>
          <div className={`rating-range-cell ${(rating_range == 'very_high' || target_range == 'very_high') ? 'border-highlight' : ''}`}></div>
        </div>

        <div className='rating-range-container'>
          <div className='rating-range-cell'>
            {rating_range == 'very_low' ? 'Current Range' : ''}
            {rating_range == 'very_low' && target_range == 'very_low' ? ' / ' : ''}
            {target_range == 'very_low' ? 'Target Range' : ''}
          </div>
          <div className='rating-range-cell'>
            {rating_range == 'low' ? 'Current Range' : ''}
            {rating_range == 'low' && target_range == 'low' ? ' / ' : ''}
            {target_range == 'low' ? 'Target Range' : ''}
          </div>
          <div className='rating-range-cell'>
            {rating_range == 'moderate' ? 'Current Range' : ''}
            {rating_range == 'moderate' && target_range == 'moderate' ? ' / ' : ''}
            {target_range == 'moderate' ? 'Target Range' : ''}
          </div>
          <div className='rating-range-cell'>
            {rating_range == 'high' ? 'Current Range' : ''}
            {rating_range == 'high' && target_range == 'high' ? ' / ' : ''}
            {target_range == 'high' ? 'Target Range' : ''}
          </div>
          <div className='rating-range-cell'>
            {rating_range == 'very_high' ? 'Current Range' : ''}
            {rating_range == 'very_high' && target_range == 'very_high' ? ' / ' : ''}
            {target_range == 'very_high' ? 'Target Range' : ''}
          </div>
        </div>
      </div>
    );
  }
}

export default SlidingBar;
