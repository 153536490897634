import React from 'react';
import Select from 'react-select';
import _ from 'lodash';

import TimePeriodPicker from 'reporting/section/TimePeriodPicker';
import RiskCategoryDefinitionPicker from 'reporting/section/RiskCategoryDefinitionPicker';
import LineOfBusinessPicker from 'reporting/section/LineOfBusinessPicker';
import DepartmentPicker from 'reporting/section/DepartmentPicker';
import AppetiteLevelPicker from 'reporting/section/AppetiteLevelPicker';
import FormGroup from 'reporting/section/FormGroup';
import Switch from 'global/components/Switch';
import QuestionInfoTooltip from 'global/components/QuestionInfoTooltip';

function SummaryReportAdvanced(props) {
  const {
    timePeriod,
    risk_category_definition_id,
    line_of_business_risk_entity_definition_id,
    department_risk_entity_definition_id,
    appetite_level,
    onChange
  } = props;

  const include_attributes = _.get(props, 'include_attributes', false);

  const verticalView = {
    value: 'vertical',
    label: 'Vertical'
  };

  const viewOptions = [{
    value: 'horizontal',
    label: 'Horizontal'
  }, verticalView];

  const viewValue = _.find(viewOptions, _.matchesProperty('value', props.view)) || verticalView;
  const handleViewChange = (option) => props.onChange({
    view: _.get(option, 'value', verticalView.value)
  });

  return (
    <div>
      <FormGroup label='Time Period'>
        <TimePeriodPicker
          value={timePeriod}
          onChange={(value) => onChange({timePeriod: value})}
          showQuarters
          showMonths={window.riskEntitySettings.monthly_kri}
        />
      </FormGroup>

      <FormGroup label={window.riskEntitySettings.risk_category_label}>
        <RiskCategoryDefinitionPicker
          timePeriod={timePeriod}
          value={risk_category_definition_id}
          onChange={onChange}
        />
      </FormGroup>

      <FormGroup label='Line of Business'>
        <LineOfBusinessPicker
          risk_category_definition_id={risk_category_definition_id}
          value={line_of_business_risk_entity_definition_id}
          onChange={onChange}
        />
      </FormGroup>

      <FormGroup label={window.riskEntitySettings.department_label}>
        <DepartmentPicker
          risk_category_definition_id={risk_category_definition_id}
          line_of_business_risk_entity_definition_id={line_of_business_risk_entity_definition_id}
          value={department_risk_entity_definition_id}
          onChange={onChange}
        />
      </FormGroup>

      <FormGroup label={window.riskEntitySettings.indicator_risk_appetite_rating_label}>
        <AppetiteLevelPicker
          value={appetite_level}
          onChange={onChange}
        />
      </FormGroup>

      <FormGroup label='View'>
        <Select
          options={viewOptions}
          value={viewValue}
          onChange={handleViewChange}
        />
      </FormGroup>

      <div className='form-group'>
        <div>
          <label>
            Include Attribute Details
            <QuestionInfoTooltip
              content={'This option will append a detail view page for each risk attribute within this ' + window.riskEntitySettings.risk_category_label + '.'}
              html={false}
              placement='bottom'
              trigger='hover'
            />
          </label>
        </div>
        <div>
          <Switch
            name='include_attributes'
            value={include_attributes}
            onChange={ (e) => onChange({include_attributes: e.target.value}) }
          />
        </div>
      </div>
    </div>
  );
}

SummaryReportAdvanced.title = 'Summary Report Section Configuration';

export default SummaryReportAdvanced;
