import React from 'react';
import MonthPicker from 'risk_management/components/MonthPicker';

function onChange(year, month) {
  let pathChunks = window.location.pathname.split('/');
  pathChunks[1] = `${year}-${month}`;
  window.location = `${pathChunks.join('/')}${window.location.search}`;
}

function DataInputMonthPicker() {
  return (
    <MonthPicker filter={() => true} onChange={onChange} />
  );
}

export default DataInputMonthPicker;
