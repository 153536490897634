import React from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import _ from 'lodash';
import Section from 'reporting/section/Section';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

class Sections extends React.Component {
  handleDragEnd = (result) => {
    // Outside the dropzone
    if (!result.destination) {
      return;
    }

    if (result.source.index == result.destination.index) {
      return;
    }

    const sections = reorder(
      this.props.sections,
      result.source.index,
      result.destination.index
    );

    this.props.onChange(sections);
  };

  removeSectionById = (id) => () => {
    const {sections, onChange} = this.props;
    const result = _.reject(sections, _.matchesProperty('id', id));
    onChange(result);
  };

  updateSectionById = (id) => (changes) => {
    const {sections, onChange} = this.props;
    // similar to _.find, but we also get the index
    let {section, index} = _.reduce(sections, (found, section, index) => {
      if (section.id == id) {
        return {section, index};
      } else {
        return found;
      }
    }, {});

    if (!_.isUndefined(index)) {
      let result = Array.from(sections);
      _.assign(section, changes);
      result.splice(index, 1, section);
      onChange(result);
    }
  };

  render() {
    const {sections, addedSectionId} = this.props;
    return (
      <DragDropContext onDragEnd={this.handleDragEnd}>
        <Droppable droppableId='sections'>
          { (provided) => (
            <div className='list-group' ref={provided.innerRef} {...provided.droppableProps}>
              { sections.map((section, index) => (
                <Section
                  index={index}
                  key={section.id}
                  animateIn={section.id == addedSectionId}
                  onChange={this.updateSectionById(section.id)}
                  onDestroy={this.removeSectionById(section.id)}
                  values={section}
                />
              )) }
            </div>
          ) }
        </Droppable>
      </DragDropContext>
    );
  }
}

export default Sections;
