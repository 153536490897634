import React from 'react';
import Button from 'global/components/Button';
import Modal from 'react-bootstrap/Modal';
import BidirectionalDisplay from 'benchmarks/components/BidirectionalDisplay';
import CeilingDisplay from 'benchmarks/components/CeilingDisplay';
import FloorDisplay from 'benchmarks/components/FloorDisplay';
import AbsoluteValueDisplay from 'benchmarks/components/AbsoluteValueDisplay';
import SelectDisplay from 'benchmarks/components/SelectDisplay';
import styles from 'risk_management/styles/Confirmation';

function IndicatorSummary(props) {
  const { rairedName, rairedDescription, rairedDefinition } = props;
  return (
    <>
      <h4>New {window.riskEntitySettings.indicator_label}</h4>
      <ul className={styles.confirmationList}>
        <li className={styles.confirmationHeader}>Name</li>
        <ul>
          <li className={styles.confirmationItem}>{rairedName}</li>
        </ul>
        {rairedDescription &&
      <>
        <li className={styles.confirmationHeader}>Description</li>
        <ul>
          <li className={styles.confirmationItem}>{rairedDescription}</li>
        </ul>
      </>}
        {rairedDefinition &&
      <>
        <li className={styles.confirmationHeader}>Definition</li>
        <ul>
          <li className={styles.confirmationItem}>{rairedDefinition}</li>
        </ul>
      </>}
      </ul>
    </>
  );
}

function RiskObjectSummary(props) {
  const { riskObject, ratingSelect, indicatorType, areaOfRisk } = props;
  const indicatorTypeName = indicatorType == 'risk_object_rated' ? 'Risk Object Rated' : 'Data Input Rated';
  return (
    <>
      <ul className={styles.confirmationList}>
        <li className={styles.confirmationHeader}>Indicator Type</li>
        <ul>
          <li className={styles.confirmationItem}>{indicatorTypeName}</li>
        </ul>
        <li className={styles.confirmationHeader}>Area of Risk</li>
        <ul>
          <li className={styles.confirmationItem}>{areaOfRisk}</li>
        </ul>
        <li className={styles.confirmationHeader}>Risk Object</li>
        <ul>
          <li className={styles.confirmationItem}>{riskObject}</li>
        </ul>
        <li className={styles.confirmationHeader}>Rating Select</li>
        <ul>
          <li className={styles.confirmationItem}>{ratingSelect}</li>
        </ul>
      </ul>
    </>
  );
}

function DataInputSummary(props) {
  const {selectedDired, diredName, diredDefinition, diredValueFormatLabel, diredDecimalPlaces} = props;
  return (
    <>
      <h4>{selectedDired === true ? 'Existing Data Input' : 'New Data Input'}</h4>
      <ul className={styles.confirmationList}>
        <li className={styles.confirmationHeader}>Name</li>
        <ul>
          <li className={styles.confirmationItem}>{diredName}</li>
        </ul>
        <li className={styles.confirmationHeader}>Definition</li>
        <ul>
          <li className={styles.confirmationItem}>{diredDefinition}</li>
        </ul>
        <li className={styles.confirmationHeader}>Value Format</li>
        <ul>
          <li className={styles.confirmationItem}>{diredValueFormatLabel}</li>
        </ul>
        {diredDecimalPlaces &&
      <>
        <li className={styles.confirmationHeader}>Decimal Places</li>
        <ul>
          <li className={styles.confirmationItem}>{diredDecimalPlaces}</li>
        </ul>
      </>}
      </ul>
    </>
  );
}

function RairedConfirmation(props) {
  const {
    rairedName,
    rairedDescription,
    rairedDefinition,
    selectedDired,
    diredName,
    diredDefinition,
    diredValueFormatLabel,
    diredDecimalPlaces,
    dataInputSelectOptions,
    enabledMonthly,
    enabledQuarterly,
    validation,
    limitType,
    submitting,
    indicatorType,
    riskObject,
    areaOfRisk,
    ratingSelect,
    onBack,
    onCancel,
    onSubmit
  } = props;

  const submitButtonText = 'Create ' + window.riskEntitySettings.indicator_label;
  return (
    <>
      <Modal.Body className="tw-modal-body">
        <h3 style={{marginTop: '-10px'}}>Confirmation</h3>
        <div>
          <IndicatorSummary
            rairedName={rairedName}
            rairedDescription={rairedDescription}
            rairedDefinition={rairedDefinition}
          />
          {indicatorType == 'risk_object_rated' &&
            <RiskObjectSummary
              indicatorType={indicatorType}
              riskObject={riskObject.label}
              ratingSelect={ratingSelect.label}
              areaOfRisk={areaOfRisk.label}
            />
          }
          {indicatorType == 'data_input_rated' &&
            <DataInputSummary
              selectedDired={selectedDired}
              diredName={diredName}
              diredDefinition={diredDefinition}
              rairedName={rairedName}
              diredValueFormatLabel={diredValueFormatLabel}
              diredDecimalPlaces={diredDecimalPlaces}
            />
          }
        </div>
        {enabledQuarterly && indicatorType == 'data_input_rated' &&
          <>
            <h4>Quarterly Rating Benchmarks</h4>
            {limitType.value == 'select' &&
              <SelectDisplay selectOptions={dataInputSelectOptions} />
            }
            {limitType.value == 'ceiling' &&
              <CeilingDisplay benchmarks={validation.formattedQuarterlyBenchmarks} />
            }
            {limitType.value == 'floor' &&
              <FloorDisplay benchmarks={validation.formattedQuarterlyBenchmarks} />
            }
            {limitType.value == 'absolute_value' &&
              <AbsoluteValueDisplay benchmarks={validation.formattedQuarterlyBenchmarks} />
            }
            {limitType.value == 'bidirectional' &&
              <BidirectionalDisplay benchmarks={validation.formattedQuarterlyBenchmarks} />
            }
          </>
        }
        {enabledMonthly && indicatorType == 'data_input_rated' &&
          <>
            <h4>Monthly Rating Benchmarks</h4>
            {limitType.value == 'select' &&
              <SelectDisplay selectOptions={dataInputSelectOptions} />
            }
            {limitType.value == 'ceiling' &&
              <CeilingDisplay benchmarks={validation.formattedMonthlyBenchmarks} />
            }
            {limitType.value == 'floor' &&
              <FloorDisplay benchmarks={validation.formattedMonthlyBenchmarks} />
            }
            {limitType.value == 'absolute_value' &&
              <AbsoluteValueDisplay benchmarks={validation.formattedMonthlyBenchmarks} />
            }
            {limitType.value == 'bidirectional' &&
              <BidirectionalDisplay benchmarks={validation.formattedMonthlyBenchmarks} />
            }
          </>
        }
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-secondary pull-left"
          type="button" disabled={submitting} onClick={onBack}>
          Back
        </button>
        {!submitting &&
          <a className='btn btn-link cancel-link' onClick={onCancel}>Cancel</a>
        }
        <Button onClick={onSubmit} label={submitButtonText} />
      </Modal.Footer>
    </>
  );
}

export default RairedConfirmation;
