import _ from 'lodash';

export function fromParam(timePeriod) {
  if (!timePeriod) {
    return null;
  }
  const [chunkA, chunkB] = timePeriod.split('-');
  if (chunkA.match(/q/i)) {
    return {
      unit: 'quarter',
      year: parseInt(chunkB),
      quarter: parseInt(_.trim(chunkA, 'qQ'))
    };
  } else {
    return {
      unit: 'month',
      year: parseInt(chunkA),
      month: parseInt(chunkB)
    };
  }
}

export function toParam(timePeriod) {
  if (!timePeriod) {
    return null;
  }
  if (timePeriod.unit == 'month') {
    return `${timePeriod.year}-${timePeriod.month}`;
  }
  return `Q${timePeriod.quarter}-${timePeriod.year}`;
}
