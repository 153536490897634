// Wrapper around axios to include Rails CSRF token
// https://github.com/rails/webpacker/issues/1015
import axios from 'axios';
import _ from 'lodash';

const defaultMessage = 'An error has occurred, we apologize for the inconvenience. Please refresh the page and try again.';
const token = document.querySelector('[name="csrf-token"]') || {content: 'no-csrf-token'};
var ax = axios.create({
  headers: {
    common: {
      'X-CSRF-Token': token.content,
      'X-Requested-With': 'XMLHttpRequest', // Identifies as request.xhr? in rails controllers
      Accept: 'application/json'
    }
  }
});
ax.handleError = (error) => {
  const status = _.get(error, 'response.status');
  const redirect = _.get(error, 'response.data.location');
  const message = _.get(error, 'response.data.message', defaultMessage);
  if (status === 302 && redirect) {
    window.location = redirect;
  } else if (status === 401) {
    window.location = '/users/sign_in';
  } else {
    window.dialogs.error({ message });
  }
};

export default ax;
