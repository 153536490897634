import React from 'react';
import _ from 'lodash';
import CalendarInputGroup from 'reporting/section/CalendarInputGroup';
import YearPicker from 'reporting/section/YearPicker';
import QuarterPicker from 'reporting/section/QuarterPicker';
import MonthPicker from 'reporting/section/MonthPicker';
import styles from 'global/styles/TimePeriodPicker';
import timePeriodLoader from 'reporting/section/timePeriodLoader';
import {fromParam, toParam} from 'reporting/section/timePeriodParam';
import monthNames from 'reporting/section/monthNames';

function scalarTimePeriod(timePeriod) {
  if (timePeriod.unit == 'quarter') {
    return timePeriod.year + ((timePeriod.quarter - 1) / 4);
  } else if (timePeriod.unit == 'month') {
    return timePeriod.year + ((timePeriod.month - 1) / 12);
  }
  return undefined;
}

function displayTimePeriod(timePeriod) {
  if (!timePeriod) {
    return undefined;
  } else if (timePeriod.unit == 'quarter') {
    return `Q${timePeriod.quarter}-${timePeriod.year}`;
  } else if (timePeriod.unit == 'month') {
    const month = monthNames[timePeriod.month - 1];
    return `${month} ${timePeriod.year}`;
  }
  return undefined;
}

function displayTimePeriodRange(a, b) {
  if (_.isEqual(a, b)) {
    return displayTimePeriod(a);
  }
  return `${displayTimePeriod(a)} to ${displayTimePeriod(b)}`;
}

const isModified = (property, a, b) => !_.isEqual(a[property], b[property]);

class TimePeriodRangePicker extends React.Component {
  constructor(props) {
    super(props);

    const date = new Date();
    const endTimePeriod = fromParam(props.end_time_period);
    let visibleYear = date.getFullYear();
    if (endTimePeriod) {
      visibleYear = endTimePeriod.year;
    }

    this.state = {
      open: false,
      visibleYear: visibleYear,
      timePeriods: [],
      loading: true,
      error: false,
      startTimePeriod: fromParam(props.start_time_period),
      endTimePeriod: endTimePeriod,
    };
    this.ref = React.createRef();
  }

  componentDidMount() {
    const {start_time_period, end_time_period, onChange} = this.props;
    if (!start_time_period) {
      onChange({start_time_period: window.riskEntitySettings.selected_time_period});
    }

    if (!end_time_period) {
      onChange({end_time_period: window.riskEntitySettings.selected_time_period});
    }

    this.loadTimePeriods();
    document.addEventListener('mousedown', this.handleDocumentMouseDown, false);
  }

  componentDidUpdate(prevProps, prevState) {
    if (isModified('start_time_period', prevProps, this.props)) {
      this.setState({startTimePeriod: fromParam(this.props.start_time_period)});
    }

    if (isModified('end_time_period', prevProps, this.props)) {
      this.setState({endTimePeriod: fromParam(this.props.end_time_period)});
    }

    const rangeIsModified = isModified('startTimePeriod', prevState, this.state) || isModified('endTimePeriod', prevState, this.state);
    if (!this.state.open && rangeIsModified) {
      this.inferVisibleYear();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleDocumentMouseDown, false);
  }

  handleDocumentMouseDown = (e) => {
    const node = this.ref.current;
    const inNode = node && node.contains(e.target);
    if (!inNode) {
      this.close();
    }
  };

  handleTimePeriodClick = (unit) => (n) => {
    const {visibleYear, startTimePeriod, endTimePeriod} = this.state;
    const timePeriod = {unit: unit, year: visibleYear, [unit]: n};
    const timePeriodParam = toParam(timePeriod);
    if (!this.isUnit(unit)) {
      this.props.onChange({
        start_time_period: timePeriodParam,
        end_time_period: timePeriodParam
      });
      return;
    }

    const x = scalarTimePeriod(timePeriod);
    const l = scalarTimePeriod(startTimePeriod);
    const r = scalarTimePeriod(endTimePeriod);

    if (x < l) {
      this.props.onChange({start_time_period: timePeriodParam});
      return;
    } else if (r < x) {
      this.props.onChange({end_time_period: timePeriodParam});
      return;
    }

    this.props.onChange({
      start_time_period: timePeriodParam,
      end_time_period: timePeriodParam
    });
  };

  toggleOpen = () => {
    const {open} = this.state;
    this.setState({open: !open});
    if (this.state.error) {
      // Bust the cache and reload if the user attempts to open in an error state
      timePeriodLoader().catch( () => timePeriodLoader.cache.delete() );
      this.loadTimePeriods();
    }
  };

  inferVisibleYear() {
    const {startTimePeriod, endTimePeriod} = this.state;
    if (!startTimePeriod || !endTimePeriod) {
      return;
    }
    const l = scalarTimePeriod(startTimePeriod);
    const r = scalarTimePeriod(endTimePeriod);
    const visibleYear = _.floor((l + r) / 2);
    this.setState({visibleYear});
  }

  close = () => {
    this.inferVisibleYear();
    this.setState({open: false});
  };

  changeVisibleYear = (year) => {
    this.setState({visibleYear: year});
  };

  timePeriodPredicate = (timePeriod) => {
    const {showQuarters, showMonths} = this.props;
    if (!showQuarters && timePeriod.unit == 'quarter') {
      return false;
    }
    if (!showMonths && timePeriod.unit == 'month') {
      return false;
    }
    return timePeriod.status > 0;
  };

  loadTimePeriods() {
    this.setState({
      loading: true,
      error: false
    });

    timePeriodLoader()
      .then( (res) => {
        const {visibleYear} = this.state;
        const timePeriods = _.filter(res.data.time_periods, this.timePeriodPredicate);
        const years = timePeriods.map(x => x.year);
        const minYear = _.min(years);
        const maxYear = _.max(years);
        if (maxYear && !_.inRange(visibleYear, minYear, maxYear)) {
          this.setState({visibleYear: maxYear});
        }
        this.setState({
          error: false,
          loading: false,
          timePeriods: timePeriods
        });
      }, () => {
        this.setState({
          error: true,
          loading: false,
          timePeriods: []
        });
      });
  }

  isSelected = (unit) => (n) => {
    const {visibleYear, startTimePeriod, endTimePeriod} = this.state;
    return this.isUnit(unit) && _.inRange(
      scalarTimePeriod({unit: unit, year: visibleYear, [unit]: n}),
      scalarTimePeriod(startTimePeriod),
      scalarTimePeriod(endTimePeriod) + 0.001
    );
  };

  isDisabled = (unit) => (n) => {
    const {visibleYear, timePeriods} = this.state;
    return !_.some(timePeriods, _.matches({unit: unit, year: visibleYear, [unit]: n}));
  };

  isUnit(unit) {
    const {startTimePeriod, endTimePeriod} = this.state;
    return startTimePeriod.unit == unit && endTimePeriod.unit == unit;
  }

  render() {
    const {showMonths, showQuarters } = this.props;
    const {error, loading, startTimePeriod, endTimePeriod} = this.state;

    const years = this.state.timePeriods.map( x => x.year );
    return (
      <span ref={this.ref}>
        <CalendarInputGroup error={error} loading={loading} onClick={this.toggleOpen}>
          {displayTimePeriodRange(startTimePeriod, endTimePeriod)}
        </CalendarInputGroup>
        { this.state.open &&
          <nav className={styles.nav}>
            <YearPicker
              value={this.state.visibleYear}
              min={_.min(years)}
              max={_.max(years)}
              onChange={this.changeVisibleYear}
            />
            { showQuarters &&
              <QuarterPicker
                isDisabled={this.isDisabled('quarter')}
                isSelected={this.isSelected('quarter')}
                onClick={this.handleTimePeriodClick('quarter')}
              />
            }
            { showMonths &&
              <MonthPicker
                isDisabled={this.isDisabled('month')}
                isSelected={this.isSelected('month')}
                onClick={this.handleTimePeriodClick('month')}
              />
            }
            <footer>
            </footer>
          </nav>
        }
      </span>
    );
  }
}

export default TimePeriodRangePicker;
