import React from 'react';
import TimePeriodPicker from 'reporting/section/TimePeriodPicker';
import RiskCategoryDefinitionPicker from 'reporting/section/RiskCategoryDefinitionPicker';
import LineOfBusinessPicker from 'reporting/section/LineOfBusinessPicker';
import DepartmentPicker from 'reporting/section/DepartmentPicker';

function RiskOverviewReportAdvanced(props) {
  const {
    timePeriod,
    risk_category_definition_id,
    line_of_business_risk_entity_definition_id,
    department_risk_entity_definition_id,
    onChange
  } = props;

  return (
    <div>
      <div className='form-group'>
        <div>
          <label>Time Period</label>
        </div>
        <div>
          <TimePeriodPicker
            value={timePeriod}
            onChange={(value) => onChange({timePeriod: value})}
            showQuarters
            showMonths={window.riskEntitySettings.monthly_kri}
          />
        </div>
      </div>

      <div className='form-group'>
        <div>
          <label>{window.riskEntitySettings.risk_category_label}</label>
        </div>
        <div>
          <RiskCategoryDefinitionPicker
            timePeriod={timePeriod}
            value={risk_category_definition_id}
            onChange={onChange}
          />
        </div>
      </div>

      <div className='form-group'>
        <div>
          <label>Line of Business</label>
        </div>
        <div>
          <LineOfBusinessPicker
            risk_category_definition_id={risk_category_definition_id}
            value={line_of_business_risk_entity_definition_id}
            onChange={onChange}
          />
        </div>
      </div>

      <div className='form-group'>
        <div>
          <label>{window.riskEntitySettings.department_label}</label>
        </div>
        <div>
          <DepartmentPicker
            risk_category_definition_id={risk_category_definition_id}
            line_of_business_risk_entity_definition_id={line_of_business_risk_entity_definition_id}
            value={department_risk_entity_definition_id}
            onChange={onChange}
          />
        </div>
      </div>
    </div>
  );
}

RiskOverviewReportAdvanced.title = 'Risk Overview Report Section Configuration';

export default RiskOverviewReportAdvanced;
