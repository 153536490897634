import React from 'react';
import PropTypes from 'prop-types';

class Message extends React.Component {
  render() {
    const {
      contents,
      title,
      type,
      isThin,
      noMargin,
    } = this.props;

    var classes = [];
    type ? classes.push(`${type}-alert-message`) : classes.push('info-alert-message');
    isThin ? classes.push('alert-message-thin') : classes.push('alert-message');
    noMargin ? classes.push('no-margin-t') : null;

    return (
      <div className={classes.join(' ')}>
        { title ? <h4>{title}</h4> : null }
        <p>{contents}</p>
      </div>
    );
  }
}

Message.propTypes = {
  contents: PropTypes.string.isRequired,
  title: PropTypes.string,
  type: PropTypes.string,
  isThin: PropTypes.bool,
  noMargin: PropTypes.bool,
};

export default Message;
