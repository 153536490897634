import React from 'react';
import Modal from 'react-bootstrap/Modal';

class DeleteConfirmation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIn: false
    };
  }

  handleEntering = () => {
    this.setState({modalIn: true});
  };

  handleExit = () => {
    this.setState({modalIn: false});
  };

  render() {
    const {show, onConfirm, onHide} = this.props;
    const {modalIn} = this.state;
    const animationClassName = modalIn ? 'in ' : '';
    const deletionModalProps = {
      show: show,
      onEntering: this.handleEntering,
      onHide: onHide,
      onExit: this.handleExit,
      backdropClassName: animationClassName,
      className: animationClassName,
      centered: true,
      animation: true,
      style: {
        zIndex:1040
      }
    };

    return (
      <Modal {...deletionModalProps}>
        <Modal.Header>
          <button className='display-md-inline close' onClick={onHide}>&times;</button>
          <div className='modal-title'>
            <h3>Please Confirm</h3>
          </div>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this reporting package?</p>
        </Modal.Body>
        <Modal.Footer>
          <a className='btn btn-link' onClick={onHide}>
            Cancel
          </a>
          <a className='btn btn-primary' onClick={onConfirm}>
            Confirm
          </a>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default DeleteConfirmation;
