import React from 'react';

function SoftInput(props) {
  const { name, value, onChange } = props;
  return (
    <td className='rating-range'>
      <input className='form-control' type="text" name={name} value={value} onChange={onChange} aria-label='input' />
    </td>
  );
}

export default SoftInput;
