import React from 'react';
import PropTypes from 'prop-types';
import Routes from 'global/routes';
import DataTable from 'global/components/DataTable';
import Toolbar from 'settings/components/Toolbar';
import CategoryRiskRow from 'settings/components/CategoryRiskRow';
import ax from 'global/axios';
import _ from 'lodash';

class RiskCategoryOverridesContainer extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      overrides: [],
      loading: true,
      reordering: false,
      toolbarActions: [
        {
          onClick: this.handleSaveReorderClick,
          iconClass: 'fa fa-save',
          text: 'Save Arrangement',
          visibility: {
            whenReordering: true
          }
        },
        {
          onClick: this.handleCancelReorderClick,
          iconClass: 'fa fa-ban',
          text: 'Cancel',
          visibility: {
            whenReordering: true
          }
        }
      ]
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.getRiskCategoryOverrides();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const overrides = this.state.overrides;
    const loading = this.state.loading;
    return (
      <div className='panel'>
        <div className='panel-heading'>
          <span className='panel-title'>{window.riskEntitySettings.risk_category_label} Configuration</span>
        </div>
        <Toolbar actions={this.state.toolbarActions} isActionVisible={this.toolbarActionVisible} loading={loading} />
        <DataTable
          records={overrides}
          isLoading={loading}
          isCheckable={false}
          isDraggable={true}
          droppableId={'overrides'}
          rowComponent={CategoryRiskRow}
          onDragEnd={this.handleDragEnd}
          columnHeaders={['Name', 'Weight']}
        />
      </div>
    );
  }

  toolbarActionVisible = (key) => {
    const {
      toolbarActions,
      reordering
    } = this.state;
    const visibilityParams = toolbarActions[key].visibility;

    if (!_.has(visibilityParams, 'whenReordering') && reordering) {
      // Short circuit for reordering is occurring.
      return false;
    }

    if (_.has(visibilityParams, 'whenReordering')) {
      return reordering;
    }
  };

  handleSaveReorderClick =  async () => {
    return await this.updateOrder();
  };

  handleCancelReorderClick = async () => {
    return await this.getRiskCategoryOverrides();
  };

  handleDragEnd = (result) => {
    // Dropped outside the table
    if (!result.destination) {
      return;
    }

    const overrides = reorder(
      this.state.overrides,
      result.source.index,
      result.destination.index
    );

    this.setState({ overrides: overrides, reordering: true });
  };

  getRiskCategoryOverrides = async () => {
    let url;

    if (this.props.categoryRoot === 'true') {
      url = Routes.settings_overrides_risk_category_root_definition_overrides_path();
    } else {
      url = Routes.settings_overrides_risk_category_overrides_path();
    }

    return ax({
      url: url,
      method: 'get',
      headers: {
      }
    }).then((res) => {
      if (this._isMounted) {
        this.setState({ overrides: res.data, loading: false, reordering: false });
      }
    }).catch(ax.handleError);
  };

  updateOrder = async () => {
    const orderedKeys = this.state.overrides.map(o => o.id);
    let url;

    if (this.props.categoryRoot === 'true') {
      url = Routes.update_order_settings_overrides_risk_category_root_definition_overrides_path();
    } else {
      url = Routes.update_order_settings_overrides_risk_category_overrides_path();
    }

    return ax({
      url:  url,
      method: 'patch',
      data: {
        category_setting_ids: orderedKeys
      }
    }).then(() => {
      return this.getRiskCategoryOverrides();
    }).catch(ax.handleError);
  };
}

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

RiskCategoryOverridesContainer.propTypes = {
  categoryRoot: PropTypes.string.isRequired
};

export default RiskCategoryOverridesContainer;
