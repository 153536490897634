import React from 'react';
import BidirectionalLayout from 'benchmarks/components/BidirectionalLayout';

function Cell(props) {
  const { ratingSymbol, formattedBenchmark } = props;
  return (
    <div className="form-group">
      <div className="text-center">{ratingSymbol} {formattedBenchmark}</div>
    </div>
  );
}

function BidirectionalDisplay({benchmarks}) {
  return (
    <BidirectionalLayout>
      <Cell ratingSymbol='≤' formattedBenchmark={benchmarks[0]} />
      <Cell ratingSymbol='≥' formattedBenchmark={benchmarks[10]} />
      <Cell ratingSymbol='>' formattedBenchmark={benchmarks[1]} />
      <Cell ratingSymbol='<' formattedBenchmark={benchmarks[9]} />
      <Cell ratingSymbol='>' formattedBenchmark={benchmarks[2]} />
      <Cell ratingSymbol='<' formattedBenchmark={benchmarks[8]} />
      <Cell ratingSymbol='>' formattedBenchmark={benchmarks[3]} />
      <Cell ratingSymbol='<' formattedBenchmark={benchmarks[7]} />
      <Cell ratingSymbol='>' formattedBenchmark={benchmarks[4]} />
      <Cell ratingSymbol='<' formattedBenchmark={benchmarks[6]} />
      <Cell ratingSymbol=''  formattedBenchmark={benchmarks[5]} />
    </BidirectionalLayout>
  );
}

export default BidirectionalDisplay;
