import React from 'react';
import Table from 'benchmarks/components/Table';
import Cell from 'benchmarks/components/Cell';

function SelectDisplay(props) {
  const { selectOptions } = props;

  return (
    <Table>
      <Cell value={selectOptions.very_low} />
      <Cell value={selectOptions.low} />
      <Cell value={selectOptions.moderate} />
      <Cell value={selectOptions.high} />
      <Cell value={selectOptions.very_high} />
    </Table>
  );
}

export default SelectDisplay;
