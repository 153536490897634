import 'url-search-params-polyfill';
import React from 'react';
import Routes from 'global/routes';
import DataTable from 'global/components/DataTable';
import Toolbar from 'settings/components/Toolbar';
import RiskRow from 'settings/components/RiskRow';
import ax from 'global/axios';
import _ from 'lodash';

class RiskAttributeIndicatorsContainer extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    const queryParams = new URLSearchParams(window.location.search);
    this.raredId = queryParams.get('risk_attribute_risk_entity_definition_id').split('-')[0];

    this.state = {
      selectedKeys: new Set(),
      indicators: [],
      columnHeaders: [],
      loading: true,
      reordering: false,
      toolbarActions: [
        {
          onClick: this.handleNewIndicatorClick,
          iconClass: 'fa-plus',
          tooltip: 'Add New Section',
          visibility: {
            minSelections: 0
          }
        },
        {
          onClick: this.handleSaveReorderClick,
          iconClass: 'fa fa-save',
          text: 'Save Arrangement',
          visibility: {
            whenReordering: true
          }
        },
        {
          onClick: this.handleCancelReorderClick,
          iconClass: 'fa fa-ban',
          text: 'Cancel',
          visibility: {
            whenReordering: true
          }
        }
      ]
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.getRiskAttributeIndicators();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const indicators = this.state.indicators;
    const loading = this.state.loading;
    const columnHeaders = this.state.columnHeaders;
    return (
      <div className='panel'>
        <div className='panel-heading'>
          <span className='panel-title'>Risk Attribute Indicators</span>
        </div>
        <Toolbar actions={this.state.toolbarActions} isActionVisible={this.toolbarActionVisible} loading={loading} />
        <DataTable
          records={indicators}
          columnHeaders={columnHeaders}
          isLoading={loading}
          isCheckable={false}
          isDraggable={true}
          droppableId={'indicators'}
          rowComponent={RiskRow}
          onDragEnd={this.handleDragEnd}
        />
      </div>
    );
  }

  toolbarActionVisible = (key) => {
    const {
      selectedKeys,
      toolbarActions,
      reordering
    } = this.state;
    const visibilityParams = toolbarActions[key].visibility;

    if (!_.has(visibilityParams, 'whenReordering') && reordering) {
      // Short circuit for reordering is occurring.
      return false;
    }

    if (_.has(visibilityParams, 'minSelections') && _.has(visibilityParams, 'maxSelections')) {
      return (selectedKeys.size >= visibilityParams.minSelections) && (selectedKeys.size <= visibilityParams.maxSelections);
    } else if (_.has(visibilityParams, 'minSelections')) {
      return (selectedKeys.size >= visibilityParams.minSelections);
    } else if (_.has(visibilityParams, 'whenReordering')) {
      return reordering;
    }
  };

  handleNewIndicatorClick = async () => {
    window.location = Routes.new_settings_overrides_indicator_overrides_risk_attribute_indicator_path({ risk_attribute_risk_entity_definition_id: this.raredId });
  };

  handleSaveReorderClick =  async () => {
    return await this.updateOrder();
  };

  handleCancelReorderClick = async () => {
    return await this.getRiskAttributeIndicators();
  };

  handleDragEnd = (result) => {
    // Dropped outside the table
    if (!result.destination) {
      return;
    }

    const indicators = reorder(
      this.state.indicators,
      result.source.index,
      result.destination.index
    );

    this.setState({ indicators: indicators, reordering: true });
  };

  getRiskAttributeIndicators = async () => {
    return ax({
      url: Routes.settings_overrides_indicator_overrides_risk_attribute_indicators_path(),
      params: { risk_attribute_risk_entity_definition_id: this.raredId },
      method: 'get',
      headers: {
      }
    }).then((res) => {
      if (this._isMounted) {
        this.setState({ indicators: res.data.raireds, columnHeaders: res.data.columnHeaders, loading: false, reordering: false });
      }
    }).catch(ax.handleError);
  };

  updateOrder = async () => {
    const orderedKeys = this.state.indicators.map(i => i.id);
    return ax({
      url: Routes.update_order_settings_overrides_indicator_overrides_risk_attribute_indicators_path(),
      method: 'patch',
      data: {
        risk_attribute_indicator_risk_entity_definition_ids: orderedKeys
      }
    }).then(() => {
      return this.getRiskAttributeIndicators();
    }).catch(ax.handleError);
  };
}

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export default RiskAttributeIndicatorsContainer;
