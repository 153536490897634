import React from 'react';
import _ from 'lodash';
import styles from 'global/styles/TimePeriodPicker';

function Chevron(props) {
  const classes = `fa fa-chevron-${props.direction} ${styles.chevron}`;
  return <i className={classes} aria-hidden="true"></i>;
}

function YearPicker(props) {
  const {min, max, value, onChange} = props;
  const leftEnabled = value > min;
  const leftLinkProps = {
    className: leftEnabled ? null : styles.disabled,
    onClick: leftEnabled ? _.partial(onChange, value - 1) : null
  };
  const rightEnabled = value < max;
  const rightLinkProps = {
    className: rightEnabled ? null : styles.disabled,
    onClick: rightEnabled ? _.partial(onChange, value + 1) : null
  };

  return (
    <header>
      <a {...leftLinkProps}>
        <Chevron direction="left" />
      </a>
      {value}
      <a {...rightLinkProps}>
        <Chevron direction="right" />
      </a>
    </header>
  );
}

export default YearPicker;
