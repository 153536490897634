import React from 'react';
import Modal from 'react-bootstrap/Modal';

class DownloadPending extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIn: false
    };
  }

  handleEntering = () => {
    this.setState({modalIn: true});
  };

  handleExit = () => {
    this.setState({modalIn: false});
  };

  render() {
    const {show, onHide} = this.props;
    const {modalIn} = this.state;
    const animationClassName = modalIn ? 'in ' : '';
    const modalProps = {
      show: show,
      onEntering: this.handleEntering,
      onHide: onHide,
      onExit: this.handleExit,
      backdropClassName: animationClassName,
      className: animationClassName,
      centered: true,
      animation: true,
      backdrop: 'static',
      style: {
        zIndex:1040
      }
    };

    return (
      <Modal {...modalProps}>
        <Modal.Header>
          <button className='display-md-inline close' onClick={onHide}>&times;</button>
          <div className='modal-title'>
            <h3>Downloading...</h3>
          </div>
        </Modal.Header>
        <Modal.Body>
          <i className='fa fa-spinner fa-pulse'></i>
          &nbsp;&nbsp;
          Please wait as your report package is being prepared for download...
        </Modal.Body>
        <Modal.Footer>
          <a className='btn btn-link' onClick={onHide}>
            Cancel
          </a>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default DownloadPending;
