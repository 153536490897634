import React from 'react';
import GlyphiconButton from 'reporting/package/GlyphiconButton';
import _ from 'lodash';

class TextInputForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
      focusCount: 0
    };
    this.input = React.createRef();
    this.blurCancel = false;
  }

  componentDidMount() {
    this.select();
  }

  select = () => {
    const el = this.input.current;
    if (el) {
      el.select();
    }
  };

  empty = () => !_.trim(this.state.value);

  modified = () => {
    return _.trim(this.state.value) != _.trim(this.props.value);
  };

  cancel = () => {
    const {value, disabled, onCancel} = this.props;
    if (!disabled) {
      this.setState({value});
      onCancel();
    }
  };

  submit = () => {
    const {onSubmit} = this.props;
    const {value} = this.state;
    onSubmit(_.trim(value));
  };

  handleKeyDown = (e) => {
    if (e.key == 'Escape') {
      this.cancel();
    }
    if (e.key == 'Enter' && !this.empty() && this.modified()) {
      this.submit();
    }
  };

  handleChange = (e) => {
    this.setState({value: e.currentTarget.value});
  };

  handleMouseDown = () => {
    this.blurCancel = true;
  };

  handleFocus = () => {
    const {focusCount} = this.state;
    this.setState({focusCount: focusCount + 1});
  };

  handleBlur = () => {
    // comparing initial focusCount with later focusCount
    // to cancel blur events where focus is moving with
    // this component.
    const {focusCount} = this.state;
    setTimeout(() => {
      const {onBlur} = this.props;
      if (onBlur && focusCount == this.state.focusCount) {
        onBlur();
      }
    }, 100);
  };

  render() {
    const {disabled, placeholder} = this.props;
    const {value} = this.state;
    const unmodified = !this.modified();
    return (
      <div className='input-group' onFocus={this.handleFocus} onBlur={this.handleBlur}>
        <input
          className='form-control'
          type='text'
          ref={this.input}
          value={value}
          placeholder={placeholder}
          onKeyDown={this.handleKeyDown}
          onChange={this.handleChange}
          disabled={disabled}
        />
        <div className='input-group-btn'>
          <GlyphiconButton
            icon='glyphicon-ok'
            alt='OK'
            disabled={disabled || unmodified || this.empty()}
            onClick={this.submit}
            onMouseDown={this.handleMouseDown}
          />
          <GlyphiconButton
            icon='glyphicon-remove'
            alt='Cancel'
            disabled={disabled}
            onClick={this.cancel}
            onMouseDown={this.handleMouseDown}
          />
        </div>
      </div>
    );
  }
}

export default TextInputForm;
