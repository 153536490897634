import React from 'react';
import PropTypes from 'prop-types';
import styles from 'global/styles/DataTable';
import ax from 'global/axios';
import CheckedDisplay from 'settings/components/CheckedDisplay';
import parse from 'html-react-parser';

class RiskRow extends React.Component {
  render() {
    const record = this.props.record;
    let columnWidth = record.wideWeightColumn ? styles.column15 : styles.column5;

    return (
      <tr
        ref={this.props.provided.innerRef}
        className={styles.draggable}
        {...this.props.provided.draggableProps}
      >
        <td
          className={styles.controlColumn}
          {...this.props.provided.dragHandleProps}
        >
          <span className='grip-handle'></span>
        </td>
        <td><a href={record.href}>{record.name}</a></td>
        { record.weight &&
          <>
            <td className={[styles.leftBorder, columnWidth, styles.alignCenter].join(' ')}>{parse(record.weight)}</td>
          </>
        }
        { record.currentUserCanPromote &&
          <>
            <td className={[styles.leftBorder, styles.column5, styles.alignCenter].join(' ')}><CheckedDisplay checked={record.global} /></td>
            <td className={[styles.leftBorder, styles.column5, styles.alignCenter].join(' ')}>
              { !record.global && record.dataInputRated &&
              <button
                onClick={() => {
                  const confirmBox = window.confirm(
                    'Do you really want to add to the Global Library?'
                  );
                  if (confirmBox === true) {
                    this.promoteToGlobalLibrary(record);
                  }
                }}>
                <i className='fa fa-upload'></i>
              </button> }
            </td>
          </>}
      </tr>
    );
  }

  promoteToGlobalLibrary = (record) => {
    return ax({
      url: record.promotionHref,
      params: { },
      method: 'get',
      headers: { }
    }).then((_res) => {
      window.location.reload();
    }).catch(ax.handleError);
  };
}

RiskRow.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    global: PropTypes.bool,
    dataInputRated: PropTypes.bool,
    href: PropTypes.string,
    promotionHref: PropTypes.string,
    weight: PropTypes.string,
  })
};

export default RiskRow;
