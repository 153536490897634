import React from 'react';
import Select from 'react-select';
import _ from 'lodash';
import TimePeriodPicker from 'reporting/section/TimePeriodPicker';
import RiskCategoryPicker from 'reporting/section/RiskCategoryPicker';

const riskComponentTypeOptions = [
  {
    value: 0,
    label: 'Inherent Risk'
  }, {
    value: 1,
    label: 'Quality of Risk Management'
  }
];

function HeatMapReportAdvanced(props) {
  const {
    timePeriod,
    risk_category_id,
    risk_component_type,
    onChange
  } = props;

  const selecteRiskComponentType = _.find(riskComponentTypeOptions, _.matchesProperty('value', risk_component_type));
  const handleRiskComponentTypeChange = (option) => onChange({risk_component_type: _.get(option, 'value', null)});

  return (
    <div>
      <div className='form-group'>
        <div>
          <label>Time Period</label>
        </div>
        <div>
          <TimePeriodPicker
            value={timePeriod}
            onChange={(value) => onChange({timePeriod: value})}
            showQuarters
            showMonths={window.riskEntitySettings.monthly_kri}
          />
        </div>
      </div>

      <div className='form-group'>
        <div>
          <label>{window.riskEntitySettings.risk_category_label}</label>
        </div>
        <div>
          <RiskCategoryPicker
            timePeriod={timePeriod}
            value={risk_category_id}
            onChange={onChange}
          />
        </div>
      </div>

      <div className='form-group'>
        <div>
          <label>Risk Component</label>
        </div>
        <div>
          <Select
            isClearable={true}
            options={riskComponentTypeOptions}
            value={selecteRiskComponentType}
            onChange={handleRiskComponentTypeChange}
          />
        </div>
      </div>
    </div>
  );
}

HeatMapReportAdvanced.title = 'Heat Map Report Section Configuration';

export default HeatMapReportAdvanced;
