import React from 'react';
import ReactDOM from 'react-dom';

class Portal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      el: undefined
    };
  }

  findTarget = () => {
    const {target_id} = this.props;
    const el = document.getElementById(target_id);
    this.setState({el});
  };

  handleReplaced = () => {
    this.findTarget();
  };

  componentDidMount() {
    this.findTarget();

    document.addEventListener('ajax:replaced', this.handleReplaced);
  }

  componentWillUnmount() {
    document.removeEventListener('ajax:replaced', this.handleReplaced);
  }

  render() {
    const {children} = this.props;
    const {el} = this.state;
    if (el) {
      return ReactDOM.createPortal(children, el);
    } else {
      return null;
    }
  }
}

export default Portal;
