/*eslint no-unused-vars: ["error", { "varsIgnorePattern": "React" }]*/
import React from 'react';

function Missing() {
  return (
    <>Section missing or misconfigured</>
  );
}

export default Missing;
