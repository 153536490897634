import React from 'react';
import Modal from 'react-bootstrap/Modal';
import styles from '../styles/RairedModal';
import ax from 'global/axios';
import _ from 'lodash';

function SearchForm(props) {
  return (
    <form className='simple_form'>
      <div className="form-group">
        <label htmlFor='search' className='control-label string'>
          Search {window.riskEntitySettings.indicator_label} Name
        </label>
        <input
          className="form-control"
          type="text"
          name='search'
          id='search'
          value={props.term}
          onChange={props.onChange}
        />
      </div>
    </form>
  );
}

function SearchResults(props) {
  return (
    <>
      <h4>Results</h4>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>Attribute</th>
            <th>{window.riskEntitySettings.indicator_label}</th>
          </tr>
        </thead>
        <tbody>
          {props.children}
        </tbody>
      </table>
    </>
  );
}

function SearchResult(props) {
  return (
    <tr>
      <td className="col-md-6">{props.raredName}</td>
      <td className="col-md-6"><a href={`/settings/overrides/indicator_overrides/risk_attribute_indicator_risk_entity_definitions/${props.id}/edit`}>{props.rairedName}</a></td>
    </tr>
  );
}

class RiskAttributeIndicatorSearchContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      modalIn: false, // Workaround to fix animation transition
      search: '',
      raireds: []
    };

    this.debouncedSearch = _.debounce(this.search, 250);
  }

  search = (term) => {
    ax({
      url: '/settings/overrides/indicator_overrides/search',
      params: {q: term},
      method: 'get',
    }).then((res) => {
      this.setState({raireds: res.data});
    }).catch(error => {
      this.handleModalExit();
      ax.handleError(error);
    });
  };

  handleSearchChange = (event) => {
    const search = event.target.value;
    this.setState({search});
    this.debouncedSearch(search);
  };

  handleShowModal = (e) => {
    e.preventDefault();
    this.setState({modalOpen: true});
  };

  handleCancelClick = (e) => {
    e.preventDefault();
    this.setState({modalIn: false});
  };

  handleModalEntering = () => {
    this.setState({modalIn: true});
  };

  handleModalExit = () => {
    this.setState({modalIn: false});
  };

  handleModalHide = () => {
    this.setState({modalOpen: false});
  };

  render() {
    const { modalOpen, search, raireds } = this.state;
    const animationClassName = this.state.modalIn ? 'in ' : '';
    const makeAnimationWork = {
      onEntering: this.handleModalEntering,
      onExit: this.handleModalExit,
      backdropClassName: animationClassName,
      className: animationClassName + styles.modal
    };

    return (
      <div>
        <button className='btn-sm btn-primary' style={{marginTop: '3px', marginRight: '15px'}} onClick={this.handleShowModal}>
          <i className='fa fa-search'>&nbsp;</i>
          Search {window.riskEntitySettings.plural_indicator_label}
        </button>
        <Modal centered animation
          style={{zIndex:1040}}
          dialogClassName={styles.kriWizardModal}
          show={modalOpen}
          onHide={this.handleModalHide}
          {...makeAnimationWork}
        >
          <Modal.Header>
            <button className='display-md-inline close' onClick={this.handleCancelClick}>&times;</button>
            <h4 className='modal-title'>Search {window.riskEntitySettings.plural_indicator_label}</h4>
          </Modal.Header>
          <Modal.Body>
            <div>
              <SearchForm
                term={search}
                onChange={this.handleSearchChange}
              />
              <SearchResults>
                {_.isEmpty(raireds) ?
                  <tr>
                    <td colSpan="2">No Results</td>
                  </tr> :
                  raireds.map(raired => (
                    <SearchResult
                      key={raired.rairedID}
                      id={raired.rairedID}
                      rairedName={raired.rairedName}
                      raredName={raired.raredName}
                    />
                  ))}
              </SearchResults>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default RiskAttributeIndicatorSearchContainer;
