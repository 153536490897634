import React from 'react';
import PropTypes from 'prop-types';
import ToolbarAction from 'settings/components/ToolbarAction';
import styles from 'settings/styles/Toolbar';

class Toolbar extends React.Component {
  render() {
    return (
      <div className='btn-group' id={styles.toolbar} role='group' aria-label='actions'>
        {this.props.actions.map((action, index) => (this.isActionVisible(index) && <ToolbarAction key={index} action={action} loading={this.props.loading} />) )}
      </div>
    );
  }

  isActionVisible = (key) => {
    if (this.props.isActionVisible) {
      return this.props.isActionVisible(key);
    } else {
      return true;
    }
  };
}

Toolbar.propTypes = {
  actions: PropTypes.arrayOf(ToolbarAction.shape()),
  isActionVisible: PropTypes.func,
  loading: PropTypes.bool
};

export default Toolbar;
