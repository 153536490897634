import React from 'react';
import {Link} from 'react-router-dom';
import ax from 'global/axios';
import _ from 'lodash';
import Breadcrumbs from 'global/components/Breadcrumbs';
import GlyphiconButton from 'reporting/package/GlyphiconButton';
import TextInputForm from 'reporting/package/TextInputForm';
import DeleteConfirmation from 'reporting/package/DeleteConfirmation';
import DownloadPending from 'reporting/package/DownloadPending';
import OrientationButtons from 'reporting/package/OrientationButtons';
import Sections from 'reporting/Sections';

class PackageEditor extends React.Component {
  constructor(props) {
    super(props);

    const id = _.get(props, 'match.params.id');
    const url = `/reporting/packages/${id}`;
    this.state = {
      id: id,
      url: url,
      debug: false, /* Only enabled via React Dev Tools */
      busy: false,
      renaming: false,
      modified: false,
      loading: false,
      error: null,
      name: '',
      orientation: 'portrait',
      sections: [],
      addedSectionId: null,
      deletionModalOpen: false,
      waitingForDownload: false
    };
  }

  componentDidMount() {
    this.load();
  }

  componentWillUnmount() {
    clearInterval(this.pollId);
  }

  load = () => {
    const {url} = this.state;
    this.setState({loading: true});
    ax({
      url: url,
      method: 'get'
    })
      .then( (res) => {
        this.setState({
          name: res.data.name,
          orientation: res.data.orientation,
          sections: res.data.sections,
          loading: false,
          error: null
        });
      }, (error) => {
        this.setState({error: error, loading: false});
      });
  };

  addSection = (e) => {
    e.preventDefault();
    const {sections} = this.state;
    const name = e.currentTarget.name;
    const maxId = _.max(_.map(sections, _.property('id'))) || 0;
    const newId = maxId + 1;

    setTimeout(() => {
      if (this.state.addedSectionId == newId) {
        this.setState({addedSectionId: null});
      }
    }, 1000);

    this.setState({
      addedSectionId: newId,
      modified: true,
      sections: _.concat(sections, {
        id: newId,
        report: name
      })
    });
  };

  handleDestroyClick = () => {
    this.setState({deletionModalOpen: true});
  };

  handleDestroyCancel = () => {
    this.setState({deletionModalOpen: false});
  };

  handleDestroyConfirm = () => {
    const {url} = this.state;
    this.setState({
      busy: true,
      deletionModalOpen: false
    });
    ax.delete(url).then(() => {
      window.location = '/reporting/packages';
    }, ax.handleError);
  };

  beginRename = () => {
    this.setState({renaming: true});
  };

  handleRenameCancel = () => {
    this.setState({renaming: false});
  };

  handleRename = (name) => {
    this.setState({
      name: name,
      modified: true,
      renaming: false
    });
  };

  handleSectionsChange = (sections) => {
    this.setState({
      modified: true,
      sections: sections
    });
  };

  handleOrientationChange = (orientation) => {
    this.setState({
      modified: true,
      orientation: orientation
    });
  };

  handleDownload = () => {
    this.setState({waitingForDownload: true});
    const {id} = this.state;
    ax({
      url: `/reporting/packages/${id}/generate`,
      method: 'post'
    }).then( () => {
      this.pollId = setInterval(this.poll, 5000);
    }, (error) => {
      this.setState({waitingForDownload: false});
      ax.handleError(error);
    });
  };

  handleDownloadCancel = () => {
    clearInterval(this.pollId);
    this.setState({waitingForDownload: false});
  };

  poll = () => {
    const {url} = this.state;
    ax({
      url: url,
      method: 'get'
    }).then( (res) => {
      const {document_id, ready_for_download, error} = res.data;
      if (error) {
        clearInterval(this.pollId);
        this.setState({waitingForDownload: false});
        ax.handleError(error);
      } else if (ready_for_download && document_id) {
        clearInterval(this.pollId);
        this.setState({waitingForDownload: false});
        const url = `/documents/${document_id}/download`;
        window.location = url;
      }
    }, (error) => {
      clearInterval(this.pollId);
      this.setState({waitingForDownload: false});
      ax.handleError(error);
    });
  };

  serialize() {
    const {id} = this.props;
    const {name, orientation, sections} = this.state;
    return {
      id: id,
      name: name,
      orientation: orientation,
      sections: sections
    };
  }

  save = () => {
    const {id} = this.state;
    this.setState({busy: true});
    ax({
      url: `/reporting/packages/${id}`,
      method: 'put',
      data: this.serialize()
    })
      .then(() => {
        this.setState({modified: false, busy: false});
      }, () => {
        this.setState({
          busy: false,
          modified: false
        });
        window.dialogs.error({ message: 'Package name cannot include these characters: ? * : \\ | / < > ¥' });
      });
  };

  render() {
    const {
      loading,
    } = this.props;

    const {
      debug,
      name,
      orientation,
      sections,
      addedSectionId,
      busy,
      renaming,
      modified,
      deletionModalOpen,
      waitingForDownload
    } = this.state;

    const saveIcon = (modified || loading || busy) ? 'glyphicon-floppy-disk' : 'glyphicon-floppy-saved';
    let saveText = 'Save';
    if (busy) {
      saveText = 'Saving...';
    } else if (!modified) {
      saveText = 'Saved';
    }

    return (
      <div className="panel" style={{marginBottom: '150px'}}>
        <Breadcrumbs>
          <li><a href='/reporting/profile_report'>Report</a></li>
          <li><Link to='/reporting/packages'>Reporting Packages</Link></li>
          <li>{name}</li>
        </Breadcrumbs>

        <div className='panel-heading clearfix'>
          <span className='panel-title col-lg-8 col-md-7' style={{lineHeight: '2em'}}>
            { renaming &&
              <TextInputForm
                placeholder='Unnamed Package'
                value={name}
                disabled={loading || busy}
                onSubmit={this.handleRename}
                onCancel={this.handleRenameCancel}
                onBlur={this.handleRenameCancel}
              />
            }
            { !renaming &&
              name
            }
          </span>
          <div className='btn-toolbar pull-right'>
            <div className='btn-group'>
              <GlyphiconButton
                icon={saveIcon}
                text={saveText}
                alt='Save'
                tooltip='Save'
                disabled={!modified || loading || busy}
                style={{width: '7em'}}
                onClick={this.save}
              />
            </div>
            <div className='btn-group'>
              <GlyphiconButton
                icon='glyphicon-download-alt'
                alt='Download'
                tooltip='Download'
                disabled={modified || loading || busy}
                onClick={this.handleDownload}
              />
            </div>
            <OrientationButtons
              value={orientation}
              onChange={this.handleOrientationChange}
            />
            <div className='btn-group'>
              <GlyphiconButton
                icon='glyphicon-pencil'
                alt='Rename'
                tooltip='Rename'
                disabled={loading || busy}
                onClick={this.beginRename}
              />
              <GlyphiconButton
                disabled={loading || busy}
                icon='glyphicon-trash'
                alt='Delete'
                tooltip='Delete'
                onClick={this.handleDestroyClick}
              />
            </div>
            <div className='btn-group'>
              <button
                className='btn btn-outline dropdown-toggle'
                disabled={loading || busy}
                data-toggle='dropdown'
                aria-haspopup={true}
                aria-expanded={false}
              >
                <span className='glyphicon glyphicon-plus' style={{pointerEvents: 'none'}}></span>
                <span className='sr-only'>Add Section</span>
              </button>
              <ul className="dropdown-menu dropdown-menu-right">
                { debug &&
                  <li>
                    <a href='#' name='DebugAdjustableHeightPlaceholderReport' onClick={this.addSection}>
                      Adjustable Height Placeholder
                    </a>
                  </li>
                }
                <li><a href='#' name='ProfileReport' onClick={this.addSection}>Profile Report</a></li>
                <li><a href='#' name='ProfileTrendReport' onClick={this.addSection}>Profile Trend Report</a></li>
                <li><a href='#' name='SummaryReport' onClick={this.addSection}>Summary Report</a></li>
                <li><a href='#' name='DomainDetailReport' onClick={this.addSection}>Domain Detail Report</a></li>
                <li><a href='#' name='DomainSummaryReport' onClick={this.addSection}>Domain Summary Report</a></li>
                <li><a href='#' name='KRIReport' onClick={this.addSection}>KRI Report</a></li>
                <li><a href='#' name='KRITrendReport' onClick={this.addSection}>KRI Trend Report</a></li>
                <li><a href='#' name='RiskAppetiteReport' onClick={this.addSection}>Risk Appetite Report</a></li>
                <li><a href='#' name='WeightJustificationReport' onClick={this.addSection}>Weight Justification Report</a></li>
                <li><a href='#' name='RiskOverviewReport' onClick={this.addSection}>Risk Overview Report</a></li>
                <li><a href='#' name='HeatMapReport' onClick={this.addSection}>Heat Map Report</a></li>
              </ul>
            </div>
          </div>
        </div>
        { loading && _.isEmpty(sections) &&
          <div className="panel-body">
            <i className='fa fa-spinner fa-pulse'></i> Loading...
          </div>
        }
        { !loading && _.isEmpty(sections) &&
          <div className="panel-body">
            No Reporting Sections have been added
          </div>
        }
        <Sections
          loading={loading}
          addedSectionId={addedSectionId}
          sections={sections}
          onChange={this.handleSectionsChange}
        />
        <DownloadPending
          show={waitingForDownload}
          onHide={this.handleDownloadCancel}
        />
        <DeleteConfirmation
          show={deletionModalOpen}
          onHide={this.handleDestroyCancel}
          onConfirm={this.handleDestroyConfirm}
        />
      </div>
    );
  }
}

export default PackageEditor;
