/*eslint no-unused-vars: ["error", { "varsIgnorePattern": "React" }]*/
import React from 'react';

function DebugAdjustableHeightPlaceholderReport(props) {
  const {height, onChange} = props;
  return (
    <div className='row'>
      <div className='col-md-4'>
        <h4>Adjustable Height Placeholder</h4>
      </div>
      <div className='col-lg-4 col-md-5 hidden-sm hidden-xs'>
        <input
          type='text'
          placeholder='height'
          value={height || ''}
          onChange={ (e) => onChange({height: e.target.value}) }
        />
      </div>
    </div>
  );
}

export default DebugAdjustableHeightPlaceholderReport;
