import React from 'react';
import Routes from 'global/routes';
import RiskEntityMenuItem from 'settings/components/RiskEntityMenuItem';
import '../styles/RiskEntityMenu.scss';
import ax from 'global/axios';

class RiskEntitiesMenu extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    const {count, maxCount, searchable, searchedRiskEntities, riskEntities, viewAllRiskEntitiesUrl, riskEntitiesLabel, riskEntityGroups, viewAllRiskEntityGroupsUrl, riskEntityGroupsLabel, enterpriseRiskProfiles, viewAllEnterpriseRiskProfilesUrl, enterpriseRiskProfilesLabel, rcsaEnabled} = props.states;

    this.state = {
      keyword: '',
      count: count,
      maxCount: maxCount,
      typingTimeout: 0,
      searching: false,
      searchable: searchable,
      searchedRiskEntities: searchedRiskEntities,
      riskEntities: riskEntities,
      viewAllRiskEntitiesUrl: viewAllRiskEntitiesUrl,
      riskEntitiesLabel: riskEntitiesLabel,
      riskEntityGroups: riskEntityGroups,
      viewAllRiskEntityGroupsUrl: viewAllRiskEntityGroupsUrl,
      riskEntityGroupsLabel: riskEntityGroupsLabel,
      enterpriseRiskProfiles: enterpriseRiskProfiles,
      viewAllEnterpriseRiskProfilesUrl: viewAllEnterpriseRiskProfilesUrl,
      enterpriseRiskProfilesLabel: enterpriseRiskProfilesLabel,
      recentText: '',
      searchLink: '',
      rcsaEnabled: rcsaEnabled
    };
  }

  getRiskEntities = async (name = '') => {
    let url = Routes.search_risk_entities_path({name: name});

    return ax({
      url: url,
      method: 'get'
    }).then((res) => {
      if (this._isMounted) {
        const {maxCount, keyword} = this.state;
        let data = res.data;
        let count = data.count;
        let countChange = count !== maxCount && keyword !== '';
        this.setState({
          count: count,
          searchedRiskEntities: data.riskEntities,
          recentText: countChange ? `${count} FOUND` : '',
          searchLink: countChange && count > 5 ? this.displaySearchLink(count, keyword) : ''
        });
      }
    }).catch(ax.handleError);
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleSearchInput = (e) => {
    if (e.key === 'Enter') {
      clearTimeout(this.state.typingTimeout);
      this.handleSubmit();
    } else {
      if (this.state.typingTimeout) {
        clearTimeout(this.state.typingTimeout);
      }
      this.setState({
        keyword: e.target.value,
        typingTimeout: setTimeout(() => { this.handleSubmit(); }, 1000)
      });
    }
  };

  handleSubmit = () => {
    this.setState({ recentText: 'SEARCHING...', searchLink: '' });
    this.getRiskEntities(this.state.keyword);
  };

  displaySearchLink = (count, keyword) => {
    return (
      <>
        <span className="view-search-results">
          (<a className="view-search-link" href={`/risk_entities?filter[name]=${keyword}`}>View All {count}</a>)
        </span>
      </>
    );
  };

  render() {
    const {searchable, searchedRiskEntities, riskEntities, viewAllRiskEntitiesUrl, riskEntitiesLabel, riskEntityGroups, viewAllRiskEntityGroupsUrl, riskEntityGroupsLabel, enterpriseRiskProfiles, viewAllEnterpriseRiskProfilesUrl, enterpriseRiskProfilesLabel, recentText, searchLink, rcsaEnabled} = this.state;

    return (
      <>
        {
          searchable && (
            <>
              <form className="searchBar" onSubmit={e => { e.preventDefault(); }}>
                <input autoFocus autoComplete="off" type="text" onKeyUp={e => this.handleSearchInput(e)} />
                <i className="fa fa-search" onClick={this.handleSubmit} />
              </form>
              <h4>{recentText}{searchLink}</h4>
              <hr />
            </>

          )
        }
        {
          searchedRiskEntities && searchedRiskEntities.length > 0 && (
            <>
              {
                searchedRiskEntities.map(searchedRiskEntity => {
                  return (
                    <RiskEntityMenuItem key={searchedRiskEntity.id} riskEntity={searchedRiskEntity} method="post" />
                  );
                })
              }
              <hr />
            </>
          )
        }
        {
          (
            <>
              <li className="risk-entity-menu-item dropdown-submenu">
                <a href="/" className="dropdown-toggle" data-toggle="dropdown">Recent {riskEntitiesLabel}</a>
                <ul className="dropdown-menu">
                  <hr />
                  {
                    riskEntities && riskEntities.map(riskEntity => {
                      return (
                        <RiskEntityMenuItem key={riskEntity.id} riskEntity={riskEntity} method="post" />
                      );
                    })
                  }
                  <hr />
                  <RiskEntityMenuItem key="view_all" riskEntity={{id: 'view_all', name: 'VIEW ALL ' + riskEntitiesLabel.toUpperCase(), url: viewAllRiskEntitiesUrl}} />
                </ul>
              </li>
            </>
          )
        }
        {
          riskEntityGroups && riskEntityGroups.length > 0 && (
            <>
              <hr />
              <li className="risk-entity-menu-item dropdown-submenu">
                <a href="/" className="dropdown-toggle" data-toggle="dropdown">Recent {riskEntityGroupsLabel}</a>
                <ul className="dropdown-menu">
                  <hr />
                  {
                    riskEntityGroups.map(riskEntityGroup => {
                      return (
                        <RiskEntityMenuItem key={riskEntityGroup.id} riskEntity={riskEntityGroup} method="post" />
                      );
                    })
                  }
                  <hr />
                  <RiskEntityMenuItem key="view_all" riskEntity={{id: 'view_all', name: 'VIEW ALL ' + riskEntityGroupsLabel.toUpperCase(), url: viewAllRiskEntityGroupsUrl}} />
                </ul>
              </li>
            </>
          )
        }
        {
          enterpriseRiskProfiles && enterpriseRiskProfiles.length > 0 && (
            <>
              <hr />
              <li className="risk-entity-menu-item dropdown-submenu">
                <a href="/" className="dropdown-toggle" data-toggle="dropdown">Recent {enterpriseRiskProfilesLabel}</a>
                <ul className="dropdown-menu">
                  <hr />
                  {
                    enterpriseRiskProfiles.map(enterpriseRiskProfile => {
                      return (
                        <RiskEntityMenuItem key={enterpriseRiskProfile.id} riskEntity={enterpriseRiskProfile} method="post" />
                      );
                    })
                  }
                  <hr />
                  <RiskEntityMenuItem key="view_all" riskEntity={{id: 'view_all', name: 'VIEW ALL ' + enterpriseRiskProfilesLabel.toUpperCase(), url: viewAllEnterpriseRiskProfilesUrl}} />
                </ul>
              </li>
            </>
          )
        }
        {
          rcsaEnabled && (
            <>
              <hr />
              <li className="risk-entity-menu-item">
                <a href="/rcsa/groups">RCSA</a>
              </li>
            </>
          )
        }
      </>
    );
  }
}

export default RiskEntitiesMenu;
