export const RaidParams = (props) => {
  let name = '';
  if (props.name) {
    name = props.name.trim();
  } else if (props.raidName) {
    name = props.raidName.trim();
  } else {
    name = null;
  }

  let didID = null;
  if (props.selectedDataInput) {
    didID = props.selectedDataInput['value'];
  }

  let limitType = '';
  if (typeof props.limitType === 'string') {
    limitType = props.limitType;
  } else if (typeof props.limitType === 'object') {
    limitType = props.limitType.value;
  } else {
    limitType = null;
  }

  if (limitType == 'select') {
    const selectBenchmarks = [1.0, 2.0, 3.0, 4.0, 5.0, 6.0, null];
    if (props.enabledQuarterly) {
      props.quarterlyBenchmarks = selectBenchmarks;
    }
    if (props.enabledMonthly) {
      props.monthlyBenchmarks = selectBenchmarks;
    }
  }

  return {
    data_input_definition_id: didID,
    risk_attribute_definition_id: props.attributeId,
    name: name,
    description: props.description,
    regulatory_definition: props.definition,
    limit_type: limitType,
    enabled_quarterly: props.enabledQuarterly,
    enabled_monthly: props.enabledMonthly,
    monthly_benchmarks: props.monthlyBenchmarks,
    quarterly_benchmarks: props.quarterlyBenchmarks
  };
};
