import React from 'react';
import Select from 'react-select';
import ax from 'global/axios';
import _ from 'lodash';

const loader = _.memoize( (risk_category_definition_id, line_of_business_risk_entity_definition_id) => {
  return ax({
    url: '/risk_category_definitions/department_risk_entity_definitions',
    method: 'post', // Note: post is inconsistent with ReST
    data: {
      risk_category_definition_ids: [risk_category_definition_id],
      line_of_business_risk_entity_definition_id: line_of_business_risk_entity_definition_id
    }
  });
});

class DepartmentPicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
      loading: false,
      error: null
    };
  }

  load() {
    const {
      risk_category_definition_id,
      line_of_business_risk_entity_definition_id
    } = this.props;

    this.setState({loading: true});
    loader(risk_category_definition_id, line_of_business_risk_entity_definition_id)
      .then( (res) => {
        const depts = res.data.department_risk_entity_definitions;
        this.setState({
          loading: false,
          error: null,
          options: depts.map( x => ({value: x.id, label: x.name}) )
        });
      }, (error) => {
        this.setState({
          loading: false,
          options: [],
          error: error
        });
      });
  }

  noOptionsMessage = () => {
    const {error} = this.state;
    if (error) {
      return 'Error loading ' + window.riskEntitySettings.plural_department_label;
    } else {
      return 'No ' + window.riskEntitySettings.plural_department_label + ' Available';
    }
  };

  selectedOption() {
    const {value} = this.props;
    const {options} = this.state;
    const found = _.find(options, _.matchesProperty('value', value));
    if (found) {
      return found;
    } else {
      // Returning undefined directly from _.find doesn't clear visible selection reliably
      return null;
    }
  }

  handleChange = (option) => {
    const value = _.get(option, 'value', null);
    this.setState({value});
    this.props.onChange({department_risk_entity_definition_id: value});
  };

  handleMenuOpen = () => {
    const {risk_category_definition_id, line_of_business_risk_entity_definition_id} = this.props;
    const {error} = this.state;
    if (error) {
      loader(risk_category_definition_id, line_of_business_risk_entity_definition_id)
        .catch( () => {
          loader.cache.delete(risk_category_definition_id, line_of_business_risk_entity_definition_id);
        } );
      this.load();
    }
  };


  componentDidMount() {
    this.load();
  }

  componentDidUpdate(prevProps, prevState) {
    const {value, onChange} = this.props;
    const {options} = this.state;

    const categoryChanged = this.props.risk_category_definition_id != prevProps.risk_category_definition_id;
    const lobChanged = this.props.line_of_business_risk_entity_definition_id != prevProps.line_of_business_risk_entity_definition_id;

    if (categoryChanged || lobChanged) {
      this.load();
    }

    if (options != prevState.options) {
      const found = this.selectedOption();
      // Clear selection when not in available options
      if (value && !found) {
        onChange({department_risk_entity_definition_id: null});
      }
    }
  }

  render() {
    const {loading, options} = this.state;

    const selectProps = {
      options: options,
      value: this.selectedOption(),
      isDisabled: false,
      isLoading: loading,
      isClearable: true,
      placeholder: window.riskEntitySettings.department_label,
      noOptionsMessage: this.noOptionsMessage,
      onChange: this.handleChange,
      onMenuOpen: this.handleMenuOpen
    };

    return (
      <Select {...selectProps} />
    );
  }
}

export default DepartmentPicker;
