/* global $, Ladda */
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Select from 'react-select';

import ax from 'global/axios';
import styles from '../styles/CallReportsSyncButton';
import _ from 'lodash';

class CallReportsSyncButton extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      modalIn: false, // Workaround to fix animation transition
      callReports: [],
      startIndex: 0,
      endIndex: 0,
      syncingCallReportIDs: [],
      timeoutId: undefined
    };
  }

  render() {
    const { modalOpen } = this.state;
    const animationClassName = this.state.modalIn ? 'in ' : '';
    const makeAnimationWork = {
      onEntering: this.handleModalEntering,
      onExit: this.handleModalExit,
      backdropClassName: animationClassName,
      className: animationClassName + styles.modal
    };

    const isLoading = this.state.callReports.length == 0;
    const isDisabled = isLoading || this.state.syncingCallReportIDs.length > 0;
    const sharedSelectAttributes = {
      options: this.selectOptions(),
      isDisabled: isDisabled,
      isLoading: isLoading
    };

    return (
      <div>
        <button id={styles.sync} className='btn btn-primary' onClick={this.handleSyncClick}>
          Sync Call Reports
        </button>
        <Modal centered animation
          show={modalOpen}
          onHide={this.handleModalHide}
          {...makeAnimationWork}
        >
          <Modal.Header closeButton>
            <Modal.Title className={styles.modalTitle}>Sync Call Reports</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>Select a range of reporting periods to sync.  Both the start and end reporting period will be imported.</p>
            <label className={styles.label}>
              Start
              <Select {...sharedSelectAttributes} value={this.startSelectValue()} onChange={this.handleStartChange} />
            </label>
            <label className={styles.label}>
              End
              <Select {...sharedSelectAttributes} value={this.endSelectValue()} onChange={this.handleEndChange} />
            </label>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleCancelClick}>Cancel</Button>
            <Button variant="primary" onClick={this.handleConfirmClick} disabled={isDisabled}>Sync</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  selectOptions = () => {
    const { callReports } = this.state;
    return callReports.map((callReport, index) => {
      return {value: callReport.id, label: callReport.time_period_name, index: index};
    });
  };

  startSelectValue = () => _.nth(this.selectOptions(), this.state.startIndex);
  endSelectValue = () =>  _.nth(this.selectOptions(), this.state.endIndex);

  handleSyncClick = (e) => {
    e.preventDefault();
    this.updateCallReports();
    this.setState({modalOpen: true});
  };

  handleCancelClick = (e) => {
    e.preventDefault();
    this.setState({modalOpen: false});
  };

  handleModalEntering = () => {
    this.setState({modalIn: true});
  };

  handleModalExit = () => {
    this.setState({modalIn: false});
    this.cancelPolling();
  };

  handleModalHide = () => {
    this.cancelPolling();
    this.setState({modalOpen: false});
  };

  updateCallReports() {
    ax.get('/settings/call_reports/sync_statuses')
      .catch(this.handleAjaxError.bind(this))
      .then((response) => {
        this.setState({callReports: response.data});
      });
  }

  handleConfirmClick = (e) => {
    e.preventDefault();
    const $primaryButton = $('.modal-footer .btn-primary');
    const ladda = Ladda.create($primaryButton.get(0));
    ladda.start();

    // This is not super intuitive, startIndex is higher than endIndex because the call report ordering is descending in time
    const selectedCallReports = _.slice(this.state.callReports, this.state.endIndex, this.state.startIndex+1);
    const selectedCallRepordIDs = _.map(selectedCallReports, 'id');
    this.setState({syncingCallReportIDs: selectedCallRepordIDs});

    ax.post('/settings/call_reports/multi_sync', {ids: selectedCallRepordIDs})
      .then((response) => {
        this.setState({syncingCallReportIDs: response.data.ids});
        this.beginPolling();
      })
      .catch(this.handleAjaxError.bind(this));
  };

  handleAjaxError(error) {
    if (error.response.status == 406) {
      // Error message or password prompt is shown when page reloads
      window.location.reload(true);
    } else {
      this.setState({modalOpen: false});
      ax.handleError(error);
    }
  }

  callReportIndex(id) {
    return _.findIndex(this.state.callReports, {id: id});
  }

  callReportID(index) {
    const {callReports} = this.state;
    const callReport = callReports[index] || {};
    return callReport.id;
  }

  handleStartChange = (option) => {
    const selectedIndex = this.callReportIndex(option.value);
    let newState = {startIndex: selectedIndex};
    if (selectedIndex < this.state.endIndex) {
      newState.endIndex = selectedIndex;
    }
    this.setState(newState);
  };

  handleEndChange = (option) => {
    const selectedIndex = this.callReportIndex(option.value);
    let newState = {endIndex: selectedIndex};
    if (selectedIndex > this.state.startIndex) {
      newState.startIndex = selectedIndex;
    }
    this.setState(newState);
  };

  beginPolling() {
    const interval = 1000;
    const component = this;

    const poll = () => {
      const timeoutId = setTimeout(() => {
        ax.get('/settings/call_reports/sync_statuses')
          .catch(this.handleAjaxError.bind(this))
          .then((response) => {
            const callReports = response.data;
            const isSyncRequested = (id) => {
              const callReport = _.find(callReports, {id});
              return _.get(callReport, 'sync_requested', false);
            };
            const syncingCallReportIDs = _.filter(this.state.syncingCallReportIDs, isSyncRequested);
            this.setState({callReports, syncingCallReportIDs});
            if (_.size(syncingCallReportIDs) == 0) {
              this.setState({modalOpen: false});
              window.location.reload(true);
            } else {
              poll();
            }
          });
      }, interval);

      component.setState({timeoutId});
    };

    poll();
  }

  cancelPolling() {
    const {timeoutId} = this.state;
    if (timeoutId) {
      clearTimeout(timeoutId);
      this.setState({timeoutId: undefined});
    }
  }
}

export default CallReportsSyncButton;
