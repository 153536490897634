import React from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import styles from 'global/styles/DataTable';
import _ from 'lodash';

class DataTable extends React.Component {
  constructor(props) {
    super(props);

    const initializeAllChecked = this.props.initializeAllChecked;
    this.state = {
      selectedKeys: initializeAllChecked ? new Set(this.props.records.map(r => r.id)) : new Set(),
      headerChecked: initializeAllChecked
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.isCheckable) {
      if (!_.isEqual(this.props.records.map(r => r.id), prevProps.records.map(r => r.id))) {
        const initializeAllChecked = this.props.initializeAllChecked;
        this.setState( {
          selectedKeys: initializeAllChecked ? new Set(this.props.records.map(r => r.id)) : new Set(),
          headerChecked: initializeAllChecked
        });
      }
      if (this.state.selectedKeys !== prevState.selectedKeys
          && typeof this.props.onSelectedRowsChange === 'function') {
        this.props.onSelectedRowsChange(this.state.selectedKeys);
      }
    }
  }

  componentDidMount() {
    if (this.props.initializeAllChecked && typeof this.props.onSelectedRowsChange === 'function') {
      this.props.onSelectedRowsChange(this.state.selectedKeys);
    }
  }

  componentWillUnmount() {
    if (typeof this.props.onSelectedRowsChange === 'function') {
      this.props.onSelectedRowsChange(new Set());
    }
  }

  render() {
    return (
      this.props.isDraggable ? this.renderDraggable() : this.renderFixed()
    );
  }

  renderDraggable = () => {
    return (
      <DragDropContext
        onDragEnd={this.props.onDragEnd}
      >
        <div className="table-light">
          <table className="table">
            <thead>
              <tr>
                <td className={styles.controlColumn + ' bottom-border'}>&nbsp;</td>
                {this.renderCheckableHeader()}
                {this.props.columnHeaders.map(header => <HeaderEntry key={header} text={header} record={this.props.records.at(-1)} />)}
              </tr>
            </thead>
            <Droppable droppableId="table">
              {(droppableProvided) => (
                <tbody
                  ref={(ref) => {
                    this.tableRef = ref;
                    droppableProvided.innerRef(ref);
                  }}
                  {...droppableProvided.droppableProps}
                >
                  {this.renderDraggableRows()}
                </tbody>
              )}
            </Droppable>
          </table>
        </div>
      </DragDropContext>);
  };

  renderFixed = () => {
    return (
      <div className="table-light">
        <table className="table">
          <thead>
            <tr>
              {this.renderCheckableHeader()}
              {this.props.columnHeaders.map(header => <HeaderEntry key={header} text={header} />)}
            </tr>
          </thead>
          <tbody>
            {this.renderFixedRows()}
          </tbody>
        </table>
      </div>
    );
  };

  renderFixedRows = () => {
    if (this.props.isLoading) {
      return (this.renderLoading());
    } else if (this.props.records.length) {
      const RiskRow = this.props.rowComponent;
      return (
        this.props.records.map((record) =>
          <RiskRow
            key={record.id}
            record={record}
            selected={this.state.selectedKeys.has(record.id)}
            onSelectedChange={this.handleSelectedRowsChange}
            isDisabled={this.props.isDisabled}
          />
        ));
    } else {
      return (this.renderEmpty());
    }
  };

  renderDraggableRows = () => {
    if (this.props.isLoading) {
      return (this.renderLoading());
    } else if (this.props.records.length) {
      const RiskRow = this.props.rowComponent;
      return (
        this.props.records.map((record, index) =>
          <Draggable
            draggableId={record.id.toString()}
            index={index}
            key={record.id}
          >
            {(provided, snapshot) => (
              <RiskRow
                provided={provided}
                snapshot={snapshot}
                record={record}
                selected={this.state.selectedKeys.has(record.id)}
                onSelectedChange={this.handleSelectedRowsChange}
                isDisabled={this.props.isDisabled}
              />
            )}
          </Draggable>
        )
      );
    } else {
      return (this.renderEmpty());
    }
  };

  renderLoading = () => {
    return (
      <tr><td colSpan={this.props.columnHeaders.length}>Loading...</td></tr>
    );
  };

  renderEmpty = () => {
    return (
      <tr><td colSpan={this.props.columnHeaders.length}>No records available.</td></tr>
    );
  };

  renderCheckableHeader = () => {
    if (!this.props.isCheckable) {
      return null;
    }

    const isDisabled = this.props.isDisabled;
    return (
      <td className={styles.controlColumn + ' bottom-border'}><input className='boolean' type='checkbox' aria-label='checkbox' onChange={this.handleSelectAllChange} disabled={isDisabled} checked={this.state.headerChecked} /></td>
    );
  };

  handleSelectedRowsChange = (key, checked) => {
    if(checked) {
      this.addSelectedKey(key);
    } else {
      this.removeSelectedKey(key);
    }
  };

  allRowsChecked = () => {
    return this.props.records.length === this.state.selectedKeys.size;
  };

  handleHeaderChangeFromRows = () => {
    this.setState({
      headerChecked: this.allRowsChecked()
    });
  };

  handleSelectAllChange = (e) => {
    if (e.target.checked) {
      this.setState({
        selectedKeys: new Set(this.props.records.map(r => r.id)),
        headerChecked: true
      });
    } else {
      this.setState({
        selectedKeys: new Set(),
        headerChecked: false
      });
    }
  };

  addSelectedKey = (key) => {
    this.setState(({ selectedKeys }) => ({
      selectedKeys: new Set(selectedKeys.add(key)),
    }),() => this.handleHeaderChangeFromRows());
  };

  removeSelectedKey = (key) => {
    this.setState(({ selectedKeys }) => {
      const copySet = new Set(selectedKeys);
      copySet.delete(key);

      return {
        selectedKeys: copySet
      };
    },() => this.handleHeaderChangeFromRows());
  };
}

DataTable.defaultProps = {
  columnHeaders: ['Name'],
};

const HeaderEntry = (props) => {
  return <th className={props.record && props.record.wideWeightColumn ? styles.alignCenter : ''}>{props.text}</th>;
};

DataTable.propTypes = {
  records: PropTypes.array.isRequired,
  columnHeaders: PropTypes.array.isRequired,
  rowComponent: PropTypes.func.isRequired,
  isCheckable: PropTypes.bool,
  isDraggable: PropTypes.bool,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onSelectedRowsChange: PropTypes.func,
  onDragEnd: PropTypes.func,
};

HeaderEntry.propTypes = {
  text: PropTypes.string.isRequired
};

export default DataTable;
