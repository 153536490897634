import _ from 'lodash';
import React from 'react';
import ax from 'global/axios';
// import Routes from 'global/routes';
import Select from 'react-select';
import Modal from 'react-bootstrap/Modal';
import AsyncPaginate from 'react-select-async-paginate';
import QuestionInfoTooltip from 'global/components/QuestionInfoTooltip';

class CreateRiskObjectKriForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      areaOfRisk: props.areaOfRisk ? props.areaOfRisk : { value: 'ftra', label: 'FTRA' },
      riskObject: props.riskObject,
      ratingSelect: props.ratingSelect ? props.ratingSelect : { value: 'total_risk_rating', label: 'Total Risk Rating' },
      riskEntity: props.riskEntity ? props.riskEntity : { value: '', label: '' },
      areaOfRiskMessage: '',
      riskEntityMessage: ''
    };

    this.loadRiskObjects = this.loadRiskObjects.bind(this);
    this.handleRairedStepOneFormDataChange = props.handleRairedStepOneFormDataChange.bind(this);
    this.handleRiskEntityChange = this.handleRiskEntityChange.bind(this);
    this.loadRiskEntities = this.loadRiskEntities.bind(this);
  }

  async loadRiskEntities(search, loadedOptions, { page, areaOfRisk }) {
    const response = await ax({
      url: '/settings/overrides/indicator_overrides/risk_entities',
      params: {
        name: search,
        area_of_risk: areaOfRisk.value,
        page: page
      },
      method: 'get',
    }).then((res) => {
      return res.data;
    }).catch(error => {
      ax.handleError(error);
    });

    return {
      options: response.results,
      hasMore: response.has_more,
      additional: {
        page: page + 1,
        areaOfRisk: areaOfRisk,
      },
    };
  }

  handleRiskEntityChange(event) {
    const label = event === null ? '' : event.label;
    const value = event === null ? null : event.value;

    this.setState({
      riskEntity: {
        label: label,
        value: value
      },
      riskObject: {
        label: null,
        value: null
      },
      sourceRatingType: '',
      disabled: true
    });

    this.handleRairedStepOneFormDataChange({ name: '' });
    this.nextButtonDisabled();
  }

  async loadRiskObjects(search, loadedOptions, { page, areaOfRisk, riskEntity, sourceRatingType }) {
    const response = await ax({
      url: '/settings/overrides/indicator_overrides/risk_objects',
      params: {
        name: search,
        area_of_risk: areaOfRisk.value,
        risk_entity: riskEntity?.value,
        source_rating_type: sourceRatingType?.value,
        page: page
      },
      method: 'get',
    }).then((res) => {
      return res.data;
    }).catch(error => {
      ax.handleError(error);
    });

    return {
      options: response.results,
      hasMore: response.has_more,
      additional: {
        page: page + 1,
        areaOfRisk: areaOfRisk,
        riskEntity: riskEntity,
        sourceRatingType: sourceRatingType,
      },
    };
  }

  notify = () => {
    const {onChange} = this.props;
    const filteredState = _.pick(this.state, ['areaOfRisk', 'ratingSelect', 'riskObject', 'riskEntity']);
    onChange(filteredState);
  };

  handleAreaOfRiskChange = (areaOfRisk) => {
    const label = areaOfRisk === null ? '' : areaOfRisk.label;
    const value = areaOfRisk === null ? null : areaOfRisk.value;

    this.setState({
      areaOfRisk: { value: value, label: label },
      areaOfRiskMessage: value ? '' : 'Must choose Area of Risk.'
    }, function () {
      let ratingSelectOption;

      switch(this.state.areaOfRisk.value) {
      case 'rcsa':
        ratingSelectOption = { label: 'Inherent Risk Rating', value: 'inherent_risk_rating' };

        break;
      case 'rmf':
        ratingSelectOption = { label: 'Composite Actual Maturity', value: 'composite_actual_maturity' };

        break;
      case 'pra':
        ratingSelectOption = { label: 'Quantity of Risk Rating', value: 'quantity_of_risk_rating' };

        break;
      default:
        ratingSelectOption = { label: 'Total Risk Rating', value: 'total_risk_rating' };
      }

      this.setState({
        ratingSelect: ratingSelectOption,
        sourceRatingType: '',
        riskObject: {
          label: null,
          value: null
        },
        riskEntity: {
          label: null,
          value: null
        },
        disabled: true
      });

      this.handleRairedStepOneFormDataChange({ name: '' });
      this.nextButtonDisabled();
      this.notify();
    });
  };

  handleSourceRatingTypeChange = (sourceRatingType) => {
    this.setState({
      sourceRatingType,
      name: '',
      riskObject: { label: '', value: '' }
    });
  };

  handleRatingSelectChange = (ratingSelect) => {
    this.setState({
      ratingSelect: ratingSelect,
      ratingSelectMessage: ratingSelect ? '' : 'Must select a Rating Option.'
    }, this.notify);
  };

  handleRiskObjectChange = (riskObject) => {
    const label = riskObject === null ? '' : riskObject.label;
    const value = riskObject === null ? null : riskObject.value;
    const { areaOfRisk, riskEntity, sourceRatingType } = this.state;

    this.setState({
      riskObject: { value: value, label: label },
      riskObjectMessage: value ? '' : 'Must select a Risk Object.',
      disabled: false
    }, this.notify);

    let indicatorName;

    if (areaOfRisk.value == 'rmf' || areaOfRisk.value == 'ftra' && sourceRatingType.value == 'composite') {
      indicatorName = `${riskEntity.label} ${label} [${areaOfRisk.value.toUpperCase()}]`;
    } else {
      indicatorName = `${label} [${areaOfRisk.value.toUpperCase()}]`;
    }

    this.handleRairedStepOneFormDataChange({ name: indicatorName });
  };

  nextButtonDisabled() {
    const { disabled } = this.state;

    if (disabled) {
      return true;
    }
    if (_.isUndefined(_.get(this.state, 'areaOfRisk.value'))) {
      return true;
    }
    if (_.isUndefined(_.get(this.state, 'ratingSelect.value'))) {
      return true;
    }
    if (_.isUndefined(_.get(this.state, 'riskObject.value'))) {
      return true;
    }
    if (_.isUndefined(_.get(this.state, 'riskEntity.value'))) {
      return true;
    }

    return false;
  }

  rcsaFields = () => {
    const {
      riskObject,
      areaOfRisk,
      ratingSelect
    } = this.state;

    const { validation } = this.props;

    const ratingSelections = [
      { label: 'Inherent Risk Rating', value: 'inherent_risk_rating' },
      { label: 'Residual Risk Rating', value: 'residual_risk_rating' }
    ];

    return (
      <>
        <div className={validation.typeable ? 'form-group has-error' : 'form-group'}>
          <label htmlFor='react-select-2-input' className="select control-label">
            <abbr title='required'>*</abbr>
            Risk Object
          </label>
          <AsyncPaginate
            debounceTimeout={300}
            id={'risk_objects'}
            className='riskObject'
            classNamePrefix='riskObject'
            value={riskObject}
            onChange={this.handleRiskObjectChange}
            loadOptions={this.loadRiskObjects}
            additional={{page: 1, areaOfRisk: areaOfRisk}}
            isClearable={true}
            styles={{ menuList: (provided, _state) => ({ ...provided, maxHeight: 200 }) }}
          />
          {validation.typeable &&
            <span className='help-block error-block'>{validation.typeable}</span>
          }
        </div>

        <div className={validation.ratingSelect ? 'has-error form-group select' : 'form-group select'}>
          <label htmlFor='react-select-2-input' className="select control-label">
            <abbr title='required'>*</abbr>
            Rating Select
          </label>
          <Select
            id='ratingSelect'
            className='ratingSelect'
            classNamePrefix='ratingSelect'
            value={ratingSelect}
            onChange={this.handleRatingSelectChange}
            options={ratingSelections}
            isClearable={false}
            styles={{ menuList: (provided, _state) => ({ ...provided, maxHeight: 200 }) }}
          />
          {validation.ratingSelect &&
            <span className='help-block error-block'>{validation.ratingSelect}</span>}
        </div>
      </>
    );
  };

  ftraFields = () => {
    const {
      riskEntity,
      riskEntityMessage,
      riskObject,
      areaOfRisk,
      sourceRatingType,
      ratingSelect
    } = this.state;

    const { validation } = this.props;

    const  ratingSelections = [
      { label: 'Total Risk Rating', value: 'total_risk_rating' }
    ];

    const sourceRatingTypes = [
      { label: 'Composite Risk Rating', value: 'composite'  },
      { label: 'Risk Category Rating', value: 'risk_category' }
    ];

    return (
      <>
        <div className={riskEntityMessage ? 'form-group has-error' : 'form-group'}>
          <label htmlFor='react-select-2-input' className="select control-label">
            <abbr title='required'>*</abbr>
            Risk Entity
          </label>
          <AsyncPaginate
            key={areaOfRisk?.value}
            debounceTimeout={300}
            id={'risk_entities'}
            className='riskEntity'
            classNamePrefix='riskEntity'
            value={riskEntity}
            onChange={this.handleRiskEntityChange}
            loadOptions={this.loadRiskEntities}
            additional={{page: 1, areaOfRisk: areaOfRisk}}
            isClearable={true}
            styles={{ menuList: (provided, _state) => ({ ...provided, maxHeight: 200 }) }}
          />
          {riskEntityMessage &&
            <span className='help-block error-block'>{riskEntityMessage}</span>
          }
        </div>
        {riskEntity?.value &&
          <div className='form-group'>
            <label htmlFor='react-select-2-input' className="select control-label">
              <abbr title='required'>*</abbr>
              Source Rating Type
            </label>
            <Select
              id='sourceRatingType'
              className='sourceRatingType'
              classNamePrefix='sourceRatingType'
              value={sourceRatingType}
              onChange={this.handleSourceRatingTypeChange}
              options={sourceRatingTypes}
              isClearable={false}
              styles={{ menuList: (provided, _state) => ({ ...provided, maxHeight: 200  })  }}
            />
          </div>
        }
        {sourceRatingType?.value &&
          <div className={validation.typeable ? 'form-group has-error' : 'form-group'}>
            <label htmlFor='react-select-2-input' className="select control-label">
              <abbr title='required'>*</abbr>
              Risk Object
            </label>
            <QuestionInfoTooltip
              content="Eligible risk objects are from the latest completed time period relative to the selected time period."
              html={true}
              placement='bottom'
              trigger='hover'
            />
            <AsyncPaginate
              key={sourceRatingType?.value}
              debounceTimeout={300}
              id={'risk_objects'}
              className='riskObject'
              classNamePrefix='riskObject'
              value={riskObject}
              onChange={this.handleRiskObjectChange}
              loadOptions={this.loadRiskObjects}
              additional={{page: 1, areaOfRisk: areaOfRisk, riskEntity: riskEntity, sourceRatingType: sourceRatingType}}
              isClearable={true}
              styles={{ menuList: (provided, _state) => ({ ...provided, maxHeight: 200 }) }}
            />
            {validation.typeable &&
              <span className='help-block error-block'>{validation.typeable}</span>
            }
          </div>
        }

        <div className={validation.ratingSelect ? 'has-error form-group select' : 'form-group select'}>
          <label htmlFor='react-select-2-input' className="select control-label">
            <abbr title='required'>*</abbr>
            Rating Select
          </label>

          <Select
            id='ratingSelect'
            className='ratingSelect'
            classNamePrefix='ratingSelect'
            value={ratingSelect}
            onChange={this.handleRatingSelectChange}
            options={ratingSelections}
            isClearable={false}
            styles={{ menuList: (provided, _state) => ({ ...provided, maxHeight: 200 }) }}
          />
          {validation.ratingSelect &&
            <span className='help-block error-block'>{validation.ratingSelect}</span>}
        </div>
      </>
    );
  };

  riskEntityRiskObjectFields = () => {
    const {
      riskEntity,
      riskEntityMessage,
      riskObject,
      areaOfRisk,
      ratingSelect
    } = this.state;

    const { validation } = this.props;

    var ratingSelections;
    if (areaOfRisk.value == 'rmf') {
      ratingSelections = [
        { label: 'Composite Actual Maturity', value: 'composite_actual_maturity' }
      ];
    } else {
      ratingSelections = [
        { label: 'Quantity of Risk Rating', value: 'quantity_of_risk_rating' }
      ];
    }

    return (
      <>
        <div className={riskEntityMessage ? 'form-group has-error' : 'form-group'}>
          <label htmlFor='react-select-2-input' className="select control-label">
            <abbr title='required'>*</abbr>
            Risk Entity
          </label>
          <AsyncPaginate
            key={areaOfRisk?.value}
            debounceTimeout={300}
            id={'risk_entities'}
            className='riskEntity'
            classNamePrefix='riskEntity'
            value={riskEntity}
            onChange={this.handleRiskEntityChange}
            loadOptions={this.loadRiskEntities}
            additional={{page: 1, areaOfRisk: areaOfRisk}}
            isClearable={true}
            styles={{ menuList: (provided, _state) => ({ ...provided, maxHeight: 200 }) }}
          />
          {riskEntityMessage &&
            <span className='help-block error-block'>{riskEntityMessage}</span>
          }
        </div>
        {riskEntity?.value &&
          <div className={validation.typeable ? 'form-group has-error' : 'form-group'}>
            <label htmlFor='react-select-2-input' className="select control-label">
              <abbr title='required'>*</abbr>
              Risk Object
            </label>
            <QuestionInfoTooltip
              content="Eligible risk objects are from the latest completed time period relative to the selected time period."
              html={true}
              placement='bottom'
              trigger='hover'
            />
            <AsyncPaginate
              key={riskEntity?.value}
              debounceTimeout={300}
              id={'risk_objects'}
              className='riskObject'
              classNamePrefix='riskObject'
              value={riskObject}
              onChange={this.handleRiskObjectChange}
              loadOptions={this.loadRiskObjects}
              additional={{page: 1, areaOfRisk: areaOfRisk, riskEntity: riskEntity}}
              isClearable={true}
              styles={{ menuList: (provided, _state) => ({ ...provided, maxHeight: 200 }) }}
            />
            {validation.typeable &&
              <span className='help-block error-block'>{validation.typeable}</span>
            }
          </div>
        }

        <div className={validation.ratingSelect ? 'has-error form-group select' : 'form-group select'}>
          <label htmlFor='react-select-2-input' className="select control-label">
            <abbr title='required'>*</abbr>
            Rating Select
          </label>

          <Select
            id='ratingSelect'
            className='ratingSelect'
            classNamePrefix='ratingSelect'
            value={ratingSelect}
            onChange={this.handleRatingSelectChange}
            options={ratingSelections}
            isClearable={false}
            styles={{ menuList: (provided, _state) => ({ ...provided, maxHeight: 200 }) }}
          />
          {validation.ratingSelect &&
            <span className='help-block error-block'>{validation.ratingSelect}</span>}
        </div>
      </>
    );
  };

  riskObjectFields = () => {
    const {
      areaOfRisk,
      areaOfRiskMessage
    } = this.state;

    const areasOfRisk = [
      { label: 'FTRA', value: 'ftra' },
      { label: 'PRA', value: 'pra'  },
      { label: 'RCSA', value: 'rcsa'  },
      { label: 'RMF', value: 'rmf'  }
    ];

    return (
      <>
        <div className={areaOfRiskMessage ? 'has-error form-group select' : 'form-group select'}>
          <label htmlFor='react-select-2-input' className="select control-label">
            <abbr title='required'>*</abbr>
            Area of Risk
          </label>
          <Select
            id='areaOfRisk'
            classNamePrefix='areaOfRisk'
            type='select'
            value={areaOfRisk}
            onChange={this.handleAreaOfRiskChange}
            options={areasOfRisk}
            isClearable={false}
            styles={{ menuList: (provided, _state) => ({ ...provided, maxHeight: 200 }) }}
          />
          {areaOfRiskMessage &&
            <span className='help-block error-block'>{areaOfRiskMessage}</span>}
        </div>
        { areaOfRisk.value == 'rcsa' &&
          this.rcsaFields()
        }
        { areaOfRisk.value == 'ftra' &&
          this.ftraFields()
        }
        { (areaOfRisk.value != 'rcsa' && areaOfRisk.value != 'ftra') &&
          this.riskEntityRiskObjectFields()
        }
      </>
    );
  };

  render() {
    const {
      onModeChange,
      onBack,
      onNext
    } = this.props;

    return (
      <>
        <Modal.Body className='overflow-visible'>
          <div>
            <a href='#' onClick={() => onModeChange('select-existing')}>Select an existing Data Input</a>
            <br />
            <a href='#' onClick={() => onModeChange('create-new')}>Create a new Data Input</a>
            <br /><br />
          </div>
          <div>
            <fieldset>
              <legend>Create from Risk Object</legend>
              { this.riskObjectFields() }
            </fieldset>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary pull-left" type="button" onClick={onBack}>
            Back
          </button>
          <button
            className="btn btn-primary pull-right"
            type="button" disabled={this.nextButtonDisabled()} onClick={onNext}>
          Next
          </button>
        </Modal.Footer>
      </>
    );
  }
}

export default CreateRiskObjectKriForm;
