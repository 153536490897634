export const RairedParams = (props) => {
  let name = '';
  if (props.name) {
    name = props.name.trim();
  } else if (props.rairedName) {
    name = props.rairedName.trim();
  } else {
    name = null;
  }

  let diredID = '';
  if (props.selectedDataInput) {
    diredID = props.selectedDataInput['value'];
  } else if (props.createdDired) {
    diredID = props.createdDired['value'];
  } else {
    diredID = null;
  }

  let riskObjectID = '';
  if (props.riskObject) {
    riskObjectID = props.riskObject['value'];
  } else {
    riskObjectID = null;
  }

  let limitType = '';
  if (typeof props.limitType === 'string') {
    limitType = props.limitType;
  } else if (typeof props.limitType === 'object') {
    limitType = props.limitType.value;
  } else {
    limitType = null;
  }

  let indicatorType = '';
  if (typeof props.indicatorType === 'string') {
    indicatorType = props.indicatorType;
  } else if (typeof props.indicatorType === 'object') {
    indicatorType = props.indicatorType.value;
  } else {
    indicatorType = null;
  }

  let ratingSelect = '';
  if (typeof props.ratingSelect === 'string') {
    ratingSelect = props.ratingSelect;
  } else if (typeof props.ratingSelect === 'object') {
    ratingSelect = props.ratingSelect.value;
  } else {
    ratingSelect = null;
  }

  let areaOfRisk = '';
  if (typeof props.areaOfRisk === 'string') {
    areaOfRisk = props.areaOfRisk;
  } else if (typeof props.areaOfRisk === 'object') {
    areaOfRisk = props.areaOfRisk.value;
  } else {
    areaOfRisk = null;
  }

  let sourceRatingType = '';
  if (props.sourceRatingType) {
    sourceRatingType = props.sourceRatingType['value'];
  } else {
    sourceRatingType = null;
  }

  if (limitType == 'select') {
    const selectBenchmarks = [1.0, 2.0, 3.0, 4.0, 5.0, 6.0, null];
    if (props.enabledQuarterly) {
      props.quarterlyBenchmarks = selectBenchmarks;
    }
    if (props.enabledMonthly) {
      props.monthlyBenchmarks = selectBenchmarks;
    }
  }

  return {
    risk_attribute_risk_entity_definition_id: props.raredID,
    data_input_risk_entity_definition_id: diredID,
    risk_object_id: riskObjectID,
    name: name,
    description: props.description,
    regulatory_definition: props.definition,
    limit_type: limitType,
    indicator_type: indicatorType,
    area_of_risk: areaOfRisk,
    rating_type: ratingSelect,
    source_rating_type: sourceRatingType,
    enabled_quarterly: props.enabledQuarterly,
    enabled_monthly: props.enabledMonthly,
    monthly_benchmarks: props.monthlyBenchmarks,
    quarterly_benchmarks: props.quarterlyBenchmarks
  };
};
