import React from 'react';

function FormGroup({label, children}) {
  return (
    <div className='form-group'>
      <div>
        <label>{label}</label>
      </div>
      <div>{children}</div>
    </div>
  );
}

export default FormGroup;
