export const RaredParams = (props) => {
  let name = '';
  if (props.name) {
    name = props.name.trim();
  } else {
    name = null;
  }

  return {
    risk_attribute_risk_entity_definition: {
      risk_category_definition_id: props.categoryDefinitionId,
      risk_component_definition_id: props.componentDefinitionId,
      attribute_type: props.attributeType,
      name: name,
      rating_description_1: props.ratingDescriptionOne,
      rating_description_2: props.ratingDescriptionTwo,
      rating_description_3: props.ratingDescriptionThree,
      rating_description_4: props.ratingDescriptionFour,
      rating_description_5: props.ratingDescriptionFive
    }
  };
};
