/*eslint no-unused-vars: ["error", { "varsIgnorePattern": "React" }]*/
import React from 'react';
// Unused vars warning should be fixed with
// https://stackoverflow.com/questions/42541559/eslint-with-react-gives-no-unused-vars-errors
// Didn't work on first attempt.

function Demo() {
  return (
    <div className='row'>
      <div className='col-md-12'>
        <h4>Demo Package Section</h4>
      </div>
    </div>
  );
}

export default Demo;
