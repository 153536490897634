import React from 'react';
import _ from 'lodash';
import Modal from 'react-bootstrap/Modal';

class ConfigurationModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      animationClassName: '',
      changes: {},
    };
  }

  componentWillUnmount() {
    this.unbindKeys();
  }

  bindKeys() {
    document.body.addEventListener('keydown', this.handleKeyDown);
  }

  unbindKeys() {
    document.body.removeEventListener('keydown', this.handleKeyDown);
  }

  handleModalEntering = () => {
    this.setState({animationClassName: 'in'});
    this.bindKeys();
  };

  handleModalExit = () => {
    this.setState({
      animationClassName: '',
      changes: {},
    });
    this.unbindKeys();
  };

  handleChange = (change) => {
    const changes = _.assign({}, this.state.changes, change);
    this.setState({changes});
  };

  handleKeyDown = (e) => {
    if (e.key == 'Enter') {
      this.apply();
    }
  };

  apply = () => {
    const {onChange, onHide} = this.props;
    const {changes} = this.state;
    onChange(changes);
    onHide();
  };

  clear = () => {
    const {values} = this.props;
    const clearable = _.omit(values, ['report', 'id', 'timePeriod', 'start_time_period', 'end_time_period']);
    const changes = _.transform(clearable, (result, value, key) => {
      let clearedValue = undefined;
      if (key == 'content') {
        clearedValue = '';
      }
      result[key] = clearedValue;
    }, {});
    this.setState({changes});
  };

  render() {
    const {show, onHide, Form, values} = this.props;
    const {animationClassName, changes} = this.state;
    const formProps = _.assign({}, values, changes);

    const modalProps = {
      show: show,
      onHide: onHide,
      onExit: this.handleModalExit,
      onEntering: this.handleModalEntering,
      backdropClassName: animationClassName,
      className: animationClassName,
      centered: true,
      animation: true,
      style: {
        zIndex:1040
      }
    };

    return (
      <Modal {...modalProps}>
        <Modal.Header>
          <button className='display-md-inline close' onClick={onHide}>&times;</button>
          <div className='modal-title'>
            <h3>{Form.title || 'Section Configuration'}</h3>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Form
            onChange={this.handleChange}
            {...formProps}
          />
        </Modal.Body>
        <Modal.Footer>
          <button className='btn pull-left' onClick={this.clear}>
            Clear
          </button>
          <button className='btn btn-outline' onClick={onHide}>
            Cancel
          </button>
          <button className='btn btn-primary' onClick={this.apply}>
            Apply
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ConfigurationModal;
