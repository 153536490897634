import React from 'react';
import {Link} from 'react-router-dom';
import _ from 'lodash';
import ax from 'global/axios';
import GlyphiconButton from 'reporting/package/GlyphiconButton';
import TextInputForm from 'reporting/package/TextInputForm';
import DeleteConfirmation from 'reporting/package/DeleteConfirmation';

const namePlaceholder = 'Unnamed Package';

class Package extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.pkg.id,
      name: props.pkg.name,
      persistedName: props.pkg.name,
      renaming: false,
      promptingForDeletion: false,
      deletionModalOpen: false,
      focused: false,
      busy: false
    };
  }

  componentDidMount() {
    if (!this.state.id) {
      this.setState({renaming: true});
    }
  }

  handleMouseEnter = () => {
    if (this.state.id) {
      this.setState({focused: true});
    }
  };

  handleMouseLeave = () => {
    this.setState({focused: false});
  };

  handleRenameClick = () => {
    this.setState({renaming: true});
  };

  handleDestroyClick = () => {
    this.setState({deletionModalOpen: true});
  };

  handleDestroyCancel = () => {
    this.setState({deletionModalOpen: false});
  };

  handleRename = (name) => {
    this.setState({name}, () => {
      if (this.state.id) {
        this.save();
      } else {
        this.create();
      }
    });
  };

  handleRenameCancel = () => {
    if (this.state.id) {
      this.revert();
    } else {
      this.props.onDestroy();
    }
  };

  create = () => {
    let {name} = this.state;
    name = _.trim(name);
    this.setState({busy: true});
    ax({
      url: '/reporting/packages',
      method: 'post',
      data: { name }
    })
      .then( (res) => {
        this.props.onChange({pkg: res.data});
      }, () => {
        this.setState({busy: false});
        window.dialogs.error({ message: 'Package name cannot include these characters: ? * : \\ | / < > ¥' });
      });
  };

  save = () => {
    const {pkg, onChange} = this.props;
    let {id, name} = this.state;
    name = _.trim(name);
    this.setState({busy: true});
    ax({
      url: `/reporting/packages/${id}`,
      method: 'put',
      data: {
        name: name,
        sections: pkg.sections
      }
    })
      .then( (res) => {
        onChange({pkg: res.data});
        this.setState({
          busy: false,
          renaming: false,
          persistedName: res.data.name
        });
      }, () => {
        this.revert();
        window.dialogs.error({ message: 'Package name cannot include these characters: ? * : \\ | / < > ¥' });
      });
  };

  destroy = () => {
    this.setState({
      busy: true,
      deletionModalOpen: false
    });
    let {id} = this.state;
    ax({
      url: `/reporting/packages/${id}`,
      method: 'delete'
    })
      .then( () => {
        this.props.onDestroy();
      }, (error) => {
        this.setState({busy: false});
        ax.handleError(error);
      });
  };

  revert = () => {
    this.setState({
      name: this.state.persistedName,
      renaming: false,
      busy: false
    });
  };

  render() {
    const {id, name, renaming, focused, busy, deletionModalOpen} = this.state;

    const liProps = {
      className: 'list-group-item',
      onMouseEnter: this.handleMouseEnter,
      onMouseLeave: this.handleMouseLeave,
      onClick: this.handleClick,
      style: {
        lineHeight: '2.5em',
        height: '4em'
      }
    };

    const linkStyle = {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      lineHeight: '4em',
      paddingLeft: '2em'
    };

    return (
      <>
        <div {...liProps}>
          <div className='row'>
            {!renaming &&
              <Link to={`/reporting/packages/${id}`} style={linkStyle}>
                {name}
              </Link>
            }
            <div className='col-md-10'>
              {renaming &&
                <TextInputForm
                  placeholder={namePlaceholder}
                  value={name}
                  disabled={busy}
                  onSubmit={this.handleRename}
                  onCancel={this.handleRenameCancel}
                  onBlur={this.handleRenameCancel}
                />
              }
            </div>
            <div className='col-md-2'>
              { focused &&
                <div className='btn-toolbar pull-right'>
                  <div className='btn-group' style={{width: 'initial'}}>
                    <GlyphiconButton
                      disabled={busy || renaming}
                      icon='glyphicon-pencil'
                      alt='Rename'
                      tooltip='Rename'
                      onClick={this.handleRenameClick}
                    />
                    <GlyphiconButton
                      disabled={busy}
                      icon='glyphicon-trash'
                      alt='Delete'
                      tooltip='Delete'
                      onClick={this.handleDestroyClick}
                    />
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
        <DeleteConfirmation
          show={deletionModalOpen}
          onHide={this.handleDestroyCancel}
          onConfirm={this.destroy}
        />
      </>
    );
  }
}

export default Package;
