import React from 'react';
import Routes from 'global/routes';
import DataTable from 'global/components/DataTable';
import Toolbar from 'settings/components/Toolbar';
import DepartmentRiskEntityDefinitionRow from 'administration/components/DepartmentRiskEntityDefinitionRow';
import ax from 'global/axios';
import _ from 'lodash';

class DepartmentRiskEntityDefinitionsContainer extends React.Component {
  constructor(props) {
    super(props);
    const element = document.querySelector('#react-root');
    this.state = {
      accountId: element.dataset.accountId,
      riskEntityId: element.dataset.riskEntityId,
      departments: [],
      loading: true,
      reordering: false,
      toolbarActions: [
        {
          onClick: this.handleSaveReorderClick,
          iconClass: 'fa fa-save',
          text: 'Save Arrangement',
          visibility: {
            whenReordering: true
          }
        },
        {
          onClick: this.handleCancelReorderClick,
          iconClass: 'fa fa-ban',
          text: 'Cancel',
          visibility: {
            whenReordering: true
          }
        }
      ]
    };
  }

  async componentDidMount() {
    this.getDepartmentRiskEntityDefinitions();
  }

  render() {
    const departments = this.state.departments;
    const loading = this.state.loading;
    return (
      <>
        <Toolbar actions={this.state.toolbarActions} isActionVisible={this.toolbarActionVisible} loading={loading} />
        <DataTable
          records={departments}
          isLoading={loading}
          isCheckable={false}
          isDraggable={true}
          droppableId={'departments'}
          rowComponent={DepartmentRiskEntityDefinitionRow}
          onDragEnd={this.handleDragEnd}
        />
      </>
    );
  }

  toolbarActionVisible = (key) => {
    const {
      toolbarActions,
      reordering
    } = this.state;
    const visibilityParams = toolbarActions[key].visibility;

    if (!_.has(visibilityParams, 'whenReordering') && reordering) {
      // Short circuit for reordering is occurring.
      return false;
    }

    if (_.has(visibilityParams, 'whenReordering')) {
      return reordering;
    }
  };

  handleSaveReorderClick =  async () => {
    return await this.updateOrder();
  };

  handleCancelReorderClick = async () => {
    return await this.getDepartmentRiskEntityDefinitions();
  };

  handleDragEnd = (result) => {
    // Dropped outside the table
    if (!result.destination) {
      return;
    }

    const departments = reorder(
      this.state.departments,
      result.source.index,
      result.destination.index
    );

    this.setState({ departments: departments, reordering: true });
  };

  getDepartmentRiskEntityDefinitions = async () => {
    return ax({
      url: Routes.administration_account_risk_entity_department_risk_entity_definitions_path(this.state.accountId, this.state.riskEntityId),
      method: 'get',
    }).then((res) => {
      this.setState({ departments: res.data, loading: false, reordering: false });
    }).catch(ax.handleError);
  };

  updateOrder = async () => {
    const orderedKeys = this.state.departments.map(d => d.id);

    return ax({
      url: Routes.update_order_administration_account_risk_entity_department_risk_entity_definitions_path(this.state.accountId, this.state.riskEntityId),
      method: 'patch',
      data: {
        department_risk_entity_definition_ids: orderedKeys
      }
    }).then(() => {
      return this.getDepartmentRiskEntityDefinitions();
    }).catch(ax.handleError);
  };
}

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export default DepartmentRiskEntityDefinitionsContainer;
