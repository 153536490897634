import React from 'react';
import styles from '../../styles/DiredForm';
import Modal from 'react-bootstrap/Modal';

function ChooseDiredMode(props) {
  const { handleModeChange, onBack, canEditObject } = props;

  return (
    <>
      <Modal.Body>
        {canEditObject &&
          <div className={'grid grid-cols-5 grid-rows-1 ' + styles.rowHeight}>
            <div className={styles.buttonPadding + ' md:col-span-1 text-center'}>
              <a href='#' className="btn btn-default" onClick={() => handleModeChange('select-existing')} data-turbolinks="false">Select an Existing Data Input</a>
            </div>
            <div className={styles.buttonPadding + ' md:col-span-1 text-center'}><h4>Or</h4></div>
            <div className={styles.buttonPadding + ' md:col-span-1 text-center'}>
              <a href='#' className="btn btn-default" onClick={() => handleModeChange('create-new')} data-turbolinks="false">Create a New Data Input</a>
            </div>
            <div className={styles.buttonPadding + ' md:col-span-1 text-center'}><h4>Or</h4></div>
            <div className={styles.buttonPadding + ' md:col-span-1 text-center'}>
              <a href='#' className="btn btn-default" onClick={() => handleModeChange('create-from-object')} data-turbolinks="false">Create from Risk Object</a>
            </div>
          </div>
        }
        {!canEditObject &&
          <div className={'grid grid-cols-5 grid-rows-1 ' + styles.rowHeight}>
            <div className={styles.buttonPadding + ' md:col-span-2 text-center'}>
              <a href='#' className="btn btn-default" onClick={() => handleModeChange('select-existing')} data-turbolinks="false">Select an Existing Data Input</a>
            </div>
            <div className={styles.buttonPadding + ' md:col-span-1 text-center'}><h4>Or</h4></div>
            <div className={styles.buttonPadding + ' md:col-span-2 text-center'}>
              <a href='#' className="btn btn-default" onClick={() => handleModeChange('create-new')} data-turbolinks="false">Create a New Data Input</a>
            </div>
          </div>
        }
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-secondary pull-left" type="button" onClick={onBack}>
          Back
        </button>
        <button className="btn btn-primary pull-right" type="button" disabled={true} >
          Next
        </button>
      </Modal.Footer>
    </>
  );
}

export default ChooseDiredMode;
