import React from 'react';
import _ from 'lodash';
import styles from 'global/styles/TimePeriodPicker';

function Quarter(props) {
  const {name, number, disabled, selected, onClick} = props;
  const liProps = {
    className: disabled ? styles.disabled : (selected ? styles.selected : null),
    onClick: onClick && !disabled ? _.partial(onClick, number) : null
  };

  return (
    <li {...liProps}>
      <label>
        {name}
      </label>
    </li>
  );
}
function QuarterPicker(props) {
  const {isSelected, isDisabled, onClick} = props;
  return (
    <ol className={styles.quarters}>
      { _.range(1, 5).map( (n) => (
        <Quarter
          name={`Q${n}`}
          key={`Q${n}`}
          disabled={isDisabled(n)}
          selected={isSelected(n)}
          onClick={_.partial(onClick, n)}
        />
      )) }
    </ol>
  );
}

export default QuarterPicker;
