import React from 'react';
import _ from 'lodash';
import AsyncPaginate from 'react-select-async-paginate';
import Modal from 'react-bootstrap/Modal';

function SelectExistingDataInputRiskEntityDefinition(props) {
  const {
    loadOptions, // TODO: This should be a responsibility of this component, not passed down from parent
    onChange,
    onModeChange,
    selectedDataInput,
    canEditObject,
    onBack,
    onNext
  } = props;
  const missingSelectedDataInput = !_.get(props, 'selectedDataInput.value');

  return (
    <>
      <Modal.Body className='overflow-visible'>
        <div>
          <a href='#' onClick={() => onModeChange('create-new')}>Create a new Data Input</a>
          <br/>
        </div>
        {canEditObject &&
          <div>
            <a href='#' onClick={() => onModeChange('create-from-object')}>Create from Risk Object</a>
            <br/>
          </div>
        }
        <br/>
        <div>
          <form className='simple_form'>
            <fieldset>
              <legend>Select an Existing Data Input</legend>
              <div className={'form-group select'}>
                <label className="select control-label">
                  <abbr title='required'>*</abbr>
                  Data Input
                </label>
                <AsyncPaginate
                  debounceTimeout={300}
                  id={'direds'}
                  classNamePrefix='dired'
                  value={selectedDataInput}
                  onChange={onChange}
                  loadOptions={loadOptions}
                  additional={{ page: 1 }}
                  styles={{ menuList: (provided, _state) => ({ ...provided, maxHeight: 200 }) }}
                />
              </div>
            </fieldset>
          </form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-secondary pull-left" type="button" onClick={onBack}>
          Back
        </button>
        <button
          className="btn btn-primary pull-right"
          type="button" disabled={missingSelectedDataInput} onClick={onNext}>
        Next
        </button>
      </Modal.Footer>
    </>
  );
}

export default SelectExistingDataInputRiskEntityDefinition;
