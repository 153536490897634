import React from 'react';
import Select from 'react-select';
import ax from 'global/axios';
import _ from 'lodash';

const loader = _.memoize( (risk_category_definition_id) => {
  return ax({
    url: '/risk_category_definitions/line_of_business_risk_entity_definitions',
    method: 'post', // Note: post is inconsistent with ReST
    data: {
      risk_category_definition_ids: [risk_category_definition_id]
    }
  });
});

class LineOfBusinessPicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
      loading: false,
      error: null
    };
  }

  load() {
    const {risk_category_definition_id} = this.props;
    this.setState({loading: true});
    loader(risk_category_definition_id)
      .then( (res) => {
        const options = res
          .data
          .line_of_business_risk_entity_definitions
          .map( x => ({value: x.id, label: x.name}) );
        this.setState({
          loading: false,
          error: null,
          options: options
        });
      }, (error) => {
        this.setState({
          loading: false,
          options: [],
          error: error
        });
      });
  }

  noOptionsMessage = () => {
    const {error} = this.state;
    if (error) {
      return 'Error loading Lines of Business';
    } else {
      return 'No Lines of Business Available';
    }
  };

  componentDidMount() {
    this.load();
  }

  componentDidUpdate(prevProps, prevState) {
    const {value, onChange} = this.props;
    const {options} = this.state;

    if (this.props.risk_category_definition_id != prevProps.risk_category_definition_id) {
      this.load();
    }

    if (options != prevState.options) {
      const found = this.selectedOption();
      // Clear selection when not in available options
      if (value && !found) {
        onChange({line_of_business_risk_entity_definition_id: null});
      }
    }
  }

  selectedOption() {
    const {value} = this.props;
    const {options} = this.state;
    const found = _.find(options, _.matchesProperty('value', value));
    if (found) {
      return found;
    } else {
      // Returning undefined directly from _.find doesn't clear visible selection reliably
      return null;
    }
  }

  handleChange = (option) => {
    const value = _.get(option, 'value');
    this.props.onChange({line_of_business_risk_entity_definition_id: value});
  };

  handleMenuOpen = () => {
    const {risk_category_definition_id} = this.props;
    const {error} = this.state;
    if (error) {
      loader(risk_category_definition_id).catch( () => loader.cache.delete(risk_category_definition_id) );
      this.load();
    }
  };

  render() {
    const {loading, options} = this.state;

    const selectProps = {
      options: options,
      value: this.selectedOption(),
      isDisabled: false,
      isLoading: loading,
      isClearable: true,
      placeholder: 'Line of Business',
      noOptionsMessage: this.noOptionsMessage,
      onChange: this.handleChange,
      onMenuOpen: this.handleMenuOpen
    };

    return (
      <Select {...selectProps} />
    );
  }
}

export default LineOfBusinessPicker;
