import React from 'react';
import styles from 'settings/styles/Benchmarks';

function BidirectionalLayout({children}) {
  if (children.length != 11) {
    return (<div>Error: Bidirectional Benchmarks must have exactly 11 elements</div>);
  }
  return (
    <div>
      <div className={'row !mx-0 ' + styles.benchmarkRow}>
        <div className='col-md-12 text-center' style={{marginBottom: '10px'}}>
          <strong>Bidirectional Benchmarks</strong>
        </div>
      </div>
      <div className={'row !mx-0 ' + styles.benchmarkRow}>
        <div className={'col-md-1 ' + styles.benchmarkLabel}>
          <strong>{window.riskEntitySettings.rating_very_high_label}</strong>
        </div>
        <div className='col-md-2 center-block'>
          {children[0]}
        </div>
        <div className='col-md-6'></div>
        <div className='col-md-2 center-block'>
          {children[1]}
        </div>
      </div>
      <div className={'row !mx-0 ' + styles.benchmarkRow}>
        <div className={'col-md-1 ' + styles.benchmarkLabel}>
          <strong>{window.riskEntitySettings.rating_high_label}</strong>
        </div>
        <div className='col-md-2 center-block'>
          {children[2]}
        </div>
        <div className='col-md-6'></div>
        <div className='col-md-2 center-block'>
          {children[3]}
        </div>
      </div>
      <div className={'row !mx-0 ' + styles.benchmarkRow}>
        <div className={'col-md-1 ' + styles.benchmarkLabel}>
          <strong>{window.riskEntitySettings.rating_moderate_label}</strong>
        </div>
        <div className='col-md-1'></div>
        <div className='col-md-2 center-block'>
          {children[4]}
        </div>
        <div className='col-md-4'></div>
        <div className='col-md-2 center-block'>
          {children[5]}
        </div>
      </div>
      <div className={'row !mx-0 ' + styles.benchmarkRow}>
        <div className={'col-md-1 ' + styles.benchmarkLabel}>
          <strong>{window.riskEntitySettings.rating_low_label}</strong>
        </div>
        <div className='col-md-2'></div>
        <div className='col-md-2 center-block'>
          {children[6]}
        </div>
        <div className='col-md-2'></div>
        <div className='col-md-2 center-block'>
          {children[7]}
        </div>
      </div>
      <div className={'row !mx-0 ' + styles.benchmarkRow}>
        <div className={'col-md-1 ' + styles.benchmarkLabel}>
          <strong>{window.riskEntitySettings.rating_very_low_label}</strong>
        </div>
        <div className='col-md-3'></div>
        <div className='col-md-2 center-block'>
          {children[8]}
        </div>
        <div className='col-md-2 center-block'>
          {children[9]}
        </div>
      </div>
      <div className={'row !mx-0 ' + styles.benchmarkRow}>
        <div className={'col-md-1 ' + styles.benchmarkLabel}>
          <strong>Midpoint</strong>
        </div>
        <div className='col-md-4'></div>
        <div className='col-md-2 center-block'>
          {children[10]}
        </div>
        <div className='col-md-4'></div>
      </div>
    </div>
  );
}

export default BidirectionalLayout;
