import _ from 'lodash';
import React from 'react';
import Routes from 'global/routes';
import Button from 'global/components/Button';
import Modal from 'react-bootstrap/Modal';
import {SacredParams} from 'settings/components/SacredParams';
import styles from '../../styles/RairedModal';
import ax from 'global/axios';

class SelfAssessmentConsiderationSetupContainer extends React.Component {

  constructor(props) {
    super(props);

    const element = document.querySelector('#react-root');

    this.state = {
      modalOpen: false,
      modalIn: false, // Workaround to fix animation transition
      submitting: false,
      timePeriodParam: element.dataset.timePeriodParam,
      riskCategoryParam: element.dataset.riskCategoryParam,
      riskAttributeParam: element.dataset.riskAttributeParam,
      raredID: element.dataset.raredId,
      touchedName: false,
      name: '',
      errorMessage: ''
    };
  }

  modalTitle = () => {
    let name = this.state.name;
    return name ? `New Self Assessment Consideration: ${name}` : 'New Self Assessment Consideration';
  };

  handleNameBlur = () => {
    this.setState({touchedName: true});
  };

  handleChange = (event) => {
    this.setState({name: event.target.value});
  };

  handleFormSubmit = async (e) => {
    if (e !== undefined) {
      e.preventDefault();
    }
    this.setState({submitting: true, errorMessage: ''});

    const sacredParams = SacredParams(this.state);

    return ax({
      url: Routes.settings_overrides_assessment_overrides_self_assessment_considerations_path(),
      method: 'post',
      data: sacredParams
    }).then((_res) => {
      var error = _res.data.error || '';
      this.setState({submitting: false, errorMessage: error});
      if (error.length == 0) {
        const url = `/${this.state.timePeriodParam}/risk_categories/${this.state.riskCategoryParam}/attributes/${this.state.riskAttributeParam}/edit_self_assessment_considerations`;
        window.location.href = url;
      }
    }).catch(this.handleError);
  };

  handleError = (error) => {
    this.setState({
      currentStep: 0,
      modalOpen: false,
      modalIn: false
    });
    ax.handleError(error);
  };

  render() {
    const { modalOpen, submitting, name, errorMessage } = this.state;
    const animationClassName = this.state.modalIn ? 'in ' : '';
    const makeAnimationWork = {
      onEntering: this.handleModalEntering,
      onExit: this.handleModalExit,
      backdropClassName: animationClassName,
      className: animationClassName + styles.modal
    };

    const nameInvalid = errorMessage.length > 0 || this.state.touchedName && (name === undefined || name.trim().length === 0);

    return (
      <div>
        <button className='btn btn-primary' onClick={this.handleShowModal}>
          New Self Assessment Consideration
        </button>
        <Modal centered animation
          style={{zIndex:1040}}
          dialogClassName={styles.kriWizardModal}
          show={modalOpen}
          onHide={this.handleModalHide}
          {...makeAnimationWork}
        >
          <Modal.Header>
            <button className='display-md-inline close' onClick={this.handleModalExit}>&times;</button>
            <h4 className='modal-title overflow-ellipsis'>{this.modalTitle()}</h4>
          </Modal.Header>
          <Modal.Body>
            <form className='simple_form' onSubmit={this.handleFormSubmit}>
              <div className={nameInvalid ? 'form-group has-error' : 'form-group'}>
                <label className='control-label string required'>
                  <abbr title='required'>*</abbr>
                    Name
                </label>
                <input
                  className='form-control required'
                  onBlur={this.handleNameBlur}
                  type="text"
                  name='name'
                  value={name || ''}
                  onChange={this.handleChange}
                  autoFocus={true}
                />
                {nameInvalid &&
                  <span className='help-block error-block'>{errorMessage}</span>
                }
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            {!submitting &&
              <a className='btn btn-link cancel-link' onClick={this.handleModalExit}>Cancel</a>
            }
            <Button onClick={this.handleFormSubmit} isDisabled={!_.trim(name)} label='Create Self Assessment Consideration' />
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  handleShowModal = (e) => {
    e.preventDefault();
    this.setState({modalOpen: true});
  };

  handleCancelClick = (e) => {
    e.preventDefault();
    this.setState({modalIn: false});
    const url = `/${this.state.timePeriodParam}/risk_categories/${this.state.riskCategoryParam}/attributes/${this.state.riskAttributeParam}/edit_self_assessment_considerations`;
    window.location.href = url;
  };

  handleModalEntering = () => {
    this.setState({modalIn: true});
  };

  handleModalExit = () => {
    this.handleModalHide();
    this.setState({modalIn: false});
  };

  handleModalHide = () => {
    this.setState({modalOpen: false});
  };
}

export default SelfAssessmentConsiderationSetupContainer;
