import React from 'react';
import Select from 'react-select';
import _ from 'lodash';

import riskCategoryDefinitionsLoader from 'reporting/section/riskCategoryDefinitionsLoader';

class RiskCategoryDefinitionPicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
      loading: false,
      error: null
    };
  }

  handleChange = (option) => {
    const value = _.get(option, 'value');
    this.props.onChange({risk_category_definition_id: value});
  };

  handleMenuOpen = () => {
    const {error} = this.state;
    if (error) {
      riskCategoryDefinitionsLoader().catch( () => riskCategoryDefinitionsLoader.cache.delete() );
      this.load();
    }
  };

  load() {
    this.setState({loading: true});
    riskCategoryDefinitionsLoader()
      .then( (options) => {
        this.setState({
          loading: false,
          error: null,
          options: options
        });
      }, (error) => {
        this.setState({
          loading: false,
          options: [],
          error: error
        });
      });
  }

  noOptionsMessage = () => {
    const {error} = this.state;
    if (error) {
      return 'Error loading ' + window.riskEntitySettings.plural_risk_category_label;
    } else {
      return 'No ' + window.riskEntitySettings.plural_risk_category_label + ' Available';
    }
  };

  selectedOption() {
    const {value} = this.props;
    const {options} = this.state;

    const found = _.find(options, _.matchesProperty('value', value));
    if (found) {
      return found;
    } else {
      // Returning undefined directly from _.find doesn't clear visible selection reliably
      return null;
    }
  }

  componentDidMount() {
    this.load();
  }

  componentDidUpdate(prevProps, prevState) {
    const {value, onChange} = this.props;
    const {options} = this.state;

    if (options != prevState.options) {
      const found = this.selectedOption();
      // Clear selection when not in available options
      if (value && !found) {
        onChange({risk_category_definition_id: null});
      }
    }
  }

  render() {
    const {loading, options} = this.state;

    const selectProps = {
      options: options,
      value: this.selectedOption(),
      isDisabled: false,
      isLoading: loading,
      isClearable: true,
      placeholder: window.riskEntitySettings.risk_category_label,
      noOptionsMessage: this.noOptionsMessage,
      onChange: this.handleChange,
      onMenuOpen: this.handleMenuOpen
    };

    return (
      <Select {...selectProps} />
    );
  }
}

export default RiskCategoryDefinitionPicker;
