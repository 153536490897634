import React from 'react';
import PropTypes from 'prop-types';

class CheckedDisplay extends React.Component {
  render() {
    const checked = this.props.checked;
    if(checked) {
      return <i className='fa fa-check'></i>;
    } else {
      return <i className='fa fa-times'></i>;
    }
  }
}

CheckedDisplay.propTypes = {
  checked: PropTypes.bool.isRequired
};

export default CheckedDisplay;
