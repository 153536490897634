import _ from 'lodash';
import React from 'react';
import Select from 'react-select';
import AsyncPaginate from 'react-select-async-paginate';
import Button from 'global/components/Button';
import MonthlySwitch from 'benchmarks/components/MonthlySwitch';
import QuarterlySwitch from 'benchmarks/components/QuarterlySwitch';
import QuestionInfoTooltip from 'global/components/QuestionInfoTooltip';
import LimitTypes from 'settings/components/LimitTypes';
import IndicatorTypes from 'settings/components/IndicatorTypes';
import {RairedParams} from 'settings/components/RairedParams';
import CeilingForm from 'benchmarks/components/CeilingForm';
import FloorForm from 'benchmarks/components/FloorForm';
import SelectDisplay from 'benchmarks/components/SelectDisplay';
import AbsoluteValueForm from 'benchmarks/components/AbsoluteValueForm';
import BidirectionalForm from 'benchmarks/components/BidirectionalForm';
import ValidationMessage from 'benchmarks/components/ValidationMessage';
import {ifSuccessful} from 'global/promise';
import ax from 'global/axios';

class RiskAttributeIndicatorNewForm extends React.Component {

  constructor(props) {
    super(props);

    const element = document.querySelector('#react-root');

    this.state = {
      monthlyFeatureFlag: window.riskEntitySettings.monthly_kri,
      submitting: false,
      raredID: element.dataset.raredId,
      raredName: element.dataset.raredName,
      canEditObject: element.dataset.editableObject == 'true',
      enabledQuarterly: true,
      enabledMonthly: false,
      quarterlyBenchmarks: null,
      monthlyBenchmarks: null,
      limitType: { value: 'ceiling', label: 'Ceiling' },
      areaOfRisk: { value: 'ftra', label: 'FTRA' },
      ratingSelect: { value: 'total_risk_rating', label: 'Total Risk Rating' },
      indicatorType: { value: 'data_input_rated', label: 'Data Input Rated' },
      validation: {
        name: '',
        dired: '',
        indicatorType: '',
        riskEntity: '',
        typeable: '',
        ratingSelect: '',
        monthlyBenchmarks: '',
        quarterlyBenchmarks: ''
      },
    };

    this.handleDiredChange = this.handleDiredChange.bind(this);
    this.loadDireds = this.loadDireds.bind(this);
    this.handleRiskEntityChange = this.handleRiskEntityChange.bind(this);
    this.loadRiskEntities = this.loadRiskEntities.bind(this);
    this.handleRiskObjectChange = this.handleRiskObjectChange.bind(this);
    this.loadRiskObjects = this.loadRiskObjects.bind(this);
  }

  async loadDireds(search, loadedOptions, { page }) {
    const response = await ax({
      url: '/settings/overrides/indicator_overrides/data_input_risk_entity_definitions',
      params: {
        q: search,
        page: page
      },
      method: 'get',
    }).then((res) => {
      return res.data;
    }).catch(error => {
      ax.handleError(error);
    });

    return {
      options: response.results,
      hasMore: response.has_more,
      additional: {
        page: page + 1,
      },
    };
  }

  async loadRiskEntities(search, loadedOptions, { page, areaOfRisk }) {
    const response = await ax({
      url: '/settings/overrides/indicator_overrides/risk_entities',
      params: {
        name: search,
        area_of_risk: areaOfRisk.value,
        page: page
      },
      method: 'get',
    }).then((res) => {
      return res.data;
    }).catch(error => {
      ax.handleError(error);
    });

    return {
      options: response.results,
      hasMore: response.has_more,
      additional: {
        page: page + 1,
        areaOfRisk: areaOfRisk,
      },
    };
  }

  handleRiskEntityChange(event) {
    const label = event === null ? '' : event.label;
    const value = event === null ? null : event.value;

    this.setState({
      riskEntity: {
        label: label,
        value: value
      },
      riskObject: {
        label: '',
        value: ''
      },
      sourceRatingType: '',
      name: ''
    });
  }

  async loadRiskObjects(search, loadedOptions, { page, areaOfRisk, riskEntity, sourceRatingType }) {
    const response = await ax({
      url: '/settings/overrides/indicator_overrides/risk_objects',
      params: {
        name: search,
        area_of_risk: areaOfRisk.value,
        risk_entity: riskEntity?.value,
        source_rating_type: sourceRatingType?.value,
        page: page
      },
      method: 'get',
    }).then((res) => {
      return res.data;
    }).catch(error => {
      ax.handleError(error);
    });

    return {
      options: response.results,
      hasMore: response.has_more,
      additional: {
        page: page + 1,
        areaOfRisk: areaOfRisk,
        riskEntity: riskEntity,
        sourceRatingType: sourceRatingType,
      },
    };
  }

  handleRiskObjectChange(event) {
    const label = event === null ? '' : event.label;
    const value = event === null ? null : event.value;
    const { areaOfRisk, riskEntity } = this.state;

    this.setState({
      riskObject: {
        label: label,
        value: value
      }
    }, function handleStateUpdate() {
      if (areaOfRisk.value === 'rmf') {
        this.setState({ name: `${riskEntity.label} ${label} [${areaOfRisk.value.toUpperCase()}]` });
      } else if (areaOfRisk.value === 'ftra') {
        this.setState({ name: `${event.label} [${riskEntity.label} ${areaOfRisk.value.toUpperCase()}]` });
      } else {
        this.setState({ name: `${label} [${areaOfRisk.value.toUpperCase()}]` });
      }
    });
  }

  handleDiredChange(event) {
    const label = event === null ? '' : event.label;
    const value = event === null ? null : event.value;

    const valueFormat = event === null ? null : event.valueFormat;
    const selectOptions = event === null ? null : event.selectOptions;

    this.resetBenchmarks(valueFormat);

    this.setState({
      selectedDataInput: {
        label: label,
        value: value
      },
      dataInputName: label,
      dataInputSelectOptions: selectOptions,
    });
  }

  handleLimitTypeChange = (limitType) => {
    this.setState({limitType});
  };

  handleObjectTypeChange = (areaOfRisk) => {
    this.setState({areaOfRisk}, function () {
      let ratingSelectOption;

      switch(this.state.areaOfRisk.value) {
      case 'rcsa':
        ratingSelectOption = { label: 'Inherent Risk Rating', value: 'inherent_risk_rating' };

        break;
      case 'rmf':
        ratingSelectOption = { label: 'Composite Actual Maturity', value: 'composite_actual_maturity' };

        break;
      case 'pra':
        ratingSelectOption = { label: 'Quantity of Risk Rating', value: 'quantity_of_risk_rating' };

        break;
      default:
        ratingSelectOption = { label: 'Total Risk Rating', value: 'total_risk_rating' };
      }

      this.setState({
        ratingSelect: ratingSelectOption,
        sourceRatingType: '',
        name: '',
        riskObject: {
          label: '',
          value: ''
        },
        riskEntity: {
          label: '',
          value: ''
        },
        validation: {
          name: '',
          dired: '',
          indicatorType: '',
          riskEntity: '',
          typeable: '',
          ratingSelect: '',
          monthlyBenchmarks: '',
          quarterlyBenchmarks: ''
        }
      });
    });
  };

  handleSourceRatingTypeChange = (sourceRatingType) => {
    this.setState({
      sourceRatingType,
      name: '',
      riskObject: { label: '', value: '' }
    });
  };

  handleRatingSelectionChange = (ratingSelect) => {
    this.setState({ratingSelect});
  };

  handleIndicatorTypeChange = (indicatorType) => {
    this.setState({indicatorType, name: '', riskObject: { label: '', value: '' }});
    this.handleDiredChange(null);
  };

  handleQuarterlyBenchmarksChange = (benchmarks) => {
    this.setState({quarterlyBenchmarks: benchmarks});
  };

  handleMonthlyBenchmarksChange = (benchmarks) => {
    this.setState({monthlyBenchmarks: benchmarks});
  };

  resetBenchmarks = (dataInputValueFormat) => {
    const enabledMonthly = this.state.enabledMonthly;

    var limitType = LimitTypes[0];
    var quarterlyBenchmarks = null;
    var monthlyBenchmarks = null;

    if (dataInputValueFormat === 5) {
      limitType = {
        value: 'select',
        label: 'Select'
      };
      quarterlyBenchmarks = [1.0, 2.0, 3.0, 4.0, 5.0, 6.0, null];
      if (enabledMonthly) {
        monthlyBenchmarks = [1.0, 2.0, 3.0, 4.0, 5.0, 6.0, null];
      } else {
        monthlyBenchmarks = null;
      }
    }

    this.setState({
      limitType,
      quarterlyBenchmarks,
      monthlyBenchmarks
    });
  };

  indicatorsUrl() {
    return `/settings/overrides/indicator_overrides/risk_attribute_indicator_risk_entity_definitions?risk_attribute_risk_entity_definition_id=${this.state.raredID}`;
  }

  cancelButtonVisible() {
    return !this.state.submitting;
  }

  handleChange = (event) => {
    this.setState({[event.target.name]: event.target.value});
  };

  validate = () => {
    return ax({
      url: '/settings/overrides/indicator_overrides/validate_and_format',
      method: 'post',
      data: RairedParams(this.state)
    }).then((res) => {
      const {messages} = res.data.errors;
      this.setState({
        validation: {
          name: _.get(messages, 'name[0]', ''),
          dired: _.get(messages, 'data_input_risk_entity_definition_id[0]', ''),
          indicatorType: _.get(messages, 'indicator_type[0]', ''),
          riskEntity: this.state.riskEntity?.value ? '' : 'can\'t be blank',
          typeable: _.get(messages, 'typeable_id[0]', ''),
          ratingSelect: _.get(messages, 'rating_type[0]', ''),
          monthlyBenchmarks: _.get(messages, 'monthly_benchmarks[0]', ''),
          quarterlyBenchmarks: _.get(messages, 'quarterly_benchmarks[0]', '')
        }
      });
      return _.isEmpty(messages);
    });
  };

  submit = () => {
    this.setState({submitting: true});
    return ax({
      url: '/settings/overrides/indicator_overrides/risk_attribute_indicator_risk_entity_definitions',
      method: 'post',
      data: RairedParams(this.state)
    });
  };

  redirect = () => {
    window.location.href = this.indicatorsUrl();
  };

  handleFormSubmit = () => {
    return this.validate()
      .then(ifSuccessful(this.submit))
      .then(ifSuccessful(this.redirect))
      .catch(ax.handleError);
  };

  handleCancelClick = (e) => {
    e.preventDefault();
    window.location.href = this.indicatorsUrl();
  };

  indicatorTypeField = () => {
    const {
      validation,
      indicatorType
    } = this.state;

    return (
      <>
        <div className={validation.indicatorType ? 'form-group has-error' : 'form-group'}>
          <div className='select'>
            <label htmlFor='react-select-3-input' className='control-label'>
              Type
            </label>
            <QuestionInfoTooltip
              content="Choose whether the KRI is driven from a data input, or another risk object."
              html={true}
              placement='bottom'
              trigger='hover'
            />
            <Select
              id='indicatorTypes'
              className='indicatorType'
              classNamePrefix='indicatorType'
              value={indicatorType}
              onChange={this.handleIndicatorTypeChange}
              options={IndicatorTypes}
              isClearable={false}
              styles={{ menuList: (provided, _state) => ({ ...provided, maxHeight: 200 }) }}
            />
            {validation.indicatorType &&
              <span className='help-block error-block'>{validation.indicatorType}</span>
            }
          </div>
        </div>
      </>
    );
  };

  rcsaFields = () => {
    const {
      riskObject,
      areaOfRisk,
      ratingSelect,
      validation
    } = this.state;

    const ratingSelections = [
      { label: 'Inherent Risk Rating', value: 'inherent_risk_rating' },
      { label: 'Residual Risk Rating', value: 'residual_risk_rating' }
    ];

    return (
      <>
        <div className={validation.typeable ? 'form-group has-error' : 'form-group'}>
          <label htmlFor='react-select-2-input' className="select control-label">
            <abbr title='required'>*</abbr>
            Risk Object
          </label>
          <AsyncPaginate
            debounceTimeout={300}
            id={'risk_objects'}
            className='riskObject'
            classNamePrefix='riskObject'
            value={riskObject}
            onChange={this.handleRiskObjectChange}
            loadOptions={this.loadRiskObjects}
            additional={{page: 1, areaOfRisk: areaOfRisk}}
            isClearable={true}
            styles={{ menuList: (provided, _state) => ({ ...provided, maxHeight: 200 }) }}
          />
          {validation.typeable &&
            <span className='help-block error-block'>{validation.typeable}</span>
          }
        </div>
        <label htmlFor='react-select-2-input' className="select control-label">
          <abbr title='required'>*</abbr>
          Rating Select
        </label>
        <div className={validation.ratingSelect ? 'form-group has-error' : 'form-group'}>
          <Select
            id='ratingSelect'
            className='ratingSelect'
            classNamePrefix='ratingSelect'
            value={ratingSelect}
            onChange={this.handleRatingSelectionChange}
            options={ratingSelections}
            isClearable={false}
            styles={{ menuList: (provided, _state) => ({ ...provided, maxHeight: 200 }) }}
          />
          {validation.ratingSelect &&
            <span className='help-block error-block'>{validation.ratingSelect}</span>
          }
        </div>
        { this.sharedFields() }
      </>
    );
  };

  ftraFields = () => {
    const {
      riskEntity,
      riskObject,
      areaOfRisk,
      sourceRatingType,
      ratingSelect,
      validation
    } = this.state;

    const  ratingSelections = [
      { label: 'Total Risk Rating', value: 'total_risk_rating' }
    ];

    const sourceRatingTypes = [
      { label: 'Composite Risk Rating', value: 'composite'  },
      { label: 'Risk Category Rating', value: 'risk_category' }
    ];

    return (
      <>
        <div className={validation.riskEntity ? 'form-group has-error' : 'form-group'}>
          <label htmlFor='react-select-2-input' className="select control-label">
            <abbr title='required'>*</abbr>
            Risk Entity
          </label>
          <AsyncPaginate
            key={areaOfRisk?.value}
            debounceTimeout={300}
            id={'risk_entities'}
            className='riskEntity'
            classNamePrefix='riskEntity'
            value={riskEntity}
            onChange={this.handleRiskEntityChange}
            loadOptions={this.loadRiskEntities}
            additional={{page: 1, areaOfRisk: areaOfRisk}}
            isClearable={true}
            styles={{ menuList: (provided, _state) => ({ ...provided, maxHeight: 200 }) }}
          />
          {validation.riskEntity &&
            <span className='help-block error-block'>{validation.riskEntity}</span>
          }
        </div>
        {riskEntity?.value &&
          <div className='form-group'>
            <label htmlFor='react-select-2-input' className="select control-label">
              <abbr title='required'>*</abbr>
              Source Rating Type
            </label>
            <Select
              id='sourceRatingType'
              className='sourceRatingType'
              classNamePrefix='sourceRatingType'
              value={sourceRatingType}
              onChange={this.handleSourceRatingTypeChange}
              options={sourceRatingTypes}
              isClearable={false}
              styles={{ menuList: (provided, _state) => ({ ...provided, maxHeight: 200  })  }}
            />
          </div>
        }
        {sourceRatingType?.value &&
          <div className={validation.typeable ? 'form-group has-error' : 'form-group'}>
            <label htmlFor='react-select-2-input' className="select control-label">
              <abbr title='required'>*</abbr>
              Risk Object
            </label>
            <QuestionInfoTooltip
              content="Eligible risk objects are from the latest completed time period relative to the selected time period."
              html={true}
              placement='bottom'
              trigger='hover'
            />
            <AsyncPaginate
              key={sourceRatingType?.value}
              debounceTimeout={300}
              id={'risk_objects'}
              className='riskObject'
              classNamePrefix='riskObject'
              value={riskObject}
              onChange={this.handleRiskObjectChange}
              loadOptions={this.loadRiskObjects}
              additional={{page: 1, areaOfRisk: areaOfRisk, riskEntity: riskEntity, sourceRatingType: sourceRatingType}}
              isClearable={true}
              styles={{ menuList: (provided, _state) => ({ ...provided, maxHeight: 200 }) }}
            />
            {validation.typeable &&
              <span className='help-block error-block'>{validation.typeable}</span>
            }
          </div>
        }

        <label htmlFor='react-select-2-input' className="select control-label">
          <abbr title='required'>*</abbr>
          Rating Select
        </label>

        <div className={validation.ratingSelect ? 'form-group has-error' : 'form-group'}>
          <Select
            id='ratingSelect'
            className='ratingSelect'
            classNamePrefix='ratingSelect'
            value={ratingSelect}
            onChange={this.handleRatingSelectionChange}
            options={ratingSelections}
            isClearable={false}
            styles={{ menuList: (provided, _state) => ({ ...provided, maxHeight: 200 }) }}
          />
          {validation.ratingSelect &&
            <span className='help-block error-block'>{validation.ratingSelect}</span>
          }
        </div>
        { this.sharedFields() }
      </>
    );
  };

  riskEntityRiskObjectFields = () => {
    const {
      riskEntity,
      riskObject,
      areaOfRisk,
      ratingSelect,
      validation
    } = this.state;

    var ratingSelections;
    if (areaOfRisk.value == 'rmf') {
      ratingSelections = [
        { label: 'Composite Actual Maturity', value: 'composite_actual_maturity' }
      ];
    } else {
      ratingSelections = [
        { label: 'Quantity of Risk Rating', value: 'quantity_of_risk_rating' }
      ];
    }

    return (
      <>
        <div className={validation.riskEntity ? 'form-group has-error' : 'form-group'}>
          <label htmlFor='react-select-2-input' className="select control-label">
            <abbr title='required'>*</abbr>
            Risk Entity
          </label>
          <AsyncPaginate
            key={areaOfRisk?.value}
            debounceTimeout={300}
            id={'risk_entities'}
            className='riskEntity'
            classNamePrefix='riskEntity'
            value={riskEntity}
            onChange={this.handleRiskEntityChange}
            loadOptions={this.loadRiskEntities}
            additional={{page: 1, areaOfRisk: areaOfRisk}}
            isClearable={true}
            styles={{ menuList: (provided, _state) => ({ ...provided, maxHeight: 200 }) }}
          />
          {validation.riskEntity &&
            <span className='help-block error-block'>{validation.riskEntity}</span>
          }
        </div>
        {riskEntity?.value &&
          <div className={validation.typeable ? 'form-group has-error' : 'form-group'}>
            <label htmlFor='react-select-2-input' className="select control-label">
              <abbr title='required'>*</abbr>
              Risk Object
            </label>
            <QuestionInfoTooltip
              content="Eligible risk objects are from the latest completed time period relative to the selected time period."
              html={true}
              placement='bottom'
              trigger='hover'
            />
            <AsyncPaginate
              key={riskEntity?.value}
              debounceTimeout={300}
              id={'risk_objects'}
              className='riskObject'
              classNamePrefix='riskObject'
              value={riskObject}
              onChange={this.handleRiskObjectChange}
              loadOptions={this.loadRiskObjects}
              additional={{page: 1, areaOfRisk: areaOfRisk, riskEntity: riskEntity}}
              isClearable={true}
              styles={{ menuList: (provided, _state) => ({ ...provided, maxHeight: 200 }) }}
            />
            {validation.typeable &&
              <span className='help-block error-block'>{validation.typeable}</span>
            }
          </div>
        }

        <label htmlFor='react-select-2-input' className="select control-label">
          <abbr title='required'>*</abbr>
          Rating Select
        </label>

        <div className={validation.ratingSelect ? 'form-group has-error' : 'form-group'}>
          <Select
            id='ratingSelect'
            className='ratingSelect'
            classNamePrefix='ratingSelect'
            value={ratingSelect}
            onChange={this.handleRatingSelectionChange}
            options={ratingSelections}
            isClearable={false}
            styles={{ menuList: (provided, _state) => ({ ...provided, maxHeight: 200 }) }}
          />
          {validation.ratingSelect &&
            <span className='help-block error-block'>{validation.ratingSelect}</span>
          }
        </div>
        { this.sharedFields() }
      </>
    );
  };

  riskObjectFields = () => {
    const {
      areaOfRisk,
    } = this.state;

    const areasOfRisk = [
      { label: 'FTRA', value: 'ftra' },
      { label: 'PRA', value: 'pra'  },
      { label: 'RCSA', value: 'rcsa'  },
      { label: 'RMF', value: 'rmf'  }
    ];

    return (
      <>
        <label htmlFor='react-select-2-input' className="select control-label">
          <abbr title='required'>*</abbr>
          Area of Risk
        </label>
        <Select
          id='areaOfRisk'
          className='areaOfRisk'
          classNamePrefix='areaOfRisk'
          value={areaOfRisk}
          onChange={this.handleObjectTypeChange}
          options={areasOfRisk}
          isClearable={false}
          styles={{ menuList: (provided, _state) => ({ ...provided, maxHeight: 200  })  }}
        />
        <br/>
        { areaOfRisk.value == 'rcsa' &&
          this.rcsaFields()
        }
        { areaOfRisk.value == 'ftra' &&
          this.ftraFields()
        }
        { (areaOfRisk.value != 'rcsa' && areaOfRisk.value != 'ftra') &&
          this.riskEntityRiskObjectFields()
        }
      </>
    );
  };

  dataInputFields = () => {
    const {
      monthlyFeatureFlag,
      limitType,
      selectedDataInput,
      enabledMonthly,
      enabledQuarterly,
      monthlyBenchmarks,
      quarterlyBenchmarks,
      validation,
      dataInputSelectOptions
    } = this.state;

    return (
      <>
        <div className={validation.dired ? 'has-error form-group select' : 'form-group select'}>
          <label htmlFor='react-select-2-input' className="select control-label">
            <abbr title='required'>*</abbr>
            Data Input
          </label>
          <AsyncPaginate
            debounceTimeout={300}
            id={'direds'}
            className='dired'
            classNamePrefix='dired'
            value={selectedDataInput}
            onChange={this.handleDiredChange}
            loadOptions={this.loadDireds}
            additional={{page: 1}}
            isClearable={true}
            styles={{ menuList: (provided, _state) => ({ ...provided, maxHeight: 200 }) }}
          />
          {validation.dired &&
            <span className='help-block error-block'>{validation.dired}</span>}
        </div>
        { this.sharedFields() }
        { limitType.value != 'select' &&
          <div className='select'>
            <label htmlFor='react-select-3-input' className='control-label'>
              Benchmark Type
            </label>
            <QuestionInfoTooltip
              content="<div class='text-left'><strong>Ceiling:</strong> Risk increases as value increases.<br/><br/><strong>Floor:</strong> Risk decreases as value increases.<br/><br/><strong>Absolute Value:</strong> Same as ceiling but using positive indicator value.<br/><br/><strong>Bidirectional:</strong> Risk is lowest at the midpoint and increases outward in both directions.</div>"
              html={true}
              placement='bottom'
              trigger='hover'
            />
            <Select
              id='limitTypes'
              className='limitType'
              classNamePrefix='limitType'
              value={limitType}
              onChange={this.handleLimitTypeChange}
              options={LimitTypes}
              isClearable={false}
              styles={{ menuList: (provided, _state) => ({ ...provided, maxHeight: 200 }) }}
            />
            <br/>
          </div>
        }
        <br/>
        <div id='quarterly-benchmarks'>
          { monthlyFeatureFlag &&
            <QuarterlySwitch value={enabledQuarterly} onChange={this.handleChange} />
          }
          { enabledQuarterly &&
            <>
              <ValidationMessage>{validation.quarterlyBenchmarks}</ValidationMessage>
              {limitType.value == 'select' &&
                <>
                  <div className="alert-message info-alert-message">
                    <p>The list of selectable options for this indicator can be configured via the data input after this page has been submitted.</p>
                  </div>
                  <SelectDisplay selectOptions={dataInputSelectOptions} />
                </>
              }
              {limitType.value == 'ceiling' &&
                <CeilingForm benchmarks={quarterlyBenchmarks} onChange={this.handleQuarterlyBenchmarksChange} />
              }
              {limitType.value == 'floor' &&
                <FloorForm benchmarks={quarterlyBenchmarks} onChange={this.handleQuarterlyBenchmarksChange} />
              }
              {limitType.value == 'absolute_value' &&
                <AbsoluteValueForm benchmarks={quarterlyBenchmarks} onChange={this.handleQuarterlyBenchmarksChange} />
              }
              {limitType.value == 'bidirectional' &&
                <BidirectionalForm benchmarks={quarterlyBenchmarks} onChange={this.handleQuarterlyBenchmarksChange} />
              }
            </>
          }
        </div>
        <div id='monthly-benchmarks'>
          { monthlyFeatureFlag &&
            <MonthlySwitch value={enabledMonthly} onChange={this.handleChange} />
          }
          { enabledMonthly &&
            <>
              <ValidationMessage>{validation.monthlyBenchmarks}</ValidationMessage>
              {limitType.value == 'select' &&
                <>
                  <div className="alert-message info-alert-message">
                    <p>The list of selectable options for this indicator can be configured via the data input after this page has been submitted.</p>
                  </div>
                  <SelectDisplay selectOptions={dataInputSelectOptions} />
                </>
              }
              {limitType.value == 'ceiling' &&
                <CeilingForm benchmarks={monthlyBenchmarks} onChange={this.handleMonthlyBenchmarksChange} />
              }
              {limitType.value == 'floor' &&
                <FloorForm benchmarks={monthlyBenchmarks} onChange={this.handleMonthlyBenchmarksChange} />
              }
              {limitType.value == 'absolute_value' &&
                <AbsoluteValueForm benchmarks={monthlyBenchmarks} onChange={this.handleMonthlyBenchmarksChange} />
              }
              {limitType.value == 'bidirectional' &&
                <BidirectionalForm benchmarks={monthlyBenchmarks} onChange={this.handleMonthlyBenchmarksChange} />
              }
            </>
          }
        </div>
      </>
    );
  };

  sharedFields = () => {
    const {
      indicatorType,
      name,
      description,
      definition,
      validation
    } = this.state;

    return (
      <>
        <div className={validation.name ? 'form-group has-error' : 'form-group'}>
          <label htmlFor='name' className='control-label string required'>
            <abbr title='required'>*</abbr>
              Name
          </label>
          <input
            className={validation.name ? 'error form-control required' : 'form-control required'}
            type="text"
            name='name'
            id='name'
            value={name || ''}
            onChange={this.handleChange}
            autoFocus={true}
            disabled={indicatorType.value == 'risk_object_rated'}
          />
          {validation.name &&
            <span className='help-block error-block'>{validation.name}</span>
          }
        </div>
        <div className='form-group'>
          <label htmlFor='description' className='control-label'>Description</label>
          <input className='form-control' id='description' type="text" name='description' value={description || ''} onChange={this.handleChange} />
        </div>
        <div className='form-group'>
          <label htmlFor='definition' className='control-label'>Definition</label>
          <input className='form-control' id='definition' type="text" name='definition' value={definition || ''} onChange={this.handleChange} />
        </div>
        <div className='form-group'>
          <label htmlFor='risk-attribute' className='control-label'>
            {window.riskEntitySettings.inherent_risk_attribute_label}
            <QuestionInfoTooltip
              content="The risk attribute is determined from the previous selection made at the list of available risk attributes."
              html={false}
              placement='bottom'
              trigger='hover'
            />
          </label>
          <input className='form-control' id='risk-attribute' type="text" name='rared' value={this.state.raredName} disabled />
        </div>
      </>
    );
  };

  render() {
    const {
      indicatorType,
      submitting,
      canEditObject
    } = this.state;

    return (
      <div>
        <form className='simple_form'>
          <div className="col-md-12">
            <div className="panel">
              <div className="panel-heading">
                <span className="panel-title">Create Indicator</span>
              </div>
              <div className="panel-body">
                { canEditObject &&
                  this.indicatorTypeField()
                }
                { indicatorType.value == 'data_input_rated' &&
                  this.dataInputFields()
                }
                { indicatorType.value == 'risk_object_rated' &&
                  this.riskObjectFields()
                }
                <br/>
                <Button
                  onClick={this.handleFormSubmit}
                  isDisabled={submitting}
                  isAsync={true}
                  label={'Create Indicator'}
                />
                {this.cancelButtonVisible() &&
                  <a href={this.indicatorsUrl()} className='btn btn-link cancel-link' onClick={this.handleCancelClick}>Cancel</a>}
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default RiskAttributeIndicatorNewForm;
