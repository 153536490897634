import React from 'react';
import AddTimePeriodContainer from 'risk_management/components/time_period/AddTimePeriodContainer';

class TimePeriodContainer extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <AddTimePeriodContainer />
      </div>
    );
  }
}

export default TimePeriodContainer;
