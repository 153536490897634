import React from 'react';
import styles from 'global/styles/Switch';

function Switch({name, value, onChange}) {
  // checked is renamed to value to be more consistent with other input types
  const handler = (e) => {
    onChange({target: {name: name, value: e.target.checked}});
  };

  return (
    <label className={styles.slot}>
      <input
        type="checkbox"
        name={name}
        checked={value}
        onChange={handler}
      />
      <span className={[styles.switch, value ? styles.on : styles.off].join(' ')} />
    </label>
  );
}

export default Switch;
