/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import 'global';

import React from 'react';
import ReactDOM from 'react-dom';
import AppRouter from 'global/app_router';
import DataInputMonthPicker from 'risk_management/components/DataInputMonthPicker';
import ReportMonthPicker from 'risk_management/components/ReportMonthPicker';
import BenchmarksForm from 'risk_management/components/RiskAttributeIndicatorBenchmarksForm';
import SlidingBar from 'risk_management/components/SlidingBar';
import RiskEntitiesMenu from '../settings/components/RiskEntitiesMenu';

function onPageLoad() {
  const reactRoot = document.querySelector('#react-root');
  if (reactRoot) {
    ReactDOM.render(<AppRouter />, reactRoot);
  }

  const dataInputMonthPicker = document.querySelector('#data-input-month-picker');
  if (dataInputMonthPicker) {
    ReactDOM.render(<DataInputMonthPicker />, dataInputMonthPicker);
  }

  const reportMonthPicker = document.querySelector('#report-month-picker');
  if (reportMonthPicker) {
    ReactDOM.render(<ReportMonthPicker />, reportMonthPicker);
  }

  const benchmarksForm = document.querySelector('#benchmarks-form');
  if (benchmarksForm) {
    ReactDOM.render(<BenchmarksForm />, benchmarksForm);
  }

  const indicatorIdsElement = document.querySelector('#indicator-ids');
  if (indicatorIdsElement) {
    const indicatorIds = JSON.parse(indicatorIdsElement.dataset.ids);
    for(const id of indicatorIds) {
      let slidingBarContainer = document.querySelector('#sliding-bar-container-' + id);
      if (slidingBarContainer) {
        ReactDOM.render(<SlidingBar id={id} />, slidingBarContainer);
      }
    }
  }

  const riskEntitiesMenu = document.querySelector('#risk_entities_sub_menu');
  if (riskEntitiesMenu) {
    ReactDOM.render(<RiskEntitiesMenu states={JSON.parse(riskEntitiesMenu.getAttribute('data-states'))} />, riskEntitiesMenu);
  }
}

function unmountComponents() {
  const reactRoot = document.querySelector('#react-root');
  if (reactRoot) {
    ReactDOM.unmountComponentAtNode(reactRoot);
  }

  const dataInputMonthPicker = document.querySelector('#data-input-month-picker');
  if (dataInputMonthPicker) {
    ReactDOM.unmountComponentAtNode(dataInputMonthPicker);
  }

  const reportMonthPicker = document.querySelector('#report-month-picker');
  if (reportMonthPicker) {
    ReactDOM.unmountComponentAtNode(reportMonthPicker);
  }
}

document.addEventListener('page:before-unload', unmountComponents);
document.addEventListener('DOMContentLoaded', onPageLoad);
document.addEventListener('page:load', onPageLoad);
document.addEventListener('slidingBar:anchorDivLoaded', onPageLoad);

