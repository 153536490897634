/*
 * ifSuccessful wraps a then callback to only run when passed a truthy result
 *
 * E.g.
 * this.validate()
 *   .then(ifSuccessful(this.submit))
 *   .then(ifSuccessful(this.redirect))
 *   .catch(ax.handleError);
 *
 * This pattern allows for a non-exceptional failure to stop a chain of actions
 * without the individual actions need to check the previous result.
 *
 */

function ifSuccessful(callback) {
  return function(success) {
    if (success) {
      return callback();
    }
  };
}

export {ifSuccessful};
