import React from 'react';
import Table from 'benchmarks/components/Table';
import Input from 'benchmarks/components/Input';
import SoftInput from 'benchmarks/components/SoftInput';

const isIncremental = !window.riskEntitySettings.indicator_flat_rating_calc;
const ratingSymbols = {veryLow: '>', low: '>', moderate: '>', high: '>', veryHigh: '≤'};

function parse(string) {
  const x = parseFloat(string);
  return isNaN(x) ? null : x;
}

class FloorForm extends React.Component {
  constructor(props) {
    super(props);
    const initialEmptyBenchmarks = ['', '', '', '', '', '', ''];
    this.state = {benchmarks: props.benchmarks || initialEmptyBenchmarks};
  }

  handler(index, e) {
    const target = e.target;
    const value = target.value.trim();
    const benchmarks = this.state.benchmarks;
    benchmarks[index] = value;
    this.setState({benchmarks}, this.notifier);
  }

  notifier = () => {
    const {onChange} = this.props;
    const {benchmarks} = this.state;
    if (onChange) {
      const parsed = benchmarks.map(parse);
      onChange(parsed);
    }
  };

  render() {
    const {benchmarks} = this.state;
    return (
      <Table>
        {isIncremental &&
            <SoftInput
              name='softLow'
              value={benchmarks[1]}
              onChange={this.handler.bind(this, 1)}
            />}
        <Input
          symbol={ratingSymbols.veryLow}
          name='veryLow'
          value={benchmarks[2]}
          onChange={this.handler.bind(this, 2)}
        />
        <Input
          symbol={ratingSymbols.low}
          name='low'
          value={benchmarks[3]}
          onChange={this.handler.bind(this, 3)}
        />
        <Input
          symbol={ratingSymbols.moderate}
          name='moderate'
          value={benchmarks[4]}
          onChange={this.handler.bind(this, 4)}
        />
        <Input
          symbol={ratingSymbols.high}
          name='high'
          value={benchmarks[5]}
          onChange={this.handler.bind(this, 5)}
        />
        <Input
          symbol={ratingSymbols.veryHigh}
          name='veryHigh'
          value={benchmarks[5]}
          disabled={true}
        />
        {isIncremental &&
          <SoftInput
            name="softHigh"
            value={benchmarks[6]}
            onChange={this.handler.bind(this, 6)}
          />}
      </Table>
    );
  }
}
export default FloorForm;
