import React from 'react';
import PropTypes from 'prop-types';

class QuestionInfoTooltip extends React.Component {
  render() {
    const {
      content,
      html,
      placement,
      trigger,
    } = this.props;

    return (
      <>
        <i
          style={{marginRight: '5px'}}
          className="label-help fa fa-question-circle"
          data-toggle="tooltip"
          data-placement={placement}
          data-trigger={trigger}
          data-html={html}
          tabIndex='-1'
          data-title={content}
        ></i>
      </>
    );
  }
}

QuestionInfoTooltip.propTypes = {
  content: PropTypes.string,
  html: PropTypes.bool,
  placement: PropTypes.string,
  trigger: PropTypes.string
};

export default QuestionInfoTooltip;
