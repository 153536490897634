import React from 'react';
import PropTypes from 'prop-types';
import styles from 'global/styles/DataTable';
import CheckedDisplay from 'settings/components/CheckedDisplay';

class DepartmentRiskEntityDefinitionRow extends React.Component {
  render() {
    const record = this.props.record;
    return (
      <tr
        ref={this.props.provided.innerRef}
        className={styles.draggable}
        {...this.props.provided.draggableProps}
      >
        <td
          className={styles.controlColumn}
          {...this.props.provided.dragHandleProps}
        >
          <span className='grip-handle'></span>
        </td>
        <td>{record.name}</td>
        <td className={styles.leftBorder}>{record.riskCategories}</td>
        <td className={styles.leftBorder}><CheckedDisplay checked={record.enabled} /></td>
      </tr>
    );
  }

  static headers() {
    return ['Name', window.riskEntitySettings.plural_risk_category_label, 'Enabled'];
  }
}

DepartmentRiskEntityDefinitionRow.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    riskCategories: PropTypes.string,
    enabled: PropTypes.bool
  }),
};

export default DepartmentRiskEntityDefinitionRow;
