import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import GlyphiconButton from 'reporting/package/GlyphiconButton';
import Demo from 'reporting/section/Demo';
import Example from 'reporting/section/Example';
import ExampleAdvanced from 'reporting/section/ExampleAdvanced';
import DomainSummaryReport from 'reporting/section/DomainSummaryReport';
import DomainDetailReport from 'reporting/section/DomainDetailReport';
import KRIReport from 'reporting/section/KRIReport';
import KRIReportAdvanced from 'reporting/section/KRIReportAdvanced';
import KRITrendReport from 'reporting/section/KRITrendReport';
import KRITrendReportAdvanced from 'reporting/section/KRITrendReportAdvanced';
import ProfileReport from 'reporting/section/ProfileReport';
import ProfileReportAdvanced from 'reporting/section/ProfileReportAdvanced';
import ProfileTrendReport from 'reporting/section/ProfileTrendReport';
import ProfileTrendReportAdvanced from 'reporting/section/ProfileTrendReportAdvanced';
import RiskAppetiteReport from 'reporting/section/RiskAppetiteReport';
import RiskAppetiteReportAdvanced from 'reporting/section/RiskAppetiteReportAdvanced';
import RiskOverviewReport from 'reporting/section/RiskOverviewReport';
import RiskOverviewReportAdvanced from 'reporting/section/RiskOverviewReportAdvanced';
import SummaryReport from 'reporting/section/SummaryReport';
import SummaryReportAdvanced from 'reporting/section/SummaryReportAdvanced';
import WeightJustificationReport from 'reporting/section/WeightJustificationReport';
import WeightJustificationReportAdvanced from 'reporting/section/WeightJustificationReportAdvanced';
import HeatMapReport from 'reporting/section/HeatMapReport';
import HeatMapReportAdvanced from 'reporting/section/HeatMapReportAdvanced';
import DebugAdjustableHeightPlaceholderReport from 'reporting/section/DebugAdjustableHeightPlaceholderReport';
import Missing from 'reporting/section/Missing';
import ConfigurationModal from 'reporting/section/ConfigurationModal';
import styles from 'reporting/section/styles';

const sectionComponents = {
  Demo,
  Example,
  DomainSummaryReport,
  DomainDetailReport,
  RiskAppetiteReport,
  RiskOverviewReport,
  KRIReport,
  KRITrendReport,
  ProfileReport,
  ProfileTrendReport,
  SummaryReport,
  WeightJustificationReport,
  HeatMapReport,
  DebugAdjustableHeightPlaceholderReport
};

const sectionAdvancedComponents = {
  ExampleAdvanced,
  KRIReportAdvanced,
  KRITrendReportAdvanced,
  ProfileReportAdvanced,
  ProfileTrendReportAdvanced,
  RiskAppetiteReportAdvanced,
  RiskOverviewReportAdvanced,
  SummaryReportAdvanced,
  WeightJustificationReportAdvanced,
  HeatMapReportAdvanced
};

const ExclamationSign = () => (<span className='glyphicon glyphicon-exclamation-sign' style={{pointerEvents: 'none'}}></span>);

function Tooltip({message, children}) {
  return (
    <span data-toggle='tooltip' data-container='body' title={message}>
      {children}
    </span>
  );
}

class Section extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      deletionTimeoutId: null
    };
  }

  handleCogClick = () => {
    this.setState({modalOpen: true});
  };

  handleModalHide = () => {
    this.setState({modalOpen: false});
  };

  handleTrashClick = () => {
    const animationMs = 2000; // Must match CSS animation
    const callbackMs = animationMs - 100; // Must be less than CSS animation
    this.setState({
      deletionTimeoutId: setTimeout(this.props.onDestroy, callbackMs)
    });
  };

  cancelDeletion = () => {
    const {deletionTimeoutId} = this.state;
    if (deletionTimeoutId) {
      clearTimeout(deletionTimeoutId);
      this.setState({deletionTimeoutId: null});
    }
  };

  render() {
    const {values, index, animateIn, onChange} = this.props;
    const {modalOpen, deletionTimeoutId} = this.state;
    const {report, id, error} = values;

    const Subcomponent = sectionComponents[report] || Missing;
    const AdvancedSubcomponent = sectionAdvancedComponents[report + 'Advanced'];

    let className = ['list-group-item', 'clearfix', styles.section];
    if (deletionTimeoutId) {
      className.push(styles.deleting);
    } else if (animateIn) {
      className.push(styles.adding);
    }

    if (error) {
      className.push('bg-warning');
    }

    return (
      <>
        <Draggable index={index} draggableId={`section-${id}`}>
          { (provided) => (
            <div
              className={className.join(' ')}
              ref={provided.innerRef}
              {...provided.draggableProps}
            >
              <span className='grip-handle' {...provided.dragHandleProps}></span>
              { error &&
              <Tooltip message={error}>
                <ExclamationSign />
              </Tooltip>
              }
              <Subcomponent {...values} onChange={onChange} />
              { !deletionTimeoutId &&
              <div className='col-lg-2 col-md-2 btn-toolbar'>
                <div className='btn-group pull-right'>
                  { AdvancedSubcomponent &&
                    <GlyphiconButton
                      icon='glyphicon-cog'
                      alt='Configure'
                      onClick={this.handleCogClick}
                    />
                  }
                  <GlyphiconButton
                    icon='glyphicon-trash'
                    alt='Delete'
                    onClick={this.handleTrashClick}
                  />
                </div>
              </div>
              }
              { deletionTimeoutId &&
              <div className={styles.mask} onClick={this.cancelDeletion}></div>
              }
            </div>
          ) }
        </Draggable>
        { AdvancedSubcomponent &&
        <ConfigurationModal
          show={modalOpen}
          Form={AdvancedSubcomponent}
          onChange={onChange}
          onHide={this.handleModalHide}
          values={this.props.values}
        />
        }
      </>
    );
  }
}

export default Section;
