import 'url-search-params-polyfill';
import React from 'react';
import Routes from 'global/routes';
import DataTable from 'global/components/DataTable';
import Toolbar from 'settings/components/Toolbar';
import RiskRow from 'settings/components/RiskRow';
import ax from 'global/axios';
import _ from 'lodash';

class SelfAssessmentConsiderationsContainer extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    const queryParams = new URLSearchParams(window.location.search);
    this.raredId = queryParams.get('risk_attribute_risk_entity_definition_id').split('-')[0];

    this.state = {
      displayAdd: true,
      considerations: [],
      loading: true,
      reordering: false,
      toolbarActions: [
        {
          onClick: this.handleNewConsiderationClick,
          iconClass: 'fa-plus',
          tooltip: 'Add New Self Assessment Consideration',
          visibility: {
            displayAdd: true
          }
        },
        {
          onClick: this.handleSaveReorderClick,
          iconClass: 'fa fa-save',
          text: 'Save Arrangement',
          visibility: {
            whenReordering: true
          }
        },
        {
          onClick: this.handleCancelReorderClick,
          iconClass: 'fa fa-ban',
          text: 'Cancel',
          visibility: {
            whenReordering: true
          }
        }
      ]
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.getSelfAssessmentConsiderations();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const considerations = this.state.considerations;
    const loading = this.state.loading;
    return (
      <div className='panel'>
        <div className='panel-heading'>
          <span className='panel-title'>Self Assessment Considerations</span>
        </div>
        <Toolbar actions={this.state.toolbarActions} isActionVisible={this.toolbarActionVisible} loading={loading} />
        <DataTable
          records={considerations}
          isLoading={loading}
          isCheckable={false}
          isDraggable={true}
          droppableId={'considerations'}
          rowComponent={RiskRow}
          onDragEnd={this.handleDragEnd}
        />
      </div>
    );
  }

  toolbarActionVisible = (key) => {
    const {
      toolbarActions,
      reordering
    } = this.state;
    const visibilityParams = toolbarActions[key].visibility;

    if (!_.has(visibilityParams, 'whenReordering') && reordering) {
      // Short circuit for reordering is occurring.
      return false;
    }

    if (_.has(visibilityParams, 'displayAdd')) {
      return true;
    } else if (_.has(visibilityParams, 'whenReordering')) {
      return reordering;
    }
  };

  handleNewConsiderationClick = async () => {
    window.location = Routes.new_settings_overrides_assessment_overrides_self_assessment_consideration_path({ risk_attribute_risk_entity_definition_id: this.raredId });
  };

  handleSaveReorderClick =  async () => {
    return await this.updateOrder();
  };

  handleCancelReorderClick = async () => {
    return await this.getSelfAssessmentConsiderations();
  };

  handleDragEnd = (result) => {
    // Dropped outside the table
    if (!result.destination) {
      return;
    }

    const considerations = reorder(
      this.state.considerations,
      result.source.index,
      result.destination.index
    );

    this.setState({ considerations: considerations, reordering: true });
  };

  getSelfAssessmentConsiderations = async () => {
    return ax({
      url: Routes.settings_overrides_assessment_overrides_self_assessment_considerations_path(),
      params: { risk_attribute_risk_entity_definition_id: this.raredId },
      method: 'get',
      headers: {
      }
    }).then((res) => {
      if (this._isMounted) {
        this.setState({ considerations: res.data, loading: false, reordering: false });
      }
    }).catch(ax.handleError);
  };

  updateOrder = async () => {
    const orderedKeys = this.state.considerations.map(i => i.id);
    return ax({
      url: Routes.update_order_settings_overrides_assessment_overrides_self_assessment_considerations_path(),
      method: 'patch',
      data: {
        self_assessment_consideration_risk_entity_definition_ids: orderedKeys
      }
    }).then(() => {
      return this.getSelfAssessmentConsiderations();
    }).catch(ax.handleError);
  };
}

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export default SelfAssessmentConsiderationsContainer;
