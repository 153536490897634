import _ from 'lodash';
import React from 'react';
import Select from 'react-select';
import QuestionInfoTooltip from 'global/components/QuestionInfoTooltip';
import MonthlySwitch from 'benchmarks/components/MonthlySwitch';
import QuarterlySwitch from 'benchmarks/components/QuarterlySwitch';
import LimitTypes from 'settings/components/LimitTypes';
import CeilingForm from 'benchmarks/components/CeilingForm';
import FloorForm from 'benchmarks/components/FloorForm';
import AbsoluteValueForm from 'benchmarks/components/AbsoluteValueForm';
import BidirectionalForm from 'benchmarks/components/BidirectionalForm';
import SelectDisplay from 'benchmarks/components/SelectDisplay';
import ValidationMessage from 'benchmarks/components/ValidationMessage';
import styles from 'risk_management/styles/Confirmation';
import Modal from 'react-bootstrap/Modal';

class RairedStepTwoForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      monthlyFeatureFlag: window.riskEntitySettings.monthly_kri,
      limitTypeTouched: false,
      limitType: props.limitType,
      enabledMonthly: props.enabledMonthly,
      enabledQuarterly: props.enabledQuarterly,
      monthlyBenchmarks: props.monthlyBenchmarks,
      quarterlyBenchmarks: props.quarterlyBenchmarks
    };
  }

  handleBlur = (_evt) => {
    this.setState({limitTypeTouched: true});
  };

  handleChange = (event) => {
    const target = event.target;
    this.setState({[target.name]: target.value}, this.notify);
  };

  handleLimitTypeChange = (limitType) => {
    this.setState({
      limitType: limitType,
      monthlyBenchmarks: null,
      quarterlyBenchmarks: null
    }, this.notify);
  };

  handleMonthlyBenchmarkChange = (benchmarks) => {
    this.setState({monthlyBenchmarks: benchmarks}, this.notify);
  };

  handleQuarterlyBenchmarkChange = (benchmarks) => {
    this.setState({quarterlyBenchmarks: benchmarks}, this.notify);
  };

  notify = () => {
    const {onChange} = this.props;
    const filteredState = _.pick(this.state, ['limitType', 'enabledMonthly', 'enabledQuarterly', 'monthlyBenchmarks', 'quarterlyBenchmarks']);
    onChange(filteredState);
  };

  render() {
    const {
      decimalPlaces,
      valueFormatLabel,
      dataInputSelectOptions,
      validation,
      onBack,
      onNext,
    } = this.props;
    const { monthlyFeatureFlag, limitType, enabledMonthly, enabledQuarterly, monthlyBenchmarks, quarterlyBenchmarks } = this.state;
    const limitTypeInvalid = limitType === undefined && this.state.limitTypeTouched;

    return (
      <>
        <Modal.Body className="tw-modal-body overflow-visible">
          <div>
            <DataInputConfirmation
              valueFormatName={valueFormatLabel}
              decimalPlaces={decimalPlaces}
            />
            <form className='simple_form'>
              <div className='form-group'>
                {valueFormatLabel !== 'Select' &&
                <div className='row'>
                  <div className={limitTypeInvalid ? 'has-error col-md-6 select' : 'col-md-6 select'}>
                    <label className='control-label'>
                      <abbr title='required'>*</abbr>
                      Benchmark Type
                    </label>
                    <QuestionInfoTooltip
                      content="<div class='text-left'><strong>Ceiling:</strong> Risk increases as value increases.<br/><br/><strong>Floor:</strong> Risk decreases as value increases.<br/><br/><strong>Absolute Value:</strong> Same as ceiling but using positive indicator value.<br/><br/><strong>Bidirectional:</strong> Risk is lowest at the midpoint and increases outward in both directions.</div>"
                      html={true}
                      placement='bottom'
                      trigger='hover'
                    />
                    <Select
                      id='limitTypes'
                      classNamePrefix='limitType'
                      onBlur={this.handleBlur}
                      value={limitType}
                      onChange={this.handleLimitTypeChange}
                      options={LimitTypes}
                      isClearable={false}
                      styles={{ menuList: (provided, _state) => ({ ...provided, maxHeight: 200 }) }}
                    />
                    {limitTypeInvalid &&
                      <span className='help-block error-block'>Must select a Benchmark Type.</span>}
                    <br/>
                  </div>
                </div>
                }
                <div>
                  {limitType &&
                  <div id='quarterly-benchmarks'>
                    { monthlyFeatureFlag &&
                      <QuarterlySwitch value={enabledQuarterly} onChange={this.handleChange} />
                    }
                    {enabledQuarterly &&
                      <>
                        <ValidationMessage>{validation.quarterlyBenchmarks}</ValidationMessage>
                        {limitType.value == 'select' &&
                          <SelectDisplay selectOptions={dataInputSelectOptions} />
                        }
                        {limitType.value == 'ceiling' &&
                          <CeilingForm benchmarks={quarterlyBenchmarks} onChange={this.handleQuarterlyBenchmarkChange} />
                        }
                        {limitType.value == 'floor' &&
                          <FloorForm benchmarks={quarterlyBenchmarks} onChange={this.handleQuarterlyBenchmarkChange} />
                        }
                        {limitType.value == 'absolute_value' &&
                          <AbsoluteValueForm benchmarks={quarterlyBenchmarks} onChange={this.handleQuarterlyBenchmarkChange} />
                        }
                        {limitType.value == 'bidirectional' &&
                          <BidirectionalForm benchmarks={quarterlyBenchmarks} onChange={this.handleQuarterlyBenchmarkChange} />
                        }
                      </>
                    }
                  </div>
                  }
                  {limitType &&
                  <div id='monthly-benchmarks'>
                    { monthlyFeatureFlag &&
                      <MonthlySwitch value={enabledMonthly} onChange={this.handleChange} />
                    }
                    { enabledMonthly &&
                      <>
                        <ValidationMessage>{validation.monthlyBenchmarks}</ValidationMessage>
                        {limitType.value == 'select' &&
                          <SelectDisplay selectOptions={dataInputSelectOptions} />
                        }
                        {limitType.value == 'ceiling' &&
                          <CeilingForm benchmarks={monthlyBenchmarks} onChange={this.handleMonthlyBenchmarkChange} />
                        }
                        {limitType.value == 'floor' &&
                          <FloorForm benchmarks={monthlyBenchmarks} onChange={this.handleMonthlyBenchmarkChange} />
                        }
                        {limitType.value == 'absolute_value' &&
                          <AbsoluteValueForm benchmarks={monthlyBenchmarks} onChange={this.handleMonthlyBenchmarkChange} />
                        }
                        {limitType.value == 'bidirectional' &&
                          <BidirectionalForm benchmarks={monthlyBenchmarks} onChange={this.handleMonthlyBenchmarkChange} />
                        }
                      </>
                    }
                  </div>
                  }
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary pull-left" type="button" onClick={onBack}>
          Back
          </button>
          <button className="btn btn-primary pull-right" type="button" disabled={!limitType} onClick={onNext}>
          Next
          </button>
        </Modal.Footer>
      </>
    );
  }
}

function DataInputConfirmation(props) {
  const { valueFormatName, decimalPlaces } = props;
  return (
    <>
      <ul className={styles.confirmationList}>
        <li className={styles.confirmationHeader}>Value Format</li>
        <ul>
          <li className={styles.confirmationItem}>{valueFormatName}</li>
        </ul>
        {decimalPlaces &&
          <>
            <li className={styles.confirmationHeader}>Decimal Places:</li>
            <ul>
              <li className={styles.confirmationItem}>{decimalPlaces}</li>
            </ul>
          </>}
      </ul>
      <hr/>
    </>
  );
}

export default RairedStepTwoForm;
