import React from 'react';
import PropTypes from 'prop-types';
import Routes from 'global/routes';
import DataTable from 'global/components/DataTable';
import Toolbar from 'settings/components/Toolbar';
import DataInputRiskEntityDefinitionRow from 'settings/components/DataInputRiskEntityDefinitionRow';
import ax from 'global/axios';
import _ from 'lodash';

class DataInputRiskEntityDefinitionsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.sectionID = parseInt(props.match.params.id);
    this.state = {
      selectedKeys: new Set(),
      definitions: [],
      columnHeaders: [],
      loading: true,
      reordering: false,
      toolbarActions: [
        {
          onClick: this.handleNewDefinitionClick,
          iconClass: 'fa-plus',
          tooltip: 'Add New Data Input',
          visibility: {
            minSelections: 0
          }
        },
        {
          onClick: this.handleEditDefinitionClick,
          iconClass: 'fa-pencil',
          tooltip: 'Edit Selected Data Input',
          visibility: {
            minSelections: 1,
            maxSelections: 1
          }
        },
        {
          onClick: this.handleRenameDefinitionClick,
          iconClass: 'fa-i-cursor',
          tooltip: 'Rename Selected Data Input(s)',
          visibility: {
            minSelections: 2
          }
        },
        {
          onClick: this.handleRemoveDefinitionClick,
          iconClass: 'fa-trash',
          tooltip: 'Remove Selected Data Input',
          visibility: {
            minSelections: 1,
            maxSelections: 1
          }
        },
        {
          onClick: this.handleShowDefinitionClick,
          iconClass: 'fa-eye',
          tooltip: 'Un-hide Selected Data Input(s)',
          visibility: {
            minSelections: 1
          }
        },
        {
          onClick: this.handleHideDefinitionClick,
          iconClass: 'fa-eye-slash',
          tooltip: 'Hide Selected Data Input(s)',
          visibility: {
            minSelections: 1
          }
        },
        {
          onClick: this.handleMoveDefinitionClick,
          iconClass: 'fa-exchange',
          tooltip: 'Move Selected Data Input(s) to Section...',
          visibility: {
            minSelections: 1
          }
        },
        {
          onClick: this.handleSaveReorderClick,
          iconClass: 'fa fa-save',
          text: 'Save Arrangement',
          visibility: {
            whenReordering: true
          }
        },
        {
          onClick: this.handleCancelReorderClick,
          iconClass: 'fa fa-ban',
          text: 'Cancel',
          visibility: {
            whenReordering: true
          }
        }
      ]
    };
  }

  componentDidMount() {
    this.getDataInputRiskEntityDefinitions();
  }

  render() {
    const definitions = this.state.definitions;
    const loading = this.state.loading;
    const columnHeaders = this.state.columnHeaders;
    return (
      <div>
        <Toolbar actions={this.state.toolbarActions} isActionVisible={this.toolbarActionVisible} loading={loading} />
        <DataTable
          records={definitions}
          columnHeaders={columnHeaders}
          rowComponent={DataInputRiskEntityDefinitionRow}
          isLoading={loading}
          isCheckable={true}
          isDraggable={true}
          droppableId={'definitions'}
          onSelectedRowsChange={this.handleSelectedRowsChange}
          onDragEnd={this.handleDragEnd}
        />
      </div>
    );
  }

  toolbarActionVisible = (key) => {
    const {
      selectedKeys,
      toolbarActions,
      reordering
    } = this.state;
    const visibilityParams = toolbarActions[key].visibility;

    if (!_.has(visibilityParams, 'whenReordering') && reordering) {
      // Short circuit for reordering is occurring.
      return false;
    }

    if (_.has(visibilityParams, 'minSelections') && _.has(visibilityParams, 'maxSelections')) {
      return (selectedKeys.size >= visibilityParams.minSelections) && (selectedKeys.size <= visibilityParams.maxSelections);
    } else if (_.has(visibilityParams, 'minSelections')) {
      return (selectedKeys.size >= visibilityParams.minSelections);
    } else if (_.has(visibilityParams, 'whenReordering')) {
      return reordering;
    }
  };

  handleSelectedRowsChange = (selectedKeys) => {
    const prevKeys = this.state.selectedKeys;
    if (!_.isEqual(prevKeys, selectedKeys)) {
      this.setState({ selectedKeys: new Set(selectedKeys) });
    }
  };

  handleNewDefinitionClick = async () => {
    window.location = Routes.new_settings_overrides_data_input_section_data_input_risk_entity_definition_path(this.sectionID);
  };

  handleEditDefinitionClick = async () => {
    const selectedKey = firstSetValue(this.state.selectedKeys);
    window.location = Routes.edit_settings_overrides_data_input_section_data_input_risk_entity_definition_path(this.sectionID, selectedKey);
  };

  handleRemoveDefinitionClick = async () => {
    return window.dialogs.confirm({
      title: 'Are you sure?',
      confirm: 'Are you sure you want to delete this data input?',
      primaryButtonText: 'Delete Data Input',
      primaryButtonClass: 'btn-danger',
      hardEscape: true,
      onPrimaryClick: () => {
        this.deleteDataInputRiskEntityDefinition();
      },
      closeOnPrimary: true
    });
  };

  handleShowDefinitionClick = async () => {
    return this.updateVisible(true);
  };

  handleHideDefinitionClick = async () => {
    return this.updateVisible(false);
  };

  handleMoveDefinitionClick = async () => {
    const keys = this.state.selectedKeys;
    const title = keys.size == 1 ? 'Move Data Input' : 'Move Data Inputs';

    return window.dialogs.easyForm({
      title: title,
      url: Routes.move_settings_overrides_data_input_section_data_input_risk_entity_definitions_path(this.sectionID),
      formUrl: Routes.move_form_settings_overrides_data_input_section_data_input_risk_entity_definitions_path(this.sectionID, { selected: [...keys] }),
      primaryButtonText: 'Move',
      hardEscape: true,
      onInitialize: function(modal){
        modal.find('#selected_section').defaultSelect2();
      },
      onSuccess: (_result) => {
        this.getDataInputRiskEntityDefinitions();
      }
    });
  };

  handleRenameDefinitionClick = async () => {
    const keys = this.state.selectedKeys;

    return window.dialogs.easyForm({
      title: 'Rename Data Inputs',
      url: Routes.rename_settings_overrides_data_input_section_data_input_risk_entity_definitions_path(this.sectionID),
      formUrl: Routes.rename_form_settings_overrides_data_input_section_data_input_risk_entity_definitions_path(this.sectionID, { selected: [...keys] }),
      modalSizeClass: 'modal-lg',
      primaryButtonText: 'Rename',
      hardEscape: true,
      onSuccess: (_result) => {
        this.getDataInputRiskEntityDefinitions();
      }
    });
  };

  handleSaveReorderClick = async () => {
    return this.updateOrder();
  };

  handleCancelReorderClick = async () => {
    return this.getDataInputRiskEntityDefinitions();
  };

  handleDragEnd = (result) => {
    // Dropped outside the table
    if (!result.destination) {
      return;
    }

    const definitions = reorder(
      this.state.definitions,
      result.source.index,
      result.destination.index
    );

    this.setState({ definitions: definitions, reordering: true });
  };

  getDataInputRiskEntityDefinitions = async () => {
    this.setState({ loading: true });
    return ax({
      url: Routes.settings_overrides_data_input_section_data_input_risk_entity_definitions_path(this.sectionID),
      method: 'get'
    }).then((res) => {
      this.setState({ definitions: res.data.direds, columnHeaders: res.data.columnHeaders, loading: false, reordering: false });
    }).catch(ax.handleError);
  };

  updateVisible = async (value) => {
    const keys = this.state.selectedKeys;
    return ax({
      url: Routes.update_visible_settings_overrides_data_input_section_data_input_risk_entity_definitions_path(this.sectionID),
      method: 'patch',
      data: {
        data_input_risk_entity_definition_ids: keys,
        visible: value
      }
    }).then((res) => {
      var definitions = [...this.state.definitions];
      res.data.direds.map(r => definitions.splice(_.findIndex(definitions, { id: r.id }), 1, r));

      this.setState({ definitions: definitions, reordering: false });
    }).catch(ax.handleError);
  };

  updateOrder = async () => {
    const orderedKeys = this.state.definitions.map(s => s.id);
    return ax({
      url: Routes.update_order_settings_overrides_data_input_section_data_input_risk_entity_definitions_path(this.sectionID),
      method: 'patch',
      data: {
        data_input_risk_entity_definition_ids: orderedKeys
      }
    }).then(() => {
      return this.getDataInputRiskEntityDefinitions();
    }).catch(ax.handleError);
  };

  deleteDataInputRiskEntityDefinition = () => {
    const selectedKey = firstSetValue(this.state.selectedKeys);
    ax({
      url: `/settings/overrides/data_input_sections/${this.sectionID}/data_input_risk_entity_definitions/${selectedKey}/remove`,
      method: 'patch'
    }).then(() => {
      this.getDataInputRiskEntityDefinitions();
    }).catch(ax.handleError);
  };
}

const firstSetValue = (set) => {
  const firstIter = set.values().next();
  return firstIter.value;
};

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

DataInputRiskEntityDefinitionsContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    })
  })
};

export default DataInputRiskEntityDefinitionsContainer;
