import React from 'react';
import Select from 'react-select';
import _ from 'lodash';

const loader = _.memoize( (timePeriod) => {
  let params = {};
  if (timePeriod) {
    params.time_period = timePeriod;
  }
});

class CompositeOnlyPicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
      loading: false,
      error: null
    };
  }

  handleChange = (option) => {
    const value = _.get(option, 'value');
    this.props.onChange({composite_only: value});
  };

  handleMenuOpen = () => {
    const {timePeriod} = this.props;
    const {error} = this.state;
    if (error) {
      loader(timePeriod).catch( () => loader.cache.delete(timePeriod) );
      this.load();
    }
  };

  load() {
    const {timePeriod} = this.props;
    // Set Initial Selected Value
    if (!this.props.value) {
      this.props.onChange({composite_only: false});
    }
    this.setState({loading: true});
    loader(timePeriod);
    const options = [
      {value: true, label: 'Yes'},
      {value: false, label: 'No'},
    ];
    this.setState({
      loading: false,
      error: null,
      options: options
    });
  }

  selectedOption() {
    const {value} = this.props;
    const {options} = this.state;

    const found = _.find(options, _.matchesProperty('value', value));
    if (found) {
      return found;
    } else {
      // Returning undefined directly from _.find doesn't clear visible selection reliably
      return null;
    }
  }

  componentDidMount() {
    this.load();
  }

  componentDidUpdate(prevProps, prevState) {
    const {value, onChange} = this.props;
    const {options} = this.state;

    if (this.props.timePeriod != prevProps.timePeriod) {
      this.load();
    }

    if (options != prevState.options) {
      const found = this.selectedOption();
      // Clear selection when not in available options
      if (value && !found) {
        onChange({composite_only: null});
      }
    }
  }

  render() {
    const {loading, options} = this.state;

    const selectProps = {
      options: options,
      value: this.selectedOption(),
      isDisabled: false,
      isLoading: loading,
      isClearable: true,
      placeholder: `${window.riskEntitySettings.composite_risk_label} Only`,
      onChange: this.handleChange,
      onMenuOpen: this.handleMenuOpen
    };

    return (
      <Select {...selectProps} />
    );
  }
}

export default CompositeOnlyPicker;
